import React from "react";
import {formatDate} from "../../../helpers/DateHelper";
import {IJuryAssessmentListTeam} from "../../../types/assessment/IJuryAssessmentListTeam";
import {IJuryAssessmentListParticipant} from "../../../types/assessment/IJuryAssessmentListParticipant";

export const JuryStageButton: React.FC<{
  tab: string,
  name: string,
  solutionDate: string,
  maxPoints: number,
  instance: IJuryAssessmentListTeam | IJuryAssessmentListParticipant,
  setRightInstance: (instance: IJuryAssessmentListTeam | IJuryAssessmentListParticipant | undefined) => void}> =
    ({tab, name, solutionDate, maxPoints, instance, setRightInstance}) => {

  const getPercent = (points: number) => {
    return (100 / maxPoints * points).toFixed(1);
  }

  if (tab === "graded" && instance.submitted) {
    return (
        <div className="stage-card-new-work">
          <div className="stage-card-new-info" onClick={() => setRightInstance(instance)}>
            <div className="stage-card-info">
              <h5 className="stage-card-heading">Статус</h5>
              <p className="stage-card-done">
                Оценено
              </p>
            </div>
            <div className="graph">
              <div className="graph-line">
                <div className="graph-line-container">
                  <div className="graph-line-line" style={{width: getPercent(instance.points || 0) + "%"}}/>
                </div>
                <p className="graph-line-result-jury">
                  {instance.points}
                </p>
              </div>
              <p className="stage-card-new-heading">
                {name}
              </p>
            </div>
            <p className="stage-card-new-text">
              {instance.comment}
            </p>
          </div>
        </div>
    )
  }
  else if (tab === "graded") {
    return (
        <div className="stage-card-new-work">
          <div className="stage-card-new-info" onClick={() => setRightInstance(instance)}>
            <div className="stage-card-info">
              <h5 className="stage-card-heading">Статус</h5>
              <p className="stage-card-done">
                Оценено
              </p>
            </div>
            <div className="graph">
              <p className="stage-card-new-heading">
                {name}
              </p>
              {/*<div className="stage-card-new-gap-work">*/}
              {/*  <p className="stage-card-new-date">*/}
              {/*    {formatDate(solutionDate)}*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </div>
          {/*<div className="stage-card-new-wrapper-link">*/}
          {/*  <a href="" className="card-standart-link">*/}
          {/*    Чат с экспертами*/}
          {/*  </a>*/}
          {/*</div>*/}
        </div>
    )
  } else {
    return (
        <div className="stage-card-new">
          <div className="stage-card-new-info" onClick={() => setRightInstance(instance)}>
            <div className="stage-card-info">
              <h5 className="stage-card-heading">Статус</h5>
              <p className="stage-card-active">
                Оценивается
              </p>
            </div>
            <div className="graph">
              <p className="stage-card-new-heading">
                {name}
              </p>
              {/*<div className="stage-card-new-gap">*/}
              {/*  <p className="stage-card-new-date">*/}
              {/*    {formatDate(solutionDate)}*/}
              {/*  </p>*/}
              {/*</div>*/}
            </div>
          </div>
          {/*<div className="stage-card-new-wrapper-link">*/}
          {/*    <a href="" className="card-standart-link">*/}
          {/*        Чат с экспертами*/}
          {/*    </a>*/}
          {/*</div>*/}
        </div>
    )
  }
}