import React, {useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import * as WalletAPI from "../../../../requests/WalletAPI";

export const PaymentResult = () => {

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (params.result === "success") {
            const oid = searchParams.get("orderId");
            if (oid !== null) {
                WalletAPI.paymentOk(oid, (response) => {
                    window.location.href = "/plan?tab=enroll";
                })
            } else {
                window.location.href = "/plan?tab=enroll";
            }
        } else {
            WalletAPI.paymentFailed((response) => {
                window.location.href = "/plan?tab=enroll";
            })
        }
    }, []);


    return (<></>);
}