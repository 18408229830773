import React, {useEffect, useState} from "react";
import {ITeamAd} from "../../../../types/ITeamAd";
import API from "../../../../../requests/_axios";

export const NewAd: React.FC<{
  ad: ITeamAd | undefined,
  challengeId: number | undefined,
  teamId: number | undefined}> = ({challengeId, ad, teamId}) => {

  const [stateAd, setStateAd] = useState<ITeamAd>();
  const [error, setError] = useState("");

  useEffect(() => {
    if(ad) {
      setStateAd(ad);
    } else {
      setStateAd({...stateAd, teamId: teamId});
    }
  }, [ad, teamId]);

  const handleSubmit = () => {
    setError("");
    const url = "/challenge/api/v1/challenge/" + challengeId + "/ad/my";
    if(ad) {
      API.put(url, stateAd).then((response) => {
        if(response.status === 200)
          window.location.reload();
        else
          setError("Все поля обязательны для заполнения");
      })
    } else {
      API.post(url, stateAd).then((response) => {
        if(response.status === 200)
          window.location.reload();
        else
          setError("Все поля обязательны для заполнения");
      })
    }
  }

  return (
      <div className="container-right-block">
        <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
        <div className="menu-challenge">
          <fieldset className="block-input">
            <legend className="legend">Роль в команде*</legend>
            <input type="text" value={stateAd?.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStateAd({...stateAd, name: e.target.value});
            }} className="input-challenges" placeholder="Пример: Верстальщик"/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Что придётся делать*</legend>
            <textarea value={stateAd?.description} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=> {
              setStateAd({...stateAd, description: e.target.value});
            }} cols={30} rows={10} className="input-challenges" placeholder="Опишите основные задачи и требования к кандидату, не более 500 символов" maxLength={500}/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Необходимые навыки*</legend>
            <input type="text" value={stateAd?.skills} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStateAd({...stateAd, skills: e.target.value});
            }} className="input-challenges" placeholder="Введите роль и/или навыки через запятую"/>
          </fieldset>
          <button className="btn-main" onClick={handleSubmit}>Сохранить и опубликовать</button>
        </div>
      </div>
  );
}