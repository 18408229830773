import React from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {toNumber} from "lodash";
import {formatDate} from "../../../../helpers/DateHelper";

export const StageButtonAdminVideoAdmin: React.FC<{
    stage: IChallengeStage,
    showStage: (id: number) => void,
    deleteStage: (id: number) => void}> = ({stage, showStage, deleteStage}) => {

    if (stage.endDate && stage.startDate) {
        // past
        if (new Date() > new Date(stage.endDate + "+03:00" || "")) {
            return (
                <div className="stage-card-new">
                    <div className="stage-card-new-info">
                        <div className="stage-card-info">
                            <h5 className="stage-card-heading">Статус</h5>
                            <p className="stage-card-done">
                                Завершено
                            </p>
                        </div>
                        <div className="graph">
                            <p className="stage-card-new-heading" onClick={() => showStage(toNumber(stage.id))}>
                                {stage.name}
                            </p>
                            <div className="stage-card-new-gap-work">
                                <p className="stage-card-new-date">
                                    {stage.videos.length} видео
                                </p>
                            </div>
                            <div className="stage-card-new-gap-work" style={{marginTop: "10px"}}>
                                <p className="stage-card-new-date">
                                    {formatDate(stage.startDate)}
                                </p>
                                <p className="stage-card-new-date">
                                    -
                                </p>
                                <p className="stage-card-new-date">
                                    {formatDate(stage.endDate)}
                                </p>
                            </div>
                            <p className="stage-card-new-text">
                                {stage.description || "Нет описания"}
                            </p>
                        </div>
                    </div>
                    <div className="stage-card-new-wrapper-link">
                        <a onClick={() => deleteStage(toNumber(stage.id))} className="card-standart-link-false">
                            Удалить
                        </a>
                    </div>
                </div>
            )
        }
        // current
        else if (new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() < new Date(stage.endDate + "+03:00" || "")) {
            return (
                <div className="stage-card-new-work">
                    <div className="stage-card-new-info">
                        <div className="stage-card-info">
                            <h5 className="stage-card-heading">Обучение</h5>
                        </div>
                        <div className="graph">
                            <p className="stage-card-new-heading" onClick={() => showStage(toNumber(stage.id))}>
                                {stage.name}
                            </p>
                            <div className="stage-card-new-gap-work">
                                <p className="stage-card-new-date">
                                    {stage.videos ? stage.videos.length : 0} видео
                                </p>
                            </div>
                            <div className="stage-card-new-gap-work" style={{marginTop: "10px"}}>
                                <p className="stage-card-new-date">
                                    {formatDate(stage.startDate)}
                                </p>
                                <p className="stage-card-new-date">
                                    -
                                </p>
                                <p className="stage-card-new-date">
                                    {formatDate(stage.endDate)}
                                </p>
                            </div>
                            <p className="stage-card-new-text">
                                {stage.description || "Нет описания"}
                            </p>
                        </div>
                    </div>
                    <div className="stage-card-new-wrapper-link">
                        <a onClick={() => deleteStage(toNumber(stage.id))} className="card-standart-link-false">
                            Удалить
                        </a>
                    </div>
                </div>
            )
        }
        // future
        else {
            return (
                <div className="stage-card-new-work">
                    <div className="stage-card-new-info">
                        <div className="stage-card-info">
                            <h5 className="stage-card-heading">Статус</h5>
                            <p className="stage-card-date">
                                Будущее
                            </p>
                        </div>
                        <div className="graph">
                            <p className="stage-card-new-heading" onClick={() => showStage(toNumber(stage.id))}>
                                {stage.name}
                            </p>
                            <div className="stage-card-new-gap-work">
                                <p className="stage-card-new-date">
                                    {stage.videos.length} видео
                                </p>
                            </div>
                            <div className="stage-card-new-gap-work" style={{marginTop: "10px"}}>
                                <p className="stage-card-new-date">
                                    {formatDate(stage.startDate)}
                                </p>
                                <p className="stage-card-new-date">
                                    -
                                </p>
                                <p className="stage-card-new-date">
                                    {formatDate(stage.endDate)}
                                </p>
                            </div>
                            <p className="stage-card-new-text">
                                {stage.description || "Нет описания"}
                            </p>
                        </div>
                    </div>
                    <div className="stage-card-new-wrapper-link">
                        <a className="card-standart-link-false" onClick={() => deleteStage(toNumber(stage.id))}>
                            Удалить
                        </a>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <div className="stage-card-new-work">
                <div className="stage-card-new-info">
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Статус</h5>
                        <p className="stage-card-date">
                            Создан
                        </p>
                    </div>
                    <div className="graph" onClick={() => showStage(toNumber(stage.id))}>
                        <p className="stage-card-new-heading">
                            {stage.name}
                        </p>
                    </div>
                </div>
                <div className="stage-card-new-wrapper-link">
                    <a className="card-standart-link-false" onClick={() => deleteStage(toNumber(stage.id))}>
                        Удалить
                    </a>
                </div>
            </div>
        )
    }
}