import React, {useEffect, useState} from "react";
import {IProfileUniversityForm} from "../../types/IProfileUniversityForm";
import API from "../../../requests/_axios";
import SessionModel from "../../../stores/SessionModel";
import * as InputMask from "react-input-mask";
import {ProfileSettings} from "./ProfileSettings";
import {AdminUniversityProfileSettings} from "../admin/AdminUniversityProfileSettings";
import {ModalMessage} from "./ModalMessage";

export const ProfileUniversityDetailsEditor:
    React.FC<{
      currentForm: IProfileUniversityForm | undefined,
      userId: number | null}>
    = ({currentForm, userId}) => {

  const InputMask = require('react-input-mask');
  const [form, setForm] = useState<IProfileUniversityForm>({
    shortName: "",
    fullName:"",
    rectorName:"",
    responsiblePersonName:"",
    responsiblePersonEmail:"",
    responsiblePersonPosition:"",
    responsiblePersonPhone:"",
    phone: "",
    fias: "",
    email: "",
    type: 1,
    site:"",
    city:"",
    region:""
  });
  const [dadata, setDadata] = useState([]);
  const [city, setCity] = useState("");
  const [respPersonHint, setRespPersonHint] = useState(false);
  const [respPersonEmailHint, setRespPersonEmailHint] = useState(false);
  const [universityList, setUniversityList] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    let url = "/challenge/api/v1/university/profile";
    if(userId !== null && userId !== undefined && userId !== 0)
      url = url + "/" + userId;
    if(currentForm == null) {
      initProfileFields(url);
    } else {
      setForm(currentForm);
      if(currentForm.city !== "" && currentForm.region !== "")
        setCity(currentForm.region + ", " + currentForm.city);
    }
  }, [userId]);

  const initProfileFields = (url: string) => {
    API.get(url, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      setForm({...form,
        shortName: response.data.shortName,
        fullName: response.data.fullName,
        username: response.data.username,
        rectorName: response.data.rectorName,
        responsiblePersonName: response.data.responsiblePersonName,
        phone: response.data.phone,
        site: response.data.site,
        responsiblePersonEmail: response.data.responsiblePersonEmail,
        responsiblePersonPosition: response.data.responsiblePersonPosition,
        responsiblePersonPhone: response.data.responsiblePersonPhone,
        email: response.data.email,
        type: response.data.type,
        region: response.data.region || " ",
        city: response.data.city || " ",
        fias: response.data.fias || "",
        mailing: response.data.mailing,
        userId: response.data.userId,
        inn: response.data.inn,
        officialLetterDate: response.data.officialLetterDate,
        canViewStudents: response.data.canViewStudents,
        userPlan: response.data.userPlan
      });
      if(response.data.city !== null && response.data.region !== null)
        setCity(response.data.region + ", " + response.data.city);
    });
  }

  const fetchLocation = (query: string) => {
    API.get("/challenge/api/v1/location/city?city=" + query, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      if(response.status === 200)
        setDadata(response.data);
      else
        setDadata([]);
    });
  };

  const handleUpdate = () => {
    let url = "/challenge/api/v1/university/profile";
    if(userId !== null && userId !== undefined) {
      url = url + "/" + userId;
    }
    if(validateAll()) {
      API.put(url, form, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
        if(response.status === 200) {
          if (userId == null) {
            window.location.reload();
          } else {
            setMessage("Сохранено");
            setError("");
          }
        } else if(response.status == 400) {
          displayValidationError(response.data.message);
        } else {
          displayValidationError("Произошла техническая ошибка. Попробуйте еще раз, или свяжитесь с администрацией");
        }
      });
    }
  }

  const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, fias: ""});
    if(event.target.value.length > 2) {
      fetchLocation(event.target.value);
    }
    setCity(event.target.value);
  }

  const handleCityClick = (text: string, fias: string) => {
    setForm({...form, city: text, fias: fias, region: " "});
    setCity(text);
    setDadata([]);
  }

  const validateAll = () : boolean => {
    let k: keyof typeof form;
    for (k in form) {
      if(k == "city" && !form.fias) {
        setCity("");
        displayValidationError("Выберите город из списка");
        return false;
      }
      if((k === "phone" || k === "responsiblePersonPhone") && form[k]) {
        form[k] = form[k].replace(/\D/g,'');
      }
    }
    return true;
  }

  const displayValidationError = (text: string) => {
    if (!text)
      setError("Ошибка! Все поля являются обязательными");
    else
      setError(text);
    // @ts-ignore
    document.querySelector('.right-menu').scrollTo(0,0);
  }

  const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /*if(event.target.value.length > 2) {
      fetchUniversities(event.target.value);
    } else {
      setUniversityList([]);
    }*/
    setForm({...form, fullName: event.target.value});
  }

  const handleUniversityClick = (name: string, id: number) => {
    /*setForm({...form, fullName: ""});
    setError("Данное учебное заведение уже зарегистрировано, обратитесь с рабочей почты к администратору платформы по адресу info@braim.org для уточнения контактов текущего ответственного от организации");*/
  }

  const fetchUniversities = (search: string) => {
    API.get("/challenge/api/v1/university/search?name=" + search, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      if(response.status === 200)
        setUniversityList(response.data);
      else
        setUniversityList([]);
    });
  }

  return (
      <>
        <div className="right-menu">
          <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>{error}</p>
          <ModalMessage msg={message} header={"Инфо"} _callback={() => setMessage("")}/>
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Краткое название учебного заведения*</legend>
            <input type="text" value={form.shortName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, shortName: e.target.value});
            }} className="input-challenges" placeholder="МГУ"/>
          </fieldset>
{/*          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Полное название учебного заведения</legend>
            <input type="text" value={form.fullName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, fullName: e.target.value});
            }} className="input-challenges" placeholder="ФГБОУ ВО «Московский государственный университет имени М.В. Ломоносова»"/>
          </fieldset>*/}
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Полное название учебного заведения*</legend>
            <input type="text" onChange={handleFullNameChange} value={form.fullName || ""} className="input-challenges city-search"
                   placeholder="ФГБОУ ВО «Московский государственный университет имени М.В. Ломоносова»"/>
            <div className="container-city">
              {universityList && universityList.map((d, i) =>
                  //@ts-ignore
                  <div onClick = {() => handleUniversityClick(d.fullName, d.userId)} className="city-input" key={i}>{d.fullName}</div>
              )}
            </div>
          </fieldset>
          <fieldset className="block-input" disabled={form.onModeration}>
            <legend className="legend">ИНН*</legend>
            <input type="text" value={form.inn || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, inn: e.target.value});
            }} className="input-challenges" placeholder="1234567890"/>
          </fieldset>
          <fieldset className="block-input-flex" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Тип учебного заведения*</legend>
            <label className="input-challenges-flex">
              <input type="radio" name="comand_num" value="1" checked={form.type === 1} onChange={() => setForm({...form, type: 1})} className="radio-btn"/>
              <span className="span-radio">СПО</span>
            </label>
            <label className="input-challenges-flex">
              <input type="radio" name="comand_num" value="2" checked={form.type === 2} onChange={() => setForm({...form, type: 2})} className="radio-btn"/>
              <span className="span-radio">ВО</span>
            </label>
          </fieldset>
          <fieldset className="block-input" disabled={!SessionModel.getCurrentUserAuthData().roles.includes("ROLE_ADMIN") && !SessionModel.isModerator()}>
            <legend className="legend">Регистрационный E-mail*</legend>
            <input type="text" value={form.username || ""} className="input-challenges" placeholder="E-mail" onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, username: e.target.value});
            }}/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Сайт организации*</legend>
            <input type="text" value={form.site || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, site: e.target.value});
            }} className="input-challenges"
                   placeholder="http://www.google.com"/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Телефон секретариата*</legend>
            {/*+7 (999) 999-99-99*/}
            <InputMask mask="+999999999999999" value={form.phone || ""} className="input-challenges"
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                       {setForm({...form, phone: e.target.value});
                       }}/>
            {/*<input type="tel" value={form.phone || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {*/}
            {/*  setForm({...form, phone: e.target.value});*/}
            {/*}} className="input-challenges"*/}
            {/*       placeholder="+79001234567"/>*/}
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">E-mail секретариата*</legend>
            <input type="email" value={form.email || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, email: e.target.value});
            }} className="input-challenges"
                   placeholder="info@parampam.com"/>
          </fieldset>
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Город*</legend>
            <input type="text" onChange={handleCity} value={city} className="input-challenges city-search"
                   placeholder="Начните вводить и выберите значение из выпадающего списка"/>
            <div className="container-city">
              {dadata && dadata.map((d, i) =>
                  //@ts-ignore
                  <div onClick = {() => handleCityClick(d.label, d.id)} className="city-input" key={i}>{d.label}</div>
              )}
            </div>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">ФИО руководителя учебного заведения*</legend>
            <input type="text" value={form.rectorName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, rectorName: e.target.value});
            }} className="input-challenges"
                   placeholder="Иванов Иван Иванович"/>
          </fieldset>
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">ФИО ответственного*</legend>
            <input type="text" value={form.responsiblePersonName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, responsiblePersonName: e.target.value});
            }} className="input-challenges"
                   placeholder="Иванов Иван Иванович"/>
            <div className="answer" onMouseOver={() => setRespPersonHint(!respPersonHint)} onMouseOut={() => setRespPersonHint(!respPersonHint)}></div>
            <p className="answer-text" style={{display: respPersonHint ? "" : "none"}}>Ответственный - это официальный представитель учебного заведения, который работает на Платформе для курирования участия в мероприятиях своих студентов</p>
          </fieldset>
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Должность ответственного*</legend>
            <input type="text" value={form.responsiblePersonPosition || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, responsiblePersonPosition: e.target.value});
            }} className="input-challenges" placeholder="Декан"/>
          </fieldset>
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">E-mail ответственного*</legend>
            <input type="email" value={form.responsiblePersonEmail || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, responsiblePersonEmail: e.target.value});
            }} className="input-challenges"
                   placeholder="info@iman.ru"/>
            <div className="answer" onMouseOver={() => setRespPersonEmailHint(!respPersonEmailHint)} onMouseOut={() => setRespPersonEmailHint(!respPersonEmailHint)}></div>
            <p className="answer-text" style={{display: respPersonEmailHint ? "" : "none"}}>Укажите свой рабочий адрес электронной почты</p>
          </fieldset>
          <fieldset className="block-input" disabled={form.onModeration || form.isApproved}>
            <legend className="legend">Мобильный номер телефона ответственного*</legend>
            <InputMask mask="+999999999999999" value={form.responsiblePersonPhone || ""} className="input-challenges"
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                       {setForm({...form, responsiblePersonPhone: e.target.value});
                       }}/>
            {/*<input type="tel" value={form.responsiblePersonPhone || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {*/}
            {/*  setForm({...form, responsiblePersonPhone: e.target.value});*/}
            {/*}} className="input-challenges"*/}
            {/*       placeholder="+79001234567"/>*/}
          </fieldset>

          <input type="button" className="btn-main" value="Сохранить" onClick={handleUpdate} />{/*disabled={SessionModel.getCurrentUserAuthData().type !== 'ADMIN' && (form.onModeration || form.isApproved)}*/}

          <ProfileSettings profile={form}/>

          {SessionModel.isAdmin() || SessionModel.isModerator() ? <AdminUniversityProfileSettings profile={form}/> : <></>}
        </div>
      </>
  )
}