import React from "react";
import {ITestResult} from "../../../types/ITestResult";
import {differenceHMS, differenceInMin} from "../../../helpers/DateHelper";
import plural from "plural-ru";

export const TestResult: React.FC<{result: ITestResult}> = ({result}) => {

    const getEstimatedTime = () => {
        const hms = differenceHMS(result.startedAt, result.finishedAt);
        return ((hms.h || "00") + ":" + (hms.m || "00") + ":" + (hms.s || "00"))
    }

    return (
        <div className="container-right-block">
            <div className="menu-challenge">
                <div className="row-wrapper">
                    <p className="number-question">
                        Поздравляем
                    </p>
                </div>
                <h2 className="headline-right-block">
                    Тестирование завершено
                </h2>
                <p className="test-description-start">
                    <div className="text-editor-style" dangerouslySetInnerHTML={{__html: result.commonText}}></div>
                </p>
                <div className="stage-button">
                    <p className="name-stat">Ваш результат в процентах</p>
                    <h2 className="headline-right-block">
                        {Number((100 / result.maxPoints * result.points).toFixed(1))}% верных ответов
                    </h2>
                    <p className="point-system">
                        Мои баллы <span className="point-system-fact">{result.points}</span> / максимум <span
                        className="point-system-max">{result.maxPoints}</span>
                    </p>
                    <p className="point-system">
                        {/*Затраченное время <span className="point-system-fact">{plural(differenceInMin(result.startedAt, result.finishedAt), "%d минута", "%d минуты", "%d минут")}</span>*/}
                        Затраченное время <span className="point-system-fact">{getEstimatedTime()}</span>
                    </p>
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Если мероприятие является командным, то тест должны пройти все участники команды. Будет учитываться средний балл участников команды. После окончания этапа будет определён итоговый результат команды, равный сумме баллов всех попыток, разделённой на количество членов команды.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}