import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IChallengeCategory} from "../../../types/IChallengeCategory";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";

export const NavigationParticipant:React.FC<{categoryCode?: string}> = ({categoryCode}) => {

  const navigate = useNavigate();
  const [challengeCategories, setChallengeCategories] = useState<IChallengeCategory[]>([]);
  const [hasPadawans, setHasPadawans] = useState(false);

  useEffect(() => {
    ChallengeAPI.getChallengeCategories((response) => {
      if (response.status === 200) {
        setChallengeCategories(response.data);
      }
    })
  }, []);

  useEffect(() => {
    ChallengeAPI.getMentorChallenges(0, "", (response) => {
      if (response.status === 200 && response.data.total > 0) {
        setHasPadawans(true);
      }
    })
  }, []);

  const handleExit = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  }

  const getLocation = () : string => {
    //let lastIndex = location.pathname.split("/").at(-1);
    let loc = location.pathname + location.search
    if(loc !== undefined)
      return loc;
    else
      return "";
  }

  const hideMenu = () => {
    // @ts-ignore
    document.querySelector(".menu").style.display = 'none';
  }

  const isActivePoint = (catCode: string) => {
    if (getLocation() === `/challenge?cat=${catCode}` || categoryCode === catCode)
      return true;
  }

  return (
      <nav className="menu">
        <div id="closemenu" className="burger-back arrow-back" onClick={hideMenu}>
          Закрыть
        </div>
        <ul className="menu-list">
          <ul className="menu-list">
            <li className="menu-item"><a href="/profile"
                                         className={getLocation() === "/profile" ? "menu-link profile-menu acvtive-menu" : "menu-link profile-menu"}>Профиль</a></li>
            {challengeCategories && challengeCategories.map((category: IChallengeCategory, key) =>
                <li className="menu-item" key={key}>
                  <a href={`/challenge?cat=${category.code}`} className={isActivePoint(category.code || "") ? (`menu-link ${category.code}-menu acvtive-menu`) : (`menu-link ${category.code}-menu`)}>{category.name}</a>
                </li>

            )}
            <li className="menu-item"><a href="/profile/certificates?tab=CERT"
                                         className={getLocation().includes("/profile/certificates") ? ("menu-link loot-menu acvtive-menu") : ("menu-link loot-menu")}>Достижения</a></li>
            {hasPadawans ?
                <li className="menu-item"><a href="/profile/mentor"
                                             className={getLocation().includes("/profile/mentor") ? ("menu-link acvtive-menu students-menu") : ("menu-link students-menu")}>Мои ученики</a></li>
                :
                <></>
            }
            <li className="menu-item"><a href="#0" className="menu-link exit-menu" onClick={handleExit}>Выход</a></li>
          </ul>
        </ul>
      </nav>
  )
}