import React, {useState} from "react";
import {ModalMessage} from "../ModalMessage";
import {ISubGetResponse} from "../../../types/subs/ISubGetResponse";
import {IChallenge} from "../../../types/IChallenge";
import {ISubItemChallenge} from "../../../types/subs/ISubItemChallenge";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import SessionModel from "../../../../stores/SessionModel";
import {IGrantRoleRequest} from "../../../types/subs/IGrantRoleRequest";
import * as CertificateAPI from "../../../../requests/CertificateAPI";
import * as TestAPI from "../../../../requests/TestAPI";

export const SubDetails:React.FC<{ close: () => void, sub: ISubGetResponse}> = ({close, sub}) => {

    const [challengeList, setChallengeList] = useState<IChallenge[]>([]);
    const [challengeName, setChallengeName] = useState("");
    const [challengeMap, setChallengeMap] = useState<ISubItemChallenge[]>(sub.challenges);

    const [challengeRoleList, setChallengeRoleList] = useState<string[]>(sub.rolesChallenge);
    const [testRoleList, setTestRoleList] = useState<string[]>(sub.rolesTest);
    const [certRoleList, setCertRoleList] = useState<string[]>(sub.rolesCertificate);

    const [error, setError] = useState("");
    const [sending, setSending] = useState(false);

    const updateChallengeList = (name: string) => {
        setChallengeName(name);
        ChallengeAPI.fetchChallenges(name, "all", SessionModel.getCurrentUserAuthData().id, (response) => {
            if (response.status === 200) {
                setChallengeList(response.data);
            } else {
            }
        })
    }

    const changeChallengeRoleList = (role: string) => {
        if (challengeRoleList.includes(role)) {
            let newRoleList = challengeRoleList.filter(r => r !== role);
            if (role === "CHALLENGE") {
                setChallengeMap([]);
                setChallengeList([]);
                setChallengeName("");
            }
            setChallengeRoleList(newRoleList);
        } else {
            setChallengeRoleList(challengeRoleList => [...challengeRoleList,role] );
        }
    }

    const changeTestRoleList = (role: string) => {
        if (testRoleList.includes(role)) {
            let newRoleList = testRoleList.filter(r => r !== role);
            setTestRoleList(newRoleList);
        } else {
            setTestRoleList(testRoleList => [...testRoleList, role]);
        }
    }

    const changeCertRoleList = (role: string) => {
        if (certRoleList.includes(role)) {
            let newRoleList = certRoleList.filter(r => r !== role);
            setCertRoleList(newRoleList);
        } else {
            setCertRoleList(certRoleList => [...certRoleList, role]);
        }
    }

    const changeChallengeMap = (key: number, value: string, isRemove?: boolean) => {
        if (isRemove) {
            let newMap = challengeMap.filter(c => c.id !== key);
            setChallengeMap(newMap);
        } else {
            let existent = challengeMap.filter(c => c.id === key);
            if (existent.length > 0) return;
            let newItem: ISubItemChallenge = {
                id: key,
                name: value
            }
            setChallengeMap(challengeMap => [...challengeMap, newItem]);
        }
    }

    const submit = () => {
        if (sending) return;
        setSending(true);
        let challengeRequest: IGrantRoleRequest = {
            subId: sub.subId,
            roles: challengeRoleList,
            challengeIds: challengeMap.length > 0 ? challengeMap.map(item => item.id) : []
        };
        let testRequest: IGrantRoleRequest = {
            subId: sub.subId,
            roles: testRoleList,
            challengeIds: []
        };
        let certRequest: IGrantRoleRequest = {
            subId: sub.subId,
            roles: certRoleList,
            challengeIds: []
        }
        ChallengeAPI.updateSubRoles(challengeRequest, (response) => {
            if (response.status !== 200) {
                setError("Ошибка обновления ролей в сервисе challenge");
                setSending(false);
            } else {
                CertificateAPI.updateSubRoles(certRequest, (response) => {
                    if (response.status !== 200) {
                        setError("Ошибка обновления ролей в сервисе certificate");
                        setSending(false);
                    } else {
                        TestAPI.updateSubRoles(testRequest, (response) => {
                            if (response.status !== 200) {
                                setError("Ошибка обновления ролей в сервисе test");
                                setSending(false);
                            } else {
                                window.location.reload();
                            }
                        })
                    }
                })
            }
        });
    }

    return (
        <div className="right-block">
            <header className="header">
                <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                <p className="name-menu">Роли</p>
            </header>

            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <h2 className="headline-right-block">{!sub.firstname ? "Запрос отправлен" : `${sub.lastname} ${sub.firstname}`}</h2>
                    {
                        sub.status === "PENDING" ?
                            <p className="challenges-description">
                                Ожидание подтверждения запроса пользователем
                            </p>
                            :
                            <>
                                <p className="challenges-description" style={{fontWeight: "bold"}}>
                                    Мероприятия
                                </p>
                                <div className="role-wrapper" style={{cursor: "pointer"}} onClick={() => changeChallengeRoleList("MASTER")}>
                                    <p className="input-role">Полные права (создание новых мероприятий и редактирование уже существующих)</p>
                                    {challengeRoleList.includes("MASTER") ? <button className="btn-ok"/> : <button className="btn-non-check"/>}
                                </div>
                                {
                                    challengeRoleList.includes("MASTER") ?
                                        <></>
                                        :
                                        <>
                                            <div className="role-wrapper" style={{cursor: "pointer"}} onClick={() => changeChallengeRoleList("CREATOR")}>
                                                <p className="input-role">Создание новых мероприятий (без доступа к уже существующим)</p>
                                                {challengeRoleList.includes("CREATOR") ? <button className="btn-ok"/> : <button className="btn-non-check"/>}
                                            </div>
                                            <div className="role-wrapper" style={{cursor: "pointer"}} onClick={() => changeChallengeRoleList("CHALLENGE")}>
                                                <p className="input-role">Администрирование ранее созданных мероприятий (по списку)</p>
                                                {challengeRoleList.includes("CHALLENGE") ? <button className="btn-ok"/> : <button className="btn-non-check"/>}
                                            </div>
                                            {
                                                challengeRoleList.includes("CHALLENGE") ?
                                                    <>
                                                        <fieldset className="block-input">
                                                            <legend className="legend">Мероприятие</legend>
                                                            <div className="stage-card-new-wrapper-link">
                                                                {
                                                                    challengeMap.map((challengeItem, index) =>
                                                                        <a className="card-standart-link" key={index}
                                                                           onClick={() => changeChallengeMap(challengeItem.id, challengeItem.name, true)}>
                                                                            {challengeItem.name} [X]
                                                                        </a>
                                                                    )
                                                                }
                                                            </div>
                                                            <p className="template-text">
                                                                Начните вводить название мероприятия и выберите его из предложенного списка
                                                            </p>
                                                            <input type="text" className="input-challenges city-search"
                                                                   placeholder="Начните вводить" value={challengeName}
                                                                   onChange={(e) => updateChallengeList(e.target.value)}/>
                                                            <div className="container-city">
                                                                {challengeList?.map((challenge) =>
                                                                    <div className="cert-name-input" key={challenge.id} onClick={() => changeChallengeMap(challenge.id || 0, challenge.name)}>
                                                                        {challenge.name}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </fieldset>
                                                    </>
                                                    :
                                                    <></>
                                            }
                                        </>
                                }
                                <p className="challenges-description" style={{fontWeight: "bold"}}>
                                    Тесты
                                </p>
                                <div className="role-wrapper" style={{cursor: "pointer"}} onClick={() => changeTestRoleList("CREATOR")}>
                                    <p className="input-role">Создание и редактирование новых тестов и просмотр уже существующих</p>
                                    {testRoleList.includes("CREATOR") ? <button className="btn-ok"/> : <button className="btn-non-check"/>}
                                </div>
                                <p className="challenges-description" style={{fontWeight: "bold"}}>
                                    Сертификаты
                                </p>
                                <div className="role-wrapper" style={{cursor: "pointer"}} onClick={() => changeCertRoleList("CREATOR")}>
                                    <p className="input-role">Создание и редактирование новых сертификатов (без доступа к созданным другими админами)</p>
                                    {certRoleList.includes("CREATOR") ? <button className="btn-ok"/> : <button className="btn-non-check"/>}
                                </div>
                            </>
                    }
                </div>
                {sub.status === "PENDING" ? <></> : <input type="button" className="btn-main" value={sending ? "Подождите..." : "Сохранить"} onClick={submit}/>}
            </div>
        </div>
    )
}