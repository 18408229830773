import React, {useEffect, useState} from "react";
import {IPublicStageResult} from "../../../types/IPublicStageResult";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {ModalMessage} from "../../common/ModalMessage";
import {useParams} from "react-router-dom";
import {toNumber} from "lodash";

export const PublicStageResultSettings: React.FC<{
    currentSettings: IPublicStageResult,
    visibility: boolean,
    setVisibility: (visible: boolean) => void,
    wasPublished: boolean}> = ({currentSettings, visibility, setVisibility, wasPublished}) => {

    const [error, setError] = useState("");
    const params = useParams();
    const [settings, setSettings] = useState<IPublicStageResult>(currentSettings);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        ChallengeAPI.getPublicResultSettings(toNumber(params.id), toNumber(params.stageId), (response) => {
            if (response.status === 200) {
                setSettings(response.data);
            } else if (response.status !== 404) {
                setError("Ошибка при получении настроек протокола");
            }
        })
    }, [visibility]);

    const handleFileUpload = function (e: React.ChangeEvent<HTMLInputElement>, type: string) {
        const fileList = e.target.files;

        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер файла не должен превышать 5Мб.");
            return;
        }

        const formData = new FormData();
        formData.append("file", fileList[0], fileList[0].name);
        ChallengeAPI.uploadPublicResultFile(toNumber(params.id), toNumber(params.stageId), formData, type, (response) => {
            if (response.status === 200) {
                if (type === "stamp") {
                    setSettings({...settings, resultResponsibleStamp: response.data.resultResponsibleStamp});
                } else if (type === "sign") {
                    setSettings({...settings, resultResponsibleSign: response.data.resultResponsibleSign});
                }
            } else {
                setError("Ошибка при загрузке файла");
            }
        })
    };

    const update = () => {
        setShowSpinner(true);
        console.log(settings);
        ChallengeAPI.updatePublicResultSettings(toNumber(params.id), toNumber(params.stageId), settings, (response) => {
            if (response.status === 200) {
                setSettings(response.data);
            } else {
                setError("Ошибка при сохранении настроек");
            }
            setShowSpinner(false);
        })
    }

    const publish = () => {
        setShowSpinner(true);
        ChallengeAPI.publishPublicResult(toNumber(params.id), toNumber(params.stageId), (response) => {
            setShowSpinner(false);
            if (response.status === 200) {
                setSettings(response.data);
            } else {
                setError("Ошибка при публикации результатов");
            }
        });
    }

    const preview = () => {
        if (!wasPublished) {
            if (confirm("Форма предпросмотра печатной версии протокола доступна только после нажатия на кнопку \"Опубликовать результаты\"")) {
                window.open(`/challenge/${params.id}/stage/${params.stageId}/protocol/${settings.id}`, '_blank');
            }
        } else {
            window.open(`/challenge/${params.id}/stage/${params.stageId}/protocol/${settings.id}`, '_blank');
        }
    }

    if (showSpinner) {
        return (
            <div className="load-block">
                <p className="load-sheet">
                    загрузка
                </p>
                <div className="circle-block">
                    <div className="circle-load one-load"></div>
                    <div className="circle-load two-load"></div>
                    <div className="circle-load three-load"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="rating-print-settings" style={{display: visibility ? "" : "none"}}>
                <ModalMessage msg={error} header={"Инфо"} _callback={() => setError("")}/>
                <div className="rating-print-export">
                    <a className="close-window" onClick={() => setVisibility(false)} style={{cursor: "pointer"}}></a>
                    <p className="fio-or-name">
                        Настройки печатной версии
                    </p>
                    <form action="" className="rating-print-export-form">
                        <fieldset className="rating-print-fieldset">
                            <legend className="rating-print-legend">Отображение</legend>
                            <label htmlFor="rating-num" className="rating-print-label">
                                <input type="checkbox" id="rating-num" className="rating-print-export" onChange={() => setSettings({...settings, showPlace: !settings.showPlace})} checked={settings.showPlace !== null ? settings.showPlace : false}/>
                                <span className="rating-print-span">Место</span>
                            </label>
                            <label htmlFor="rating-fio-comand" className="rating-print-label">
                                <input type="checkbox" id="rating-fio-comand" className="rating-print-export" onChange={() => setSettings({...settings, showName: !settings.showName})} checked={settings.showName !== null ? settings.showName : false}/>
                                <span className="rating-print-span">ФИО/Команда</span>
                            </label>
                            <label htmlFor="rating-vuz" className="rating-print-label">
                                <input type="checkbox" id="rating-vuz" className="rating-print-export" onChange={() => setSettings({...settings, showUniversity: !settings.showUniversity})} checked={settings.showUniversity !== null ? settings.showUniversity : false}/>
                                <span className="rating-print-span">Учебное заведение</span>
                            </label>
                            <label htmlFor="rating-country" className="rating-print-label">
                                <input type="checkbox" id="rating-country" className="rating-print-export" onChange={() => setSettings({...settings, showCountry: !settings.showCountry})} checked={settings.showCountry !== null ? settings.showCountry : false}/>
                                <span className="rating-print-span">Страна</span>
                            </label>
                            <label htmlFor="rating-city" className="rating-print-label">
                                <input type="checkbox" id="rating-city" className="rating-print-export" onChange={() => setSettings({...settings, showCity: !settings.showCity})} checked={settings.showCity !== null ? settings.showCity : false}/>
                                <span className="rating-print-span">Город</span>
                            </label>
                            <label htmlFor="rating-points" className="rating-print-label">
                                <input type="checkbox" id="rating-points" className="rating-print-export" onChange={() => setSettings({...settings, showPoints: !settings.showPoints})} checked={settings.showPoints !== null ? settings.showPoints : false}/>
                                <span className="rating-print-span">Баллы</span>
                            </label>
                            <label htmlFor="rating-percent" className="rating-print-label">
                                <input type="checkbox" id="rating-percent" className="rating-print-export" onChange={() => setSettings({...settings, showPercent: !settings.showPercent})} checked={settings.showPercent !== null ? settings.showPercent : false}/>
                                <span className="rating-print-span">Проценты</span>
                            </label>
                            <label htmlFor="rating-admittance" className="rating-print-label">
                                <input type="checkbox" id="rating-admittance" className="rating-print-export" onChange={() => setSettings({...settings, showPass: !settings.showPass})} checked={settings.showPass}/>
                                <span className="rating-print-span">Допуск</span>
                            </label>
                        </fieldset>
                    </form>
                    <div className="rating-export-wrapper">
                        <input id="rating-stamp" type="file" onChange={(e) => handleFileUpload(e, "stamp")} className="disable" accept=".jpg,.jpeg,.png"/>
                        <label htmlFor="rating-stamp" className="load-image-test-btn" style={{cursor: "pointer"}}>{settings.resultResponsibleStamp ? "Заменить печать" : "Загрузить печать" }</label>
                        <input id="rating-signatory" type="file" className="disable" onChange={(e) => handleFileUpload(e, "sign")} accept=".jpg,.jpeg,.png"/>
                        <label htmlFor="rating-signatory" className="load-image-test-btn rating-export-btn" style={{cursor: "pointer"}}>{settings.resultResponsibleSign ? "Заменить подпись" : "Загрузить подпись"}</label>
                    </div>
                    <textarea rows={5} placeholder="Описание" className="input-challenges" onChange={(e) => setSettings({...settings, description: e.target.value})} value={settings.description}></textarea>
                    <input type="text" className="input-challenges" placeholder="ФИО подписанта" onChange={(e) => setSettings({...settings, resultResponsibleName: e.target.value})} value={settings.resultResponsibleName}/>
                    <input type="text" className="input-challenges" placeholder="Должность подписанта" onChange={(e) => setSettings({...settings, resultResponsiblePosition: e.target.value})} value={settings.resultResponsiblePosition}/>
                    <fieldset className="block-input" >
                        <legend className="legend">Дата протокола</legend>
                        <input type="date" className="input-challenges" placeholder="Дата протокола" onChange={(e) => setSettings({...settings, publicationDate: e.target.value})} value={settings.publicationDate} min="2020-01-01" max="2100-01-01"/>
                    </fieldset>
                    <div className="flex-btn-stage">
                        <div className="flex-btn-template">
                            <a className="btn-blue-width" onClick={update}>Сохранить</a>
                            <a onClick={preview} className="btn-blue-width" style={{display: settings.id ? "" : "none", width: "fit-content"}}>Предпросмотр</a>
                        </div>
                        <label htmlFor="dopusk" className="rating-print-label">
                            {settings.id ?
                            <>
                                <input type="checkbox" id="dopusk" className="rating-print-export" checked={settings.isPublished} onClick={publish}/>
                                <span className="rating-print-span">Протокол доступен всем</span>
                            </> : ""}
                        </label>
                    </div>
                </div>
            </div>
        )
    }
}