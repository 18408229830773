import React, {useEffect, useState} from "react";
import API from "../../../../requests/_axios";
import {IChallenge} from "../../../types/IChallenge";
import {useNavigate, useParams} from "react-router-dom";
import {StageDetails} from "./right/StageDetails";
import {ProfileIndividualHeader} from "../profile/ProfileIndividualHeader";
import {StageList} from "./tabs/StageList";
import {NavigationIndividualChallenge} from "../navigation/NavigationIndividualChallenge";
import {Team} from "./tabs/Team";
import {NewTeam} from "./right/NewTeam";
import {toNumber} from "lodash";
import {ProfileIndividualView} from "../../common/ProfileIndividualView";
import {IProfilePersonalForm} from "../../../types/IProfilePersonalForm";
import {ITeam} from "../../../types/ITeam";
import {ITeamResponse} from "../../../types/ITeamResponse";
import SessionModel from "../../../../stores/SessionModel";
import {Ads} from "./tabs/Ads";
import {ITeamAd} from "../../../types/ITeamAd";
import {NewAd} from "./right/NewAd";
import {RecruitmentTeam} from "./tabs/RecruitmentTeam";
import {RecruitmentPersonal} from "./tabs/RecruitmentPersonal";
import {AdDetails} from "./right/AdDetails";
import {ChallengeInfo} from "./ChallengeInfo";
import {BottomNav} from "./tabs/BottomNav";
import {Help} from "../../common/Help";
import {Spinner} from "../../common/Spinner";
import {formatDate} from "../../../helpers/DateHelper";

import {Rating} from "./right/Rating";
import {MyResults} from "./right/MyResults";
import {CONFIG} from "../../../../CONFIG";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";
import {Jury} from "../jury/Jury";
import {MentorParticipant} from "./tabs/MentorParticipant";

interface IChallengePassword {
  password: string;
}

export const ChallengeMy = () => {

  const params = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState("");
  const [currentStageId, setCurrentStageId] = useState<any>(null);
  const [displayNewTeam, setDisplayNewTeam] = useState(false);
  const [currentTeamMember, setCurrentTeamMember] = useState<IProfilePersonalForm | undefined>(undefined);
  const [currentMemberTeam, setCurrentMemberTeam] = useState<ITeam | undefined>(undefined);
  const [currentTeam, setCurrentTeam] = useState<ITeam | undefined>(undefined);
  const [currentAd, setCurrentAd] = useState<ITeamAd | undefined>(undefined);
  const [teamResponse, setTeamResponse] = useState<ITeamResponse>();
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [challenge, setChallenge] = useState<IChallenge>({
    name: "",
    startDate: "",
    endDate: "",
    description: "",
    challengeType: "",
    partyFrom: 0,
    partyTo: 0,
    alias: ""
  });
  const [recruitmentPanelEnabled, setRecruitmentPanelEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [challengePassword, setChallengePassword] = useState<IChallengePassword>({password: ""});

  // show/hide right
  const [ratingStage, setRatingStage] = useState<number | undefined>(undefined);
  const [myResultStageId, setMyResultStageId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (myResultStageId) {
      setRatingStage(undefined);
      hideStage();
    }
  }, [myResultStageId]);

  useEffect(() => {
    if (ratingStage) {
      setMyResultStageId(undefined);
      hideStage();
    }
  }, [ratingStage]);

  useEffect(() => {
    if (currentStageId !== null) {
      setMyResultStageId(undefined);
      setRatingStage(undefined);
    }
  }, [currentStageId]);

  useEffect(() => {
    if (!SessionModel.isPersonal()) {
      alert("Для доступа к данной странице вы должны быть авторизованы на платформе в статусе \"Участник\".");
      window.location.href = "/profile";
    }
    API.get("/challenge/api/v1/challenge/" + params.id + "/my").then((response) => {
      if (response.status === 200) {
        setChallenge(response.data);
        setLoading(false);
      } else {
        API.get("/challenge/api/v1/challenge/" + params.id).then((response) => {
          if (response.status === 404) {
            navigate("/404");
          } else {
            setChallenge({...challenge, id: response.data.id, name: response.data.name, startDate: response.data.startDate, endDate: response.data.endDate, registrationEndDate: response.data.registrationEndDate, rules: response.data.rules, password: response.data.password, site: response.data.site});
            setLoading(false);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (challenge.challengeType === "COMMAND") {
      API.get("/challenge/api/v1/challenge/" + params.id + "/team/my").then((response) => {
        if(response.status === 200) {
          setTeamResponse(response.data);
        } else {
          if (!params.tab) {
            if (challenge?.challengeUser?.isTeamCreator === false && challenge?.challengeUser?.status !== 2)
              setTab("recruitment");
            else
              setTab("team");
          }
        }
      });
    } else {
      if (!params.tab) {
        setTab("stages");
      }
    }
  }, [challenge]);

  useEffect(() => {
    if (params.tab)  setTab(params.tab);
  }, [params.tab]);

  const handleParticipate = (id: number) => {
    setIsSending(true);
    if (challenge.password && challenge.password.length > 0 && (!challengePassword?.password || challengePassword?.password.length == 0)) {
      setError("Неверный пароль для мероприятия");
      setIsSending(false);
      return;
    }
    API.post("/challenge/api/v1/challenge/" + id + "/join", challengePassword).then((response => {
      if(response.status === 200) {
        window.location.reload();
      } else if (response.data.code === "009-034" || response.data.code === "009-035") {
        setError("Достигнут лимит по тарифному плану создателя мероприятия. Пожалуйста, обратитесь в поддержку");
      } else if (response.data.code === "009-008") {
        setError("Доступ запрещен");
      } else if (response.data.code === "009-037") {
        setError("Неверный пароль для мероприятия");
      } else {
        setError("Чтобы участвовать в мероприятии, вам необходимо заполнить профиль");
      }
      setIsSending(false);
    }));
  }

  const showProfilePanel = (profile: IProfilePersonalForm, team: ITeam | undefined, enableRecruitmentPanel: boolean) : void => {
    setCurrentTeamMember(profile);
    setCurrentMemberTeam(team);
    setRecruitmentPanelEnabled(enableRecruitmentPanel);
    showTeamMember();
  }

  const updateCurrentAd = (ad: ITeamAd | undefined) : void => {
    setCurrentAd(ad);
    showAd();
  }

  const canViewBottomMobileNav = () : boolean => {
    if (!teamResponse?.team.id && challenge.challengeUser?.isTeamCreator === false) {
      if (teamResponse?.team?.id || challenge.challengeUser?.isTeamCreator === null) {
        return false;
      } else {
        return true;
      }
    } else if (teamResponse?.team?.userId === toNumber(SessionModel.getCurrentUserAuthData().id) && teamResponse?.team.status === 3) {
      return true;
    }
    return false;
  }

  const hideStage = () => {
    setCurrentStageId(null);
    // @ts-ignore
    //document.querySelector('#right-block-stage').style.right = "-100vw";
  }

  const hideNewTeam = () => {
    setDisplayNewTeam(false);
    let selector = document.querySelector('#right-block-team') as HTMLElement;
    if (selector) {
      selector.style.right = "-100vw";
    }
  }

  const hideTeamMember = () => {
    setCurrentMemberTeam(undefined);
    let selector = document.querySelector('#right-block-team-member') as HTMLElement;
    if (selector) {
      selector.style.right = "-100vw";
    }
  }

  const hideAd = () => {
    let selector = document.querySelector('#right-block-ad') as HTMLElement;
    if (selector) {
      selector.style.right = "-100vw";
    }
  }

  const hideAdDetails = () => {
    let selector = document.querySelector('#right-block-ad-details') as HTMLElement;
    if (selector) {
      selector.style.right = "-100vw";
    }
  }

  const hideChallengeDetails = () => {
    // @ts-ignore
    document.querySelector('#right-block-challenge-details').style.right = "-100vw";
  }

  const showStage = (id: number | undefined) => {
    if (challenge?.challengeUser?.status !== 2) {
      hideAd();
      hideTeamMember();
      hideNewTeam();
      hideAdDetails();
      hideChallengeDetails();
      setRatingStage(undefined);
      setMyResultStageId(undefined);
    }
    // @ts-ignore
    document.querySelector('#right-block-stage').style.right = "-100vw";
    setCurrentStageId(id);
    // @ts-ignore
    document.querySelector('#right-block-stage').style.right = "0";
  }

  const showNewTeam = (team: ITeam | undefined) => {
    hideAd();
    hideTeamMember();
    hideStage();
    hideAdDetails();
    hideChallengeDetails();
    setCurrentTeam(team);
    setDisplayNewTeam(true);
    setRatingStage(undefined);
    setMyResultStageId(undefined);
    // @ts-ignore
    document.querySelector('#right-block-team').style.right = "0";
  }

  const showTeamMember = () => {
    hideAd();
    hideNewTeam();
    hideStage();
    //hideAdDetails();
    hideChallengeDetails();
    setRatingStage(undefined);
    setMyResultStageId(undefined);
    // @ts-ignore
    document.querySelector('#right-block-team-member').style.right = "0";
  }

  const showAd = () => {
    hideNewTeam();
    hideStage();
    hideTeamMember();
    hideAdDetails();
    hideChallengeDetails();
    setRatingStage(undefined);
    setMyResultStageId(undefined);
    // @ts-ignore
    document.querySelector('#right-block-ad').style.right = "0";
  }

  const showAdDetails = (ad: ITeamAd) => {
    hideNewTeam();
    hideStage();
    hideTeamMember();
    hideAd();
    hideChallengeDetails();
    setCurrentAd(ad);
    setRatingStage(undefined);
    setMyResultStageId(undefined);
    // @ts-ignore
    document.querySelector('#right-block-ad-details').style.right = "0";
  }

  const showChallengeDetails = () => {
    hideNewTeam();
    hideStage();
    hideTeamMember();
    hideAd();
    hideAdDetails();
    setRatingStage(undefined);
    setMyResultStageId(undefined);
    let selector = document.querySelector('#right-block-challenge-details') as HTMLElement;
    if (selector) {
      selector.style.right = "0";
    }
  }

  const canDeleteTeam = () : boolean => {
    if (challenge.registrationEndDate && new Date(challenge.registrationEndDate) > new Date() && teamResponse?.team.status === 3)
      return toNumber(SessionModel.getCurrentUserAuthData().id) === teamResponse?.team.userId
    return false;
  }

  const canLeaveTeam = () : boolean => {
    if (challenge.registrationEndDate && new Date(challenge.registrationEndDate) > new Date() && teamResponse?.team.status === 3)
      return toNumber(SessionModel.getCurrentUserAuthData().id) !== teamResponse?.team.userId
    return false;
  }

  const leaveTeam = () => {
    API.post(`/challenge/api/v1/challenge/${params.id}/team/my/leave`).then((response) => {
      if(response.status === 200) {
        navigate("/challenge");
      }
    })
  }

  const deleteTeam = () => {
    API.delete(`/challenge/api/v1/challenge/${params.id}/team/${teamResponse?.team.id}`).then((response) => {
      if(response.status === 200) {
        window.location.reload();
      }
    })
  }

  if (loading) {
    return (
        <Spinner text={"Загрузка"} enabled={true}/>
    )
  } else if (challenge?.challengeUser?.status === 2) {
    // WASTED
      return (
          <div className="platform-background">
            <Help challenge={challenge}/>
            {challenge.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back-video"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                      <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                  <source src={require("../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
            <div className="left-block">
              <ProfileIndividualHeader newPreview={""}/>
              <main className="content">
                <NavigationIndividualMain categoryCode={challenge.categoryCode}/>
                <div className="main-list-center-comand">
                  <div className="my-challenge">
                    <a href={challenge.site} target="_blank" className="link-landing"></a>
                    <div className="user-info">
                      <p className="fio-or-name" onClick={showChallengeDetails} style={{cursor: "pointer"}}>{challenge.name}</p>
                    </div>
                    <ul className="nav-block-center">
                      <li className="nav-center-item">
                        <a href={"/challenge/" + challenge.id + "/my/stages"}
                           className={tab === "stages" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Задания</a>
                      </li>
                      {challenge.challengeType === "COMMAND" ? (
                          <>
                            <li className="nav-center-item">
                              <a href={"/challenge/" + challenge.id + "/my/team"}
                                 className={tab === "team" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Команда</a>
                            </li>
                          </>
                      ) : (<></>)}
                      <li className="nav-center-item">
                        <a href={"/challenge/" + challenge.id + "/my/mentor"}
                           className={tab === "mentor" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Наставник</a>
                      </li>
                    </ul>
                  </div>
                  {tab === "stages" ? <StageList showRating={setRatingStage} showMyResults={setMyResultStageId} challenge={challenge} showStage={showStage} enabled={tab === "stages"}/> : ""}
                  {tab === "team" ? <Team challengeId={toNumber(params.id)} challenge={challenge} showNewTeam={showNewTeam} showProfilePanel={showProfilePanel} enabled={tab === "team"}/> : ""}
                  {tab === "mentor" ? <MentorParticipant challenge={challenge} enabled={tab === "mentor"}/> : ""}
                </div>
              </main>
            </div>


            <div className="right-block" id="right-block-stage" style={{right: currentStageId !== null ? "0" : "-100vw"}}>
              <header className="header">
                <p className="header-back" id="backmenu" onClick={() => hideStage()}>Назад</p>
                <p className="name-menu">Данные этапа</p>
              </header>
              {currentStageId !== null ? (
                  <StageDetails stageId={currentStageId} challenge={challenge} key={currentStageId}/>
              ) : (<></>)}
            </div>
            <div className="right-block" id="right-block-team" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" id="backmenu" onClick={() => hideNewTeam()}>Назад</p>
                <p className="name-menu">Новая команда</p>
              </header>
              {displayNewTeam ? (
                  <NewTeam team={currentTeam} challengeId={challenge.id}/>
              ) : (<></>)}
            </div>
            <div className="right-block" id="right-block-ad" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideAd} id="backmenu">Назад</p>
                <p className="name-menu">Объявление о наборе</p>
              </header>
              <NewAd key={currentAd?.id} challengeId={challenge.id} ad={currentAd} teamId={teamResponse?.team.id}/>
            </div>
            <div className="right-block" id="right-block-ad-details" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideAdDetails} id="backmenu">Назад</p>
                <p className="name-menu">Объявление о наборе</p>
              </header>
              <AdDetails key={currentAd?.id} ad={currentAd} showProfilePanel={showProfilePanel}/>
            </div>
            <div className="right-block" id="right-block-challenge-details" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideChallengeDetails} id="backmenu">Назад</p>
                <p className="name-menu">Мероприятие</p>
              </header>
              <ChallengeInfo challengeId={challenge.id || 0}/>
            </div>
            <div className="right-block" id="right-block-team-member" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideTeamMember} id="backmenu">Назад</p>
                <p className="name-menu">Участник команды</p>
              </header>
              {
                currentTeamMember ? (
                    <ProfileIndividualView
                        key={currentTeamMember.id}
                        currentForm={currentTeamMember}
                        team={currentMemberTeam} enableRecruitmentPanel={recruitmentPanelEnabled}/>
                ) : (<></>)
              }
            </div>

            {/* New after redisign */}

            <div className="right-block" id="right-block-rating" style={{right: ratingStage ? "0" : "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={() => setRatingStage(undefined)} id="backmenu">Назад</p>
                <p className="name-menu">Рейтинг</p>
              </header>
              {
                ratingStage ? <Rating stageId={ratingStage}/> : <></>
              }
            </div>
            <div className="right-block" id="right-block-my-results" style={{right: myResultStageId ? "0" : "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={() => setMyResultStageId(undefined)} id="backmenu">Назад</p>
                <p className="name-menu">Результаты</p>
              </header>
              {
                myResultStageId ? <MyResults stageId={myResultStageId}/> : <></>
              }
            </div>
          </div>
      )
    } else {
    if (challenge.challengeType && teamResponse?.team && teamResponse.team.isBanned) {
      // BLOCKED
      return (
          <div className="platform-background">
            <Help challenge={challenge}/>
            {challenge.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back-video"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                      <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                  <source src={require("../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
            <div className="left-block">
              <ProfileIndividualHeader newPreview={""}/>
              <main className="content">
                <NavigationIndividualMain categoryCode={challenge.categoryCode}/>
                <div className="main-list-center-comand">
                  <div className="my-challenge">
                    <a href={challenge.site} target="_blank" className="link-landing"></a>
                    <div className="user-info">
                      <p className="fio-or-name" onClick={showChallengeDetails} style={{cursor: "pointer"}}>{challenge.name}</p>
                    </div>
                  </div>
                  <div className="challenge-info">
                    <p className="important-message">
                      Ваша команда заблокирована, обратитесь в техподдержку Braim
                    </p>
                    <button className="del-coamnd" onClick={() => {if(window.confirm('Вы действительно желаете расформировать и удалить команду?')){deleteTeam()}}} style={{display: canDeleteTeam() ? "" : "none"}}>Удалить команду</button>
                    <button className="del-coamnd" onClick={() => {if(window.confirm('Вы действительно желаете покинуть команду?')){leaveTeam()}}} style={{display: canLeaveTeam() ? "" : "none"}}>Покинуть команду</button>
                  </div>
                </div>
              </main>
            </div>
          </div>
      )
    } else if (challenge.challengeType && challenge.jury) {
      return (<Jury challenge={challenge}/>)
    } else if (challenge.challengeType) {
      return (
          <div className="platform-background">
            <Help challenge={challenge}/>
            {canViewBottomMobileNav() ? (<BottomNav isTeam={false} styleName={"notifications-search-mobile"}/>) : (<></>)}
            {challenge.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back-video"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                      <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                  <source src={require("../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
            <div className="left-block">
              <ProfileIndividualHeader newPreview={""}/>
              <main className="content">
                <NavigationIndividualMain categoryCode={challenge.categoryCode}/>
                <div className="main-list-center-comand">
                  <div className="my-challenge">
                    <a href={challenge.site} target="_blank" className="link-landing"></a>
                    <div className="user-info">
                      <p className="fio-or-name" onClick={showChallengeDetails} style={{cursor: "pointer"}}>{challenge.name}</p>
                    </div>
                    <ul className="nav-block-center">
                      <li className="nav-center-item">
                        <a href={"/challenge/" + challenge.id + "/my/stages"}
                           className={tab === "stages" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Задания</a>
                      </li>
                      {challenge.challengeType === "COMMAND" ? (
                          <>
                            <li className="nav-center-item">
                              <a href={"/challenge/" + challenge.id + "/my/team"}
                                 className={tab === "team" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Команда</a>
                            </li>
                            {teamResponse?.team?.userId === toNumber(SessionModel.getCurrentUserAuthData().id) && teamResponse?.team.status === 3 ? (
                                <>
                                  <li className="nav-center-item">
                                    <a href={"/challenge/" + challenge.id + "/my/ads"} className={tab === "ads" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Объявления</a>
                                  </li>
                                  <li className="nav-center-item">
                                    <a href={"/challenge/" + challenge.id + "/my/recruitment"} className={tab === "recruitment" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Набор</a>
                                  </li>
                                </>
                            ) : (
                                <>
                                  {!teamResponse?.team.id && challenge.challengeUser?.isTeamCreator === false && challenge?.challengeUser?.status !== 2 ? (
                                      <li className="nav-center-item" style={{display: teamResponse?.team?.id || challenge.challengeUser?.isTeamCreator === null ? "none" : ""}}>
                                        <a href={"/challenge/" + challenge.id + "/my/recruitment"} className={tab === "recruitment" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Объявления</a>
                                      </li>
                                  ) : (
                                      <></>
                                  )}
                                </>
                            )}
                          </>
                      ) : (<></>)}
                      <li className="nav-center-item">
                        <a href={"/challenge/" + challenge.id + "/my/mentor"}
                           className={tab === "mentor" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Наставник</a>
                      </li>
                    </ul>
                  </div>
                  {tab === "stages" ? <StageList showRating={setRatingStage} showMyResults={setMyResultStageId} challenge={challenge} showStage={showStage} enabled={tab === "stages"}/> : ""}
                  {tab === "team" ? <Team challengeId={toNumber(params.id)} challenge={challenge} showNewTeam={showNewTeam} showProfilePanel={showProfilePanel} enabled={tab === "team"}/> : ""}
                  {tab === "ads" ? <Ads challengeId={toNumber(params.id)} updateCurrentAd={updateCurrentAd} enabled={tab === "ads"}/> : ""}
                  {tab === "recruitment" && teamResponse?.team?.userId && teamResponse.team?.userId === toNumber(SessionModel.getCurrentUserAuthData().id) ? <RecruitmentTeam challengeId={toNumber(params.id)} showProfilePanel={showProfilePanel} enabled={tab === "recruitment"}/> : ""}
                  {tab === "recruitment" && challenge.challengeUser?.isTeamCreator === false ? <RecruitmentPersonal challengeId={toNumber(params.id)} showAdDetails={showAdDetails} enabled={tab === "recruitment"}/> : ""}
                  {tab === "mentor" ? <MentorParticipant challenge={challenge} enabled={tab === "mentor"}/> : ""}
                </div>
              </main>
            </div>


            <div className="right-block" id="right-block-stage" style={{right: currentStageId !== null ? "0" : "-100vw"}}>
              <header className="header">
                <p className="header-back" id="backmenu" onClick={() => hideStage()}>Назад</p>
                <p className="name-menu">Данные этапа</p>
              </header>
              {currentStageId !== null ? (
                  <StageDetails stageId={currentStageId} challenge={challenge} key={currentStageId}/>
              ) : (<></>)}
            </div>
            <div className="right-block" id="right-block-team" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" id="backmenu" onClick={() => hideNewTeam()}>Назад</p>
                <p className="name-menu">Новая команда</p>
              </header>
              {displayNewTeam ? (
                  <NewTeam team={currentTeam} challengeId={challenge.id}/>
              ) : (<></>)}
            </div>
            <div className="right-block" id="right-block-ad" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideAd} id="backmenu">Назад</p>
                <p className="name-menu">Объявление о наборе</p>
              </header>
              <NewAd key={currentAd?.id} challengeId={challenge.id} ad={currentAd} teamId={teamResponse?.team.id}/>
            </div>
            <div className="right-block" id="right-block-ad-details" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideAdDetails} id="backmenu">Назад</p>
                <p className="name-menu">Объявление о наборе</p>
              </header>
              <AdDetails key={currentAd?.id} ad={currentAd} showProfilePanel={showProfilePanel}/>
            </div>
            <div className="right-block" id="right-block-challenge-details" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideChallengeDetails} id="backmenu">Назад</p>
                <p className="name-menu">Мероприятие</p>
              </header>
              <ChallengeInfo challengeId={challenge.id || 0}/>
            </div>
            <div className="right-block" id="right-block-team-member" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideTeamMember} id="backmenu">Назад</p>
                <p className="name-menu">Участник команды</p>
              </header>
              {
                currentTeamMember ? (
                    <ProfileIndividualView
                        key={currentTeamMember.id}
                        currentForm={currentTeamMember}
                        team={currentMemberTeam} enableRecruitmentPanel={recruitmentPanelEnabled}/>
                ) : (<></>)
              }
            </div>

            {/* New after redisign */}

            <div className="right-block" id="right-block-rating" style={{right: ratingStage ? "0" : "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={() => setRatingStage(undefined)} id="backmenu">Назад</p>
                <p className="name-menu">Рейтинг</p>
              </header>
              {
                ratingStage ? <Rating stageId={ratingStage}/> : <></>
              }
            </div>
            <div className="right-block" id="right-block-my-results" style={{right: myResultStageId ? "0" : "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={() => setMyResultStageId(undefined)} id="backmenu">Назад</p>
                <p className="name-menu">Результаты</p>
              </header>
              {
                myResultStageId ? <MyResults stageId={myResultStageId}/> : <></>
              }
            </div>
          </div>
      )
    } else {
      // CONFIRMATION
      return (
          <div className="platform-background">
            <Help challenge={challenge}/>
            {challenge.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back-video"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                      <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                  <source src={require("../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
            <div className="left-block">
              <ProfileIndividualHeader newPreview={""}/>
              <main className="content">
                <NavigationIndividualMain categoryCode={challenge.categoryCode}/>
                <div className="main-list-center-comand">
                  <div className="my-challenge">
                    <a href={challenge.site} target="_blank" className="link-landing"></a>
                    <div className="user-info">
                      <p className="fio-or-name" onClick={showChallengeDetails} style={{cursor: "pointer"}}>{challenge.name}</p>
                    </div>
                  </div>
                  <div className="challenge-info">
                    <div className="btn" style={{cursor: "default"}}>
                      <p className="btn-heading profile">
                        Необходимо подтвердить ваше участие!
                      </p>
                      <p className="btn-description">
                        Нажимая на кнопку «Участвовать», вы соглашаетесь с <a href={challenge.rules} target="_blank" className="active-link">правилами</a> мероприятия, а также с информацией, опубликованной на
                        {challenge.site ? <a href={challenge.site} target="_blank" className="active-link"> сайте </a> : " сайте "} мероприятия.
                      </p>
                      <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>

                      {challenge.password && challenge.password.length > 0 ?
                          <input type="password" value={challengePassword?.password} className="input-challenges" placeholder="Введите пароль, выданный организатором"
                                 onChange={(e) => {setChallengePassword({...challengePassword, password: e.target.value});}}/> : <></>
                      }
                      {console.log(challenge.registrationEndDate)}
                      {new Date(challenge.startDate || "") < new Date() && new Date(challenge.registrationEndDate || "") > new Date() ?
                          <button onClick={() => handleParticipate(challenge.id || 0)} className="btn-main"
                                  style={{textAlign: "center"}}
                                  disabled={isSending}>{isSending ? "Подождите..." : "Участвовать"}</button>
                          :
                          new Date(challenge.startDate || "") > new Date() ?
                          <button className="btn-main" style={{textAlign: "center", cursor: "default"}} disabled={true}>Регистрация
                            с {formatDate(challenge.startDate)}</button>
                              :
                              <button className="btn-main" style={{textAlign: "center", cursor: "default"}} disabled={true}>Регистрация окончена</button>
                      }
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="right-block" id="right-block-challenge-details" style={{right: "-100vw"}}>
              <header className="header">
                <p className="header-back" onClick={hideChallengeDetails} id="backmenu">Назад</p>
                <p className="name-menu">Мероприятие</p>
              </header>
              <ChallengeInfo challengeId={challenge.id || 0}/>
            </div>
          </div>)
    }
  }
}