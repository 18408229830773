import React, {useEffect, useState} from "react";
import API from "../../../../requests/_axios";
import {IChallenge} from "../../../types/IChallenge";
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../../helpers/DateHelper";

interface IChallengePassword {
  password: string;
}

export const ChallengeInfo: React.FC<{challengeId: number}> = ({challengeId}) => {

  const [isParticipant, setIsParticipant] = useState(false);
  const [error, setError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [challengePassword, setChallengePassword] = useState<IChallengePassword>({password: ""});
  const [challenge, setChallenge] = useState<IChallenge>({
    name: "",
    startDate: new Date().toString(),
    endDate: new Date().toString(),
    description: "",
    challengeType: "",
    partyFrom: 0,
    partyTo: 0,
    alias: ""
  });
  const plural = require('plural-ru');
  const navigate = useNavigate();

  useEffect(() => {
    API.get("/challenge/api/v1/challenge/" + challengeId).then((response) => {
      setChallenge(response.data);
    });
    API.get("/challenge/api/v1/challenge/" + challengeId + "/my").then((response) => {
      if(response.status == 200)
        setIsParticipant(true);
      else
        setIsParticipant(false);
    })
  }, [challengeId]);

  const handleParticipate = (id: number) => {
    setIsSending(true);
    if (challenge.password && challenge.password.length > 0 && (!challengePassword?.password || challengePassword?.password.length == 0)) {
      setError("Неверный пароль для мероприятия");
      setIsSending(false);
      return;
    }
    API.post("/challenge/api/v1/challenge/" + id + "/join", challengePassword).then((response => {
      if(response.status === 200) {
        navigate("/challenge/" + id + "/my");
      } else if (response.data.code === "009-034" || response.data.code === "009-035") {
        setError("Достигнут лимит по тарифному плану создателя мероприятия. Пожалуйста, обратитесь в поддержку");
      } else if (response.data.code === "009-008") {
        setError("Доступ запрещен");
      } else if (response.data.code === "009-037") {
        setError("Неверный пароль для мероприятия");
      } else {
        setError("Чтобы участвовать в мероприятии, вам необходимо заполнить профиль");
      }
      setIsSending(false);
    }));
  }

  return (
      <div className="container-right-block">
        <div className="menu-challenge">
          <h2 className="headline-right-block">{challenge.name}</h2>
          <ul className="responses-list">
            <li className="respose-item">
              <div className="block-input">
                <ul className="career-list">
                  <li className="career-item">
                    <p className="city">
                      Организатор
                    </p>
                    <h4 className="career-heading">
                      {challenge.orgName || "Не указан"}
                    </h4>
                  </li>
                </ul>
              </div>

              <div className="block-input">
                <p className="legend-about">
                  Описание
                </p>
                <ul className="career-list">
                  <li className="career-item">
                    <p className="career-description">
                      {challenge.description}
                    </p>
                  </li>
                  <li className="career-item">
                    <p className="city">
                      Награды
                    </p>
                    <h4 className="career-heading">
                      {challenge.fund}
                    </h4>
                  </li>
                </ul>
              </div>

              <div className="block-input">
                <p className="legend-about">
                  Даты проведения
                </p>
                <ul className="career-list">
                  <li className="career-item">
                    <p className="city">
                      Начало мероприятия (UTC +3)
                    </p>
                    <h4 className="career-heading">
                      {formatDate(challenge.startDate)}
                    </h4>
                  </li>
                </ul>
              </div>

              <div className="block-input">
                <ul className="career-list">
                  <li className="career-item">
                    <p className="city">
                      Окончание регистрации (UTC +3)
                    </p>
                    <h4 className="career-heading">
                      {formatDate(challenge.registrationEndDate || new Date().toString())}
                    </h4>
                  </li>
                </ul>
              </div>

              <div className="block-input">
                <ul className="career-list">
                  <li className="career-item">
                    <p className="city">
                      Окончание мероприятия (UTC +3)
                    </p>
                    <h4 className="career-heading">
                      {formatDate(challenge.endDate)}
                    </h4>
                  </li>
                </ul>
              </div>

              <div className="block-input">
                <p className="legend-about">
                  Формат
                </p>
                <ul className="career-list">
                  <li className="career-item">
                    <h4 className="career-heading">
                      {challenge.challengeType == 'INDIVIDUAL' ? ('Индивидуальный') : ("Командный")}
                    </h4>
                  </li>
                  {challenge.challengeType === 'COMMAND' ? (
                      <>
                        <li className="career-item">
                          <p className="city">
                            Минимальное количество участников команды
                          </p>
                          <h4 className="career-heading">
                            {challenge.partyFrom}
                          </h4>
                        </li>
                        <li className="career-item">
                          <p className="city">
                            Максимальное количество участников команды
                          </p>
                          <h4 className="career-heading">
                            {challenge.partyTo}
                          </h4>
                        </li>
                      </>
                  ) : (<></>)}
                </ul>
              </div>

              <a href={challenge.rules} target="_blank" className="rules">
                Правила
              </a>
              <p className="class-description">
                Нажимая на кнопку «Участвовать», вы соглашаетесь с правилами мероприятия, а также с информацией, опубликованной на сайте мероприятия.
              </p>
            </li>

          </ul>

          <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>

          {challenge.password && challenge.password.length > 0 && !isParticipant ?
              <input type="password" value={challengePassword?.password} className="input-challenges" placeholder="Введите пароль, выданный организатором"
                     onChange={(e) => {setChallengePassword({...challengePassword, password: e.target.value});}}/> : <></>
          }

          <li className="btn-wrapper" style={{display: (new Date(challenge.registrationEndDate || "") > new Date() && new Date(challenge.startDate || "") < new Date()) || isParticipant ? "" : "none"}}>
            {
              isParticipant ?
                  (<><button className="btn-main-green" style={{cursor: "default"}}>Участвую</button><button className="btn-landing" onClick={() => navigate("/challenge/" + challengeId + "/my")}>К мероприятию</button></>) :
                  (<><button className="btn-main" onClick={() => handleParticipate(challengeId)} disabled={isSending}>{isSending ? "Подождите..." : "Участвовать"}</button><button onClick={()=> window.open(challenge.site, "_blank")} className="btn-landing" style={{display: challenge.site ? "" : "none"}}>Сайт</button></>)
            }
          </li>
          <li className="btn-wrapper" style={{display: new Date(challenge.startDate || "") < new Date() ? "none" : ""}}>
            <button className="btn-main" disabled={true} style={{cursor: "default"}}>Регистрация с {formatDate(challenge.startDate)}</button>
            <button onClick={()=> window.open(challenge.site, "_blank")} className="btn-landing" style={{display: challenge.site ? "" : "none"}}>Сайт</button>
          </li>
          {!isParticipant ?
              <li className="btn-wrapper" style={{display: new Date(challenge.registrationEndDate || new Date()) > new Date() ? "none" : ""}}>
                <button className="btn-main" disabled={true} style={{cursor: "default"}}>Регистрация окончена</button>
                <button onClick={()=> window.open(challenge.site, "_blank")} className="btn-landing" style={{display: challenge.site ? "" : "none"}}>Сайт</button>
              </li>
              :
              <></>
          }
        </div>
      </div>
  )
}