import React from "react";

export const Spinner: React.FC<{text: string, enabled: boolean}> = ({text, enabled}) => {

    if (enabled) {
        return (
            <div className="load-block">
                <p className="load-sheet">
                    {text}
                </p>
                <div className="circle-block">
                    <div className="circle-load one-load"></div>
                    <div className="circle-load two-load"></div>
                    <div className="circle-load three-load"></div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}