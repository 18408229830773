import React from "react";
import SessionModel from "../../../stores/SessionModel";
import {UnloadsAdmin} from "../admin/UnloadsAdmin";
import {UnloadsUniversityOld} from "../university/UnloadsUniversityOld";

export const Unloads = () => {
    let panel;

    if(SessionModel.getCurrentUserAuthData().type === "EDU") {
        panel = <UnloadsUniversityOld/>
    } else if(SessionModel.getCurrentUserAuthData().type === "ADMIN" || SessionModel.isModerator()) {
        panel = <UnloadsAdmin/>
    }
    return (
        <div>
            {panel}
        </div>
    )
}