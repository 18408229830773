import React from "react";
import {IAnswer} from "../../../types/IUserQuestionDto";

export const Answer: React.FC<{
    answer: IAnswer,
    answerKey: number,
    type: string,
    pick: (id: string) => void}> = ({answer, answerKey, type, pick}) => {

    if (type === "SINGLE") {
        return (
            <label key={answerKey} htmlFor={`radio-test-` + answerKey} className={answer.checked ? "answer-button-active" : "answer-button"}>
                <input key={answerKey} type="radio" id={`radio-test-` + answerKey} className="answer-button-margin" checked={answer.checked} onChange={() => pick(answer.id || "")}/>
                {" " + answer.name}
            </label>
        )
    } else {
        return (
            <label key={answerKey} htmlFor={`check-test-` + answerKey} className={answer.checked ? "answer-button-active" : "answer-button"}>
                <input key={answerKey} type="checkbox" id={`check-test-` + answerKey} className="answer-button-margin" checked={answer.checked} onChange={() => pick(answer.id || "")}/>
                {" " + answer.name}
            </label>
        )
    }
}
