import {IChallenge} from "../types/IChallenge";
import {formatDate} from "./DateHelper";
import {IChallengeUniversityAssumption} from "../types/IChallengeUniversityAssumption";
import {IChallengeStageGradeCriteria} from "../types/IChallengeStageGradeCriteria";
import {max} from "lodash";
import {IChallengeStageCTFTask} from "../types/IChallengeStageCTFTask";

export function getChallengeStatus(challenge: IChallenge | IChallengeUniversityAssumption) {

    if (new Date(challenge.registrationEndDate || "") > new Date() && new Date(challenge.startDate || "") < new Date()) {
        return "Идёт регистрация";
    }
    if (new Date(challenge.startDate || "") > new Date()) {
        return `Регистрация с ${formatDate(challenge.startDate)}`;
    }
    if (new Date() > new Date(challenge.endDate || "")) {
        return "Завершено";
    }

    return "Регистрация закончена";
}

export function getPercentResult(criteriaList: IChallengeStageGradeCriteria[] | IChallengeStageCTFTask[] | undefined, points: number, maxPts: number | undefined) {
    if (criteriaList && criteriaList.length > 0) {
        let maxPoints = getMaxPoints(criteriaList);
        return Number((100 / maxPoints * points).toFixed(1));
    } else if (maxPts) {
        if (maxPts === 0)
            return 0;
        return Number((100 / maxPts * points).toFixed(1));
    } else {

    }
}

export function getMaxPoints(criteriaList: IChallengeStageGradeCriteria[] | IChallengeStageCTFTask[] | undefined) {
    let maxPoints = 0;
    if (criteriaList) {
        criteriaList.map((criteria) => {
            maxPoints += criteria.points || 0;
        });
    }
    return maxPoints;
}