import React from "react";
import {MainPageHelp} from "./profile/common/MainPageHelp";

export const SignupFirstStep = () => {
    return (
        <div className="reg-background">
            <MainPageHelp/>
            <img src="/images/logo_white.svg" alt="" className="logo-reg"/>
            <video autoPlay muted loop playsInline src={require("../../public/images/background.mp4").default} className="video-reg"></video>
            <div className="container-registration">
                <div className="registration">
                    <ul className="number-registration">
                        <li className="number-registration-item-active">
                            <p className="number-reg-active">1</p>
                        </li>
                        <li className="number-registration-line"></li>
                        <li className="number-registration-item">
                            <p className="number-reg">2</p>
                        </li>
                        <li className="number-registration-line"></li>
                        <li className="number-registration-item">
                            <p className="number-reg">3</p>
                        </li>
                    </ul>
                    <h2 className="reg-heading">Регистрация. Выберите роль</h2>
                    <a href="/signup?step=2" className="btn role-btn">Участник</a>
                    {/*<a href="" className="btn role-btn">Представитель учебного заведения</a>
                    <a href="" className="btn role-btn">Юридическое лицо</a>
                    <a href="/edu/signup" className="code-error">Образовательная организация</a>
                    <a href="/bsn/signup" className="code-error">Компания/НКО</a>*/}
                    <a href="/signin" className="code-error">Я уже зарегистрирован</a>
                </div>
            </div>
        </div>
    )
}