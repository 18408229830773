import React, {useEffect, useState} from "react";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {useParams} from "react-router-dom";
import {ModalMessage} from "../../../common/ModalMessage";
import {sum, toNumber} from "lodash";
import {IChallenge} from "../../../../types/IChallenge";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {IChallengeStageGradeCriteria} from "../../../../types/IChallengeStageGradeCriteria";
import {ITestResult} from "../../../../types/ITestResult";
import {getPercentResult} from "../../../../helpers/ChallengeHelper";
import {differenceHMS, differenceInMin} from "../../../../helpers/DateHelper";
import {IChallengeStageCTFTask} from "../../../../types/IChallengeStageCTFTask";
import {trueRound} from "../../../../helpers/Util";

export const MyResults:React.FC<{stageId: number | undefined}> = ({stageId}) => {

    const params = useParams();
    const [error, setError] = useState("");
    const [challenge, setChallenge] = useState<IChallenge>();
    const [stage, setStage] = useState<IChallengeStage | undefined>(undefined);
    const [testResult, setTestResult] = useState<ITestResult>();
    const [totalPoints, setTotalPoints] = useState(0);

    useEffect(() => {
        if (stageId) {
            ChallengeAPI.getMyChallenge(toNumber(params.id), (response) => {
                if (response.status === 200) {
                    setChallenge(response.data);
                } else {
                    setError("Ошибка получения результатов");
                }
            });
        }
    }, [stageId]);

    useEffect(() => {
        setStage(challenge?.stages?.filter(s => s.id === stageId)[0]);
    }, [challenge]);

    useEffect(() => {
        if (stage && stage.type !== "TEST") {
            let sum = 0;
            /*if (stage.type === "CTF") {
                stage.ctfTasks.forEach(t => { sum += t.points || 0});
            } else {*/
                stage.criteriaList.forEach(c => sum += c.userPoints || 0);
            //}
            setTotalPoints(sum || 0);
        } else if (stage && stage.type === "TEST") {
            console.log("HERE1");
            ChallengeAPI.getTestResult(toNumber(params.id), toNumber(stageId), (response) => {
                if (response.status === 200) {
                    setTestResult(response.data);
                    setTotalPoints(response.data.maxPoints);
                } else {
                    setError("Ошибка получения результатов");
                }
            })
        }
    }, [stage]);

    const getEstimatedTime = () => {
        if (testResult?.startedAt && testResult.finishedAt) {
            return differenceInMin(testResult.startedAt, testResult.finishedAt);
        } else {
            return "Ошибка получения времени";
        }
    }

    if (stage?.type === "TEST") {
        if (!testResult?.maxPoints) {
            return (
                <div className="container-right-block">
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="menu-challenge">
                        <div className="myresults-total">
                            <p className="myresults-total-heading">
                                Вы не проходили тестирование
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="container-right-block">
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="menu-challenge">
                        <div className="myresults-total">
                            <p className="myresults-total-heading">
                                Итого баллов
                            </p>
                            <p className="myresults-total-heading-num">
                                {trueRound(testResult?.points)} из {trueRound(totalPoints)}
                            </p>
                        </div>
                    </div>
                    <div className="menu-challenge">
                        <div className="myresults-stage">
                            <p className="myresults-stage-heading">
                                {stage.name}
                            </p>
                            <div className="my-results-stage-wrapper">
                                <p className="input-challenges">
                                    Процент верных ответов
                                </p>
                                <p className="input-challenges content-new">
                                    {
                                        testResult?.points && testResult.maxPoints && testResult.maxPoints > 0 ?
                                            getPercentResult(undefined, testResult?.points || 0, testResult?.maxPoints)
                                            :
                                            0
                                    }
                                </p>
                            </div>
                            <div className="my-results-stage-wrapper">
                                <p className="input-challenges">
                                    Затраченное время (минуты)
                                </p>
                                <p className="input-challenges content-new">
                                    {getEstimatedTime()}
                                </p>
                            </div>
                            <div className="my-results-stage-wrapper">
                                <p className="input-challenges">
                                    Получено баллов
                                </p>
                                <p className="input-challenges content-new">
                                    {testResult?.points}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    } else if (stage?.type === "CTF") {
        return (
            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <div className="myresults-total">
                        <p className="myresults-total-heading">
                            Итого баллов
                        </p>
                        <p className="myresults-total-heading-num">
                            {trueRound(stage.points || 0)}
                        </p>
                    </div>
                </div>
                {stage?.comment ?
                    <div className="menu-challenge">
                        <div className="myresults-stage">
                            <p className="myresults-stage-heading">
                                Комментарий
                            </p>
                            <div className="my-results-stage-wrapper">
                                <p className="input-challenges">
                                    {stage.comment}
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        )
    } else {
        return (
            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <div className="myresults-total">
                        <p className="myresults-total-heading">
                            Итого баллов
                        </p>
                        <p className="myresults-total-heading-num">
                            {trueRound(totalPoints)}
                        </p>
                    </div>
                </div>
                <div className="menu-challenge">
                    <div className="myresults-stage">
                        <p className="myresults-stage-heading">
                            {stage?.name}
                        </p>
                        {stage?.criteriaList.map((criteria: IChallengeStageGradeCriteria, index) =>
                            <div className="my-results-stage-wrapper" key={index}>
                                <p className="input-challenges">
                                    {criteria.name}
                                </p>
                                <p className="input-challenges content-new">
                                    {trueRound(criteria.userPoints || 0)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                {stage?.comment ?
                    <div className="menu-challenge">
                        <div className="myresults-stage">
                            <p className="myresults-stage-heading">
                                Комментарии
                            </p>
                            {stage.comment.split("<%--------%>").map(c =>
                                <div className="my-results-stage-wrapper">
                                    <p className="input-challenges">
                                        {c}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }
}