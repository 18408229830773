import React, {useEffect, useState} from "react";
import {IProfileBusinessForm} from "../../types/IProfileBusinessForm";
import API from "../../../requests/_axios";
import {isAdmin} from "../../../requests/user/user";
import {ModalMessage} from "../common/ModalMessage";
import * as WalletAPI from "../../../requests/WalletAPI";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import * as CertificateAPI from "../../../requests/CertificateAPI";
import * as TestAPI from "../../../requests/TestAPI";
import {IUserPlan} from "../../types/IUserPlan";
import {IPlan} from "../../types/IPlan";

interface IPreferences {
    userId?: number;
    officialLetterDate?: string;
}

export const AdminBusinessProfileSettings: React.FC<{profile: IProfileBusinessForm | undefined}> = ({profile}) => {


    const [date, setDate] = useState("");
    const [message, setMessage] = useState("");
    const [preferences, setPreferences] = useState<IPreferences>();
    const [walletAmount, setWalletAmount] = useState(undefined);
    const [plans, setPlans] = useState([]);
    const [currentPlan, setCurrentPlan] = useState<string|undefined>();
    const [currentPlanDate, setCurrentPlanDate] = useState<string|undefined>();
    const [hasCertificatePlan, setHasCertificatePlan] = useState(false);
    const [hasTestPlan, setHasTestPlan] = useState(false);
    const [actualProfile, setActualProfile] = useState<IProfileBusinessForm>();

    useEffect(() => {
        initForm();
    }, [profile]);

    useEffect(() => {
        setPreferences({
            userId: profile?.userId || 0,
            officialLetterDate: profile?.officialLetterDate || "",
        });
        if(actualProfile?.officialLetterDate) {
            setDate(new Date(actualProfile?.officialLetterDate).toISOString().substring(0,10));
        }
        WalletAPI.getBalanceById(actualProfile?.userId || 0, (response) => {
            if (response.status === 200) {
                setWalletAmount(response.data);
            }
        });
        ChallengeAPI.getPlans((response) => {
            if (response.status === 200) {
                setPlans(response.data);
            } else {
                setMessage("Ошибка при получении тарифных планов");
            }
        })
        setCurrentPlan(actualProfile && actualProfile.userPlan && actualProfile.userPlan[0] ? actualProfile?.userPlan[0].planId : undefined);
        setCurrentPlanDate(actualProfile && actualProfile.userPlan && actualProfile.userPlan[0] ? actualProfile?.userPlan[0].paidUntil.substring(0, 10) : undefined);
        CertificateAPI.getByUserId(actualProfile?.userId || 0, (response) => {
            if (response.status === 200) {
                setHasCertificatePlan(response.data.isActive)
            } else {
                setMessage("Ошибка при получении данных из Certificate");
            }
        });
        TestAPI.getActivePlanByUserId(actualProfile?.userId || 0, (response) => {
            if (response.status === 200) {
                setHasTestPlan(response.data.isActive)
            } else {
                setMessage("Ошибка при получении данных из Test");
            }
        })
    }, [actualProfile]);

    const initForm = () => {
        if (profile?.userId) {
            ChallengeAPI.getProfileById("business", profile.userId, (response) => {
                if (response.status === 200) {
                    setActualProfile(response.data);
                } else {
                    setMessage("Ошибка получения профиля");
                }
            })
        }
    }


    const handleChangeDate = function (e: React.ChangeEvent<HTMLInputElement>) {
        setPreferences({...preferences, officialLetterDate: e.target.value});
        setDate(e.target.value);
    }

    const handleSubmit = () => {
        API.patch(`/challenge/api/v1/business/${preferences?.userId}/preferences`, preferences).then((response) => {
            if (response.status === 200) {
                setMessage("Сохранено");
            } else {
                setMessage("Произошла ошибка");
            }
        });
    }

    const addCertificateService = () => {
        CertificateAPI.addUserPlan(profile?.userId || 0, (response) => {
            if (response.status === 200) {
                initForm();
                setMessage("Сохранено");
            } else {
                setMessage("Ошибка подключения сервиса сертификатов");
            }
        })
    }

    const addTestService = () => {
        TestAPI.addUserPlan(profile?.userId || 0, (response) => {
            if (response.status === 200) {
                initForm();
                setMessage("Сохранено");
            } else {
                setMessage("Ошибка подключения сервиса тестов");
            }
        })
    }

    const switchChallengePlan = () => {
        const userPlan: IUserPlan = {
            userId: profile?.userId,
            planId: currentPlan,
            paidUntil: currentPlanDate
        }
        ChallengeAPI.addPlanToUser(userPlan, (response) => {
            if (response.status === 200) {
                initForm();
                setMessage("Сохранено");
            } else {
                setMessage("Ошибка подключения тарифного плана");
            }
        })
    }


    if (isAdmin()) {
        return (
            <>
                <div className="menu-challenge">
                    <ModalMessage msg={message} header={"Успех"} _callback={() => setMessage("")}/>
                    <fieldset className="block-input-flex">
                        <legend className="legend">Дата письма</legend>
                        <input type="date" value={date} onChange={handleChangeDate} className="input-challenges" min="2022-01-01" max="3000-01-01"/>
                    </fieldset>
                    <button className="btn-main" onClick={handleSubmit}>Сохранить</button>
                </div>
                {profile?.userPlan && profile.userPlan.length > 0 && profile.userPlan[0].plan ?
                    <div className="menu-challenge">
                        <div className="stage-button balance-green">
                            <h2 className="headline-tarif">
                                Тариф «{profile.userPlan[0].plan.name}» (Баланс: {walletAmount ? walletAmount / 100 : "Кошелек не найден"})
                            </h2>
                            {/*<p className="pay-service-info">
                                Не хватает средств
                            </p>*/}
                            <div className="btn-info balance-green">
                                <h4 className="btn-info-heading-green">
                                    Активен
                                </h4>
                                <ul className="tarif-about">
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Количество участников всего*
                                        </p>
                                        <p className="tarif-about-num">{profile.userPlan[0].plan.persons === -1 ? "Безлимит" : profile.userPlan[0].plan.persons}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Количество инициатив одновременно
                                        </p>
                                        <p className="tarif-about-num">{profile.userPlan[0].plan.challenges === -1 ? "Безлимит" : profile.userPlan[0].plan.challenges}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Обязательная принадлежность к организации
                                        </p>
                                        <p className="tarif-about-num">{profile.userPlan[0].plan.employeesOnly ? "Да" : "Нет"}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Период действия (дней)
                                        </p>
                                        <p className="tarif-about-num">{profile.userPlan[0].plan.period}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    : <></>}
                <div className="menu-challenge">
                    <div className="stage-button balance-green">
                        <fieldset className="block-input">
                            <legend className="legend">Тариф</legend>
                            <select value={currentPlan} className="input-challenges-select" onChange={(e) => setCurrentPlan(e.target.value)}>
                                <option value="">Выберите из списка</option>
                                {plans?.map((plan: IPlan) =>
                                    <option value={plan.id}>{plan.name}</option>
                                )}
                            </select>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Срок</legend>
                            <input type="date" className="input-challenges" placeholder="Начните вводить" min="1700-01-01" max="2100-01-01" value={currentPlanDate} onChange={(e) => setCurrentPlanDate(e.target.value)}/>
                        </fieldset>
                        <button className="btn-main" onClick={switchChallengePlan}>Подключить</button>
                    </div>
                    <div className="stage-button balance-green">
                        {hasCertificatePlan ? "Сервис сертификатов подключен" : <button className="btn-main" onClick={addCertificateService}>Подключить сертификаты</button>}
                    </div>
                    <div className="stage-button balance-green">
                        {hasTestPlan ? "Сервис тестов подключен" : <button className="btn-main" onClick={addTestService}>Подключить тесты</button>}
                    </div>
                </div>
            </>
        )
    } else {
        return (<></>)
    }

}