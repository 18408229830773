import React from "react";
import {IChallengeStageGradeCriteria} from "../../../../types/IChallengeStageGradeCriteria";
import {IJuryAssessmentGrade} from "../../../../types/assessment/IJuryAssessmentGrade";

export const GradeDetailsView:React.FC<{
  allowComments: boolean,
  nameOrProject: string,
  gradeList: IJuryAssessmentGrade[],
  comment: string,
  getCriteriaById: (id: number) => IChallengeStageGradeCriteria}> = (
      {allowComments, nameOrProject, gradeList, comment, getCriteriaById}) => {
  return (
      <>
      <li className="respose-item">
        <div className="username">
            <p className="fio-or-name">{nameOrProject}</p>
        </div>
        {
          gradeList.map((ag, index) =>
              <div className="challenge-card">
                <div className="challenge-card-mini">
                  <img src="/images/done_rating.svg" alt="" className="challenge-avatar"/>
                  <div className="user-info">
                    <p className="rating-num">{ag.points}</p>
                    <p className="rating-criterion">{getCriteriaById(ag.criteriaId).name}</p>
                  </div>
                </div>
              </div>
          )
        }
      </li>
        {allowComments ?
            <div className="block-input">
              <ul className="career-list">
                <li className="career-item">
                  <h4 className="career-heading">
                    Комментарий
                  </h4>
                  <p className="career-description">
                    {comment}
                  </p>
                </li>
              </ul>
            </div>
            :
            <></>
        }
    </>
  )
}