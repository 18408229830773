import React from "react";
import {IChallengeStage} from "../../../types/IChallengeStage";
import {formatDate} from "../../../helpers/DateHelper";
import plural from "plural-ru";
import {toNumber} from "lodash";

export const StageButtonJury: React.FC<{
    stage: IChallengeStage,
    index: number}> = ({stage, index}) => {

    const getGradedPercent = () : number => {
        if (toNumber(stage.totalSolutions) === 0) return 0;
        return Number((100 / toNumber(stage.totalSolutions) * toNumber(stage.assessedSolutions)).toFixed(1));
    }

    return (
        <div className="stage-card-new" style={stage.isAssessmentSubmitted ? {backgroundColor: "#00ff9d1c"} : {}}>
            <div className="stage-card-new-info" onClick={() => toNumber(stage.totalSolutions) === 0 ? void(0) : window.location.href=`?stageId=${stage.id}`}>
                <div className="stage-card-new-wrapper">
                    <p className="status-stage">
                        {stage.totalSolutions === stage.assessedSolutions && stage.isAssessmentSubmitted ? "Оценен" : "Готов к оценке"}
                    </p>
                    <p className="status-stage-result">

                    </p>
                </div>
                <div className="graph">
                    <div className="graph-line">
                        <div className="graph-line-container">
                            <div className="graph-line-line" style={{width: getGradedPercent() + "%"}}/>
                        </div>
                        <p className="graph-line-projects-jury">
                            {stage.totalSolutions === stage.assessedSolutions && toNumber(stage.totalSolutions) > 0 ?
                            "все проекты"
                                :
                            plural((stage.assessedSolutions || 0), "%d проект", "%d проекта", "%d проектов")}
                        </p>
                    </div>
                    <p className="stage-card-new-heading">
                        {stage.name}
                    </p>
                    <div className="stage-card-new-gap">
                        {stage.totalSolutions === stage.assessedSolutions && !stage.isAssessmentSubmitted && toNumber(stage.totalSolutions) > 0 ?
                            <p className="stage-card-new-date">
                                Отправьте оценки организаторам
                            </p>
                            :
                            toNumber(stage.totalSolutions) === 0 || ((stage.totalSolutions || 0) - (stage.assessedSolutions || 0) > 0) ?
                                <>
                                    <p className="stage-card-new-date">
                                        {(stage.totalSolutions || 0) - (stage.assessedSolutions || 0)}
                                    </p>
                                    <p className="stage-card-new-date">
                                        ожидают оценки
                                    </p>
                                </>
                                :
                                <p className="stage-card-new-date">
                                    Оценки отправлены
                                </p>
                        }
                    </div>
                </div>
                <p className="stage-card-new-text">
                    {stage.description}
                </p>
            </div>
            {!stage.isAssessmentSubmitted ?
                <div className="stage-card-new-wrapper-link">
                    { toNumber(stage.totalSolutions) === 0 ?
                        <></>
                        :
                        <a onClick={() => window.location.href=`?stageId=${stage.id}`} className="card-standart-link">
                            Оценить этап
                        </a>
                    }
                </div>
                :
                <></>
            }

        </div>
    )
}