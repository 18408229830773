import React, {useEffect, useState} from "react";
import * as TestAPI from '../../../../requests/TestAPI';
import * as ChallengeAPI from '../../../../requests/ChallengeAPI';
import {IUserQuestionDto} from "../../../types/IUserQuestionDto";
import {ModalMessage} from "../ModalMessage";
import {IHms, toHoursAndMinutesFromSec} from "../../../helpers/DateHelper";
import {TestResult} from "./TestResult";
import {ITestResult} from "../../../types/ITestResult";
import {TestAdminResult} from "./TestAdminResult";
import {AnswerList} from "./AnswerList";

export const Testing: React.FC<{challengeId: number | null, stageId: number | null, testingId: string}> = ({challengeId, stageId, testingId}) => {

    const [question, setQuestion] = useState<IUserQuestionDto>();
    const [currentTime, setCurrentTime] = useState(-1);
    const [startDate, setStartDate] = useState();
    const [duration, setDuration] = useState();
    const [message, setMessage] = useState("");
    //const [showFinish, setShowFinish] = useState(false);
    const [testResult, setTestResult] = useState<ITestResult>();
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [timeout, setTimeout] = useState(false);

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        TestAPI.getCurrentQuestion(testingId, (response) => {
            if (response.status === 200) {
                setQuestion(response.data);
                setStartDate(response.data.startedAt);
                setDuration(challengeId ? response.data.duration : 100500);
                //setCurrentTime(challengeId ? response.data.time : 100500)
                if (response.data.isFinished) {
                    fetchTestResult();
                }
                //setShowFinish(response.data.isFinished)
            }
            else {
                setMessage("Ошибка при получении вопроса");
            }
        });
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (startDate && duration) {
                const diff = new Date().getTime() - (new Date(startDate).getTime());
                const testingTimeInSec = duration;
                if (diff / 1000 >= testingTimeInSec) {
                    setCurrentTime(0);
                    clearInterval(myInterval);
                    setTimeout(true);
                } else if (challengeId) {
                    setCurrentTime(testingTimeInSec - diff / 1000);
                }
            }
            /*if (currentTime !== -1) {
                if (currentTime > 0 && challengeId) {
                    setCurrentTime(currentTime - 1);
                }
                if (currentTime === 0) {
                    clearInterval(myInterval);
                    setTimeout(true);
                }
            }*/
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    const pickAnswer = (id: string) => {
        if (question && question.isFinished && challengeId) return;
        const currentQuestion = question?.question;
        if (currentQuestion) {
            const updatedAnswers = question?.question.answers.map(answer => {
                if (answer.id === id) {
                    return {...answer, checked: !answer.checked}
                } else if (question?.question.type === "SINGLE") {
                    return {...answer, checked: false}
                } else {
                    return answer;
                }
            });
            currentQuestion.answers = updatedAnswers;
            setQuestion({...question, question: currentQuestion});
        }
    }

    const fillAnswer = (value: string) => {
        if (question && question.isFinished && challengeId) return;
        const currentQuestion = question?.question;
        if (currentQuestion) {
            const updatedAnswers = question?.question.answers.map(answer => {
                return {...answer, value: value}
            });
            currentQuestion.answers = updatedAnswers;
            setQuestion({...question, question: currentQuestion});
        }
    }

    const submitAnswers = () => {
        if (!anyChecked() && !timeout) {
            setMessage("Выберите один или несколько вариантов, или нажмите \"Пропустить\"");
            return;
        }
        setSubmitDisabled(true);
        if (question) {
            if (challengeId && question.isLast && !question.isFinished) {
                TestAPI.submitAnswers(question, (response) => {
                    if (response.status === 200) {
                        finishTest();
                    } else {
                        setMessage("Ошибка при отправке ответов");
                    }
                    setSubmitDisabled(false);
                })
            } else {
                TestAPI.submitAnswers(question, (response) => {
                    if (response.status === 200) {
                        setQuestion(response.data);
                    } else {
                        setMessage("Ошибка при отправке ответов");
                    }
                    setSubmitDisabled(false);
                })
            }
        }
    }

    const finishTest = () => {
        TestAPI.finalize(testingId, (response) => {
            if (response.status === 200) {
                fetchTestResult();
                //setShowFinish(true);
            } else {
                setMessage("Ошибка при завершении теста");
            }
        })
    }

    const fetchTestResult = () => {
        if (challengeId && stageId) {
            ChallengeAPI.getTestResult(challengeId, stageId, (responseResult) => {
                if (responseResult.data.startedAt) {
                    setTestResult(responseResult.data);
                }
            })
        }
    }

    const anyChecked = () : boolean => {
        let anyChecked = false;
        const updatedAnswers = question?.question.answers.map(answer => {
            if (question?.question.type === "INPUT") {
                if (answer.value && answer.value.length > 0) anyChecked = true;
            } else {
                if (answer.checked) anyChecked = true;
            }
        });
        return anyChecked;
    }

    const getNext = () => {
        if (question) {
            TestAPI.getNextQuestion(question, (response) => {
                if (response.status === 200) {
                    setQuestion(response.data);
                } else {
                    setMessage("Ошибка при получении вопроса");
                }
            });
        }
    }

    const getEstimatedTime = () : IHms => {
        return toHoursAndMinutesFromSec(currentTime);
    }

    const doReview = () => {
        TestAPI.getCurrentQuestion(testingId, (response) => {
            if (response.status === 200) {
                setQuestion(response.data);
                setCurrentTime(0)
                //setShowFinish(false);
            }
            else {
                setMessage("Ошибка при получении вопроса");
            }
        });
    }

    const showAdminResult = () => {
        TestAPI.getIncorrectQuestions(testingId, (response) => {
           if (response.status === 200) {
               setTestResult(response.data);
           } else {
               setMessage("Ошибка при получении результатов");
           }
        });
    }

    const doReset = () => {
        setTestResult(undefined);
        TestAPI.resetTesting(testingId, (response) => {
            if (response.status === 200) {
                init();
            } else {
                setMessage("Ошибка при сбросе результатов");
            }
        })
    }


    if (challengeId == null && testResult) {
        return <TestAdminResult result={testResult} reset={doReset}/>
    } else if (testResult) {
        return <TestResult result={testResult}/>
    } else if (question) {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                    <ModalMessage msg={message} header={"Ошибка"} _callback={() => setMessage("")}/>
                    <div className="row-wrapper">
                        {question ? (
                            <p className="number-question">
                                <span className="number-question-text">{question?.current}</span>
                                /
                                <span className="number-question-text">{question?.total}</span>
                            </p>
                        ) : (<p className="number-question"></p>)}
                        {challengeId ?
                            <p className="number-question">
                                {
                                    question && question.isFinished ?
                                        ("Тест завершен") :
                                        timeout ? ("Время на тестирование закончилось") : ((getEstimatedTime().h || "00") + ":" + (getEstimatedTime().m || "00") + ":" + (getEstimatedTime().s || "00"))

                                }
                            </p>
                            :
                            <></>}
                    </div>
                    {question.question.image ? (
                        <a href={question.question.image} className="container-img-test" target="_blank" rel="noopener noreferrer">
                            <div className="loopa"></div>
                            <img src={question.question.image} alt="" className="img-test-question-background"/>
                            <img src={question.question.image} alt="" className="img-test-question"/>
                        </a>
                    ) : ""}
                    <h2 className="headline-right-block">
                        {question?.question.name}
                    </h2>
                    <p className="weight-question">
                        Баллов за правильный ответ:<span className="weight-question-num"> {question?.question.points}</span>
                    </p>
                    <fieldset className="block-input">
                        <legend className="legend">
                            {question?.question.type === "SINGLE" ? "Выберите один из вариантов" :
                                question?.question.type === "INPUT" ? "Введите ответ" : "Выберите несколько вариантов"}
                        </legend>
                        {
                            question?.question.answers?.map((qa, key) =>
                                <AnswerList answer={qa} answerKey={key} type={question?.question.type} pick={pickAnswer} fill={fillAnswer}/>
                                /*<Answer answerKey={key} answer={qa} type={question?.question.type} pick={pickAnswer}/>*/
                            )
                        }
                    </fieldset>
                    {challengeId ?
                        (<div className="row-wrapper">
                            {timeout ?
                                <input className="btn-blue" type="button" onClick={finishTest} disabled={submitDisabled} value="Посмотреть результат"/> :
                                (
                                    <>
                                        <input className="btn-grey" type="button" onClick={getNext} value="Пропустить"/>
                                        <input className="btn-blue" type="button" onClick={submitAnswers} disabled={submitDisabled} value={submitDisabled ? "Подождите..." : question?.isLast ? (question.isFinished ? "В начало" : "Завершить") : "Далее"}/>
                                    </>
                                )
                            }
                        </div>)
                        :
                        (<div className="row-wrapper">
                                <input className="btn-blue" type="button" onClick={doReset} disabled={submitDisabled} value="Пройти заново"/>
                                <input className="btn-blue" type="button" onClick={showAdminResult} disabled={submitDisabled} value="Посмотреть результат"/>
                                <input className="btn-grey" type="button" onClick={getNext} value="Пропустить"/>
                                <input className="btn-blue" type="button" onClick={submitAnswers} disabled={submitDisabled} value={submitDisabled ? "Подождите..." : "Далее"}/>
                        </div>)
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                </div>
            </div>
        )
    }
}