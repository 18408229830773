import React, {useEffect, useState} from "react";
import {IProfileUniversityForm} from "../../types/IProfileUniversityForm";
import {isAdmin} from "../../../requests/user/user";
import API from "../../../requests/_axios";
import {ModalMessage} from "../common/ModalMessage";
import * as WalletAPI from "../../../requests/WalletAPI";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import * as CertificateAPI from "../../../requests/CertificateAPI";
import * as TestAPI from "../../../requests/TestAPI";
import {IPlan} from "../../types/IPlan";
import {IUserPlan} from "../../types/IUserPlan";
import SessionModel from "../../../stores/SessionModel";

interface IStudentsVisibility {
    userId: number;
    officialLetterDate: string;
    canViewStudents: boolean;
}

export const AdminUniversityProfileSettings: React.FC<{profile: IProfileUniversityForm | undefined}> = ({profile}) => {

    const [date, setDate] = useState("");
    const [message, setMessage] = useState("");
    const [studentsVisibility, setStudentsVisibility] = useState<IStudentsVisibility>({
        userId: profile?.userId || 0,
        officialLetterDate: profile?.officialLetterDate || "",
        canViewStudents: profile?.canViewStudents || true
    });
    const [walletAmount, setWalletAmount] = useState(undefined);
    const [plans, setPlans] = useState([]);
    const [currentPlan, setCurrentPlan] = useState<string|undefined>();
    const [currentPlanDate, setCurrentPlanDate] = useState<string|undefined>();
    const [hasCertificatePlan, setHasCertificatePlan] = useState(false);
    const [hasTestPlan, setHasTestPlan] = useState(false);
    const [actualProfile, setActualProfile] = useState<IProfileUniversityForm>();

    useEffect(() => {
        initForm();
    }, [profile]);

    useEffect(() => {
        setStudentsVisibility({
            userId: profile?.userId || 0,
            officialLetterDate: actualProfile?.officialLetterDate || "",
            canViewStudents: actualProfile?.canViewStudents === undefined ? true : actualProfile.canViewStudents
        });
        if(actualProfile?.officialLetterDate) {
            setDate(actualProfile?.officialLetterDate.substring(0,10));
        }
        if (SessionModel.isAdmin()) {
            WalletAPI.getBalanceById(actualProfile?.userId || 0, (response) => {
                if (response.status === 200) {
                    setWalletAmount(response.data);
                }
            });
            ChallengeAPI.getPlans((response) => {
                if (response.status === 200) {
                    setPlans(response.data);
                } else {
                    setMessage("Ошибка при получении тарифных планов");
                }
            })
            setCurrentPlan(actualProfile && actualProfile.userPlan && actualProfile.userPlan[0] ? actualProfile?.userPlan[0].planId : undefined);
            setCurrentPlanDate(actualProfile && actualProfile.userPlan && actualProfile.userPlan[0] ? actualProfile?.userPlan[0].paidUntil.substring(0, 10) : undefined);
            CertificateAPI.getByUserId(actualProfile?.userId || 0, (response) => {
                if (response.status === 200) {
                    setHasCertificatePlan(response.data.isActive)
                } else {
                    setMessage("Ошибка при получении данных из Certificate");
                }
            });
            TestAPI.getActivePlanByUserId(actualProfile?.userId || 0, (response) => {
                if (response.status === 200) {
                    setHasTestPlan(response.data.isActive)
                } else {
                    setMessage("Ошибка при получении данных из Test");
                }
            });
        }
    }, [actualProfile]);

    const initForm = () => {
        if (profile?.userId) {
            ChallengeAPI.getProfileById("university", profile.userId, (response) => {
                if (response.status === 200) {
                    setActualProfile(response.data);
                } else {
                    setMessage("Ошибка получения профиля");
                }
            })
        }
    }

    const handleSubmit = () => {
        API.patch(`/challenge/api/v1/university/${studentsVisibility.userId}/students-visibility`, studentsVisibility).then((response) => {
            if (response.status === 200) {
                setMessage("Сохранено");
            } else {
                setMessage("Произошла ошибка");
            }
        });
    }

    const handleChangeDate = function (e: React.ChangeEvent<HTMLInputElement>) {
        setStudentsVisibility({...studentsVisibility, officialLetterDate: e.target.value});
        setDate(e.target.value);
    }

    const addCertificateService = () => {
        CertificateAPI.addUserPlan(profile?.userId || 0, (response) => {
            if (response.status === 200) {
                initForm();
                setMessage("Сохранено");
            } else {
                setMessage("Ошибка подключения сервиса сертификатов");
            }
        })
    }

    const addTestService = () => {
        TestAPI.addUserPlan(profile?.userId || 0, (response) => {
            if (response.status === 200) {
                initForm();
                setMessage("Сохранено");
            } else {
                setMessage("Ошибка подключения сервиса тестов");
            }
        })
    }

    const switchChallengePlan = () => {
        const userPlan: IUserPlan = {
            userId: profile?.userId,
            planId: currentPlan,
            paidUntil: currentPlanDate
        }
        ChallengeAPI.addPlanToUser(userPlan, (response) => {
            if (response.status === 200) {
                initForm();
                setMessage("Сохранено");
            } else {
                setMessage("Ошибка подключения тарифного плана");
            }
        })
    }

    if (isAdmin() || SessionModel.isModerator()) {
        return (
            <>
                <div className="menu-challenge">
                    <ModalMessage msg={message} header={"Успех"} _callback={() => setMessage("")}/>
                    <h2 className="headline-right-block">Студенты</h2>
                    <fieldset className="block-input">
                        <legend className="legend">Видимость</legend>
                        <label className="input-challenges-flex">
                            <input type="checkbox" name="status" onChange={(e) => setStudentsVisibility({...studentsVisibility, canViewStudents: !studentsVisibility.canViewStudents})} checked={!studentsVisibility.canViewStudents} className="radio-btn"/>
                            <span className="span-radio">Запретить видеть участников</span>
                        </label>
                    </fieldset>
                    <fieldset className="block-input-flex">
                        <legend className="legend">Дата письма</legend>
                        <input type="date" value={date} onChange={handleChangeDate} className="input-challenges" min="2022-01-01" max="3000-01-01"/>
                    </fieldset>
                    <button className="btn-main" onClick={handleSubmit}>Сохранить</button>
                </div>
                {isAdmin() ?
                <>
                    {profile?.userPlan && profile.userPlan.length > 0 && profile.userPlan[0].plan ?
                        <div className="menu-challenge">
                            <div className="stage-button balance-green">
                                <h2 className="headline-tarif">
                                    Тариф «{profile.userPlan[0].plan.name}» (Баланс: {walletAmount ? walletAmount / 100 : "Кошелек не найден"})
                                </h2>
                                {/*<p className="pay-service-info">
                                Не хватает средств
                            </p>*/}
                                <div className="btn-info balance-green">
                                    <h4 className="btn-info-heading-green">
                                        Активен
                                    </h4>
                                    <ul className="tarif-about">
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Количество участников всего*
                                            </p>
                                            <p className="tarif-about-num">{profile.userPlan[0].plan.persons === -1 ? "Безлимит" : profile.userPlan[0].plan.persons}</p>
                                        </li>
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Количество инициатив одновременно
                                            </p>
                                            <p className="tarif-about-num">{profile.userPlan[0].plan.challenges === -1 ? "Безлимит" : profile.userPlan[0].plan.challenges}</p>
                                        </li>
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Обязательная принадлежность к организации
                                            </p>
                                            <p className="tarif-about-num">{profile.userPlan[0].plan.employeesOnly ? "Да" : "Нет"}</p>
                                        </li>
                                        <li className="tarif-about-item">
                                            <p className="tarif-about-text">
                                                Период действия (дней)
                                            </p>
                                            <p className="tarif-about-num">{profile.userPlan[0].plan.period}</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        : <></>}
                    <div className="menu-challenge">
                        <div className="stage-button balance-green">
                            <fieldset className="block-input">
                                <legend className="legend">Тариф</legend>
                                <select value={currentPlan} className="input-challenges-select" onChange={(e) => setCurrentPlan(e.target.value)}>
                                    <option value="">Выберите из списка</option>
                                    {plans?.map((plan: IPlan) =>
                                        <option value={plan.id}>{plan.name}</option>
                                    )}
                                </select>
                            </fieldset>
                            <fieldset className="block-input">
                                <legend className="legend">Срок</legend>
                                <input type="date" className="input-challenges" placeholder="Начните вводить" min="1700-01-01" max="2100-01-01" value={currentPlanDate} onChange={(e) => setCurrentPlanDate(e.target.value)}/>
                            </fieldset>
                            <button className="btn-main" onClick={switchChallengePlan}>Подключить</button>
                        </div>
                        <div className="stage-button balance-green">
                            {hasCertificatePlan ? "Сервис сертификатов подключен" : <button className="btn-main" onClick={addCertificateService}>Подключить сертификаты</button>}
                        </div>
                        <div className="stage-button balance-green">
                            {hasTestPlan ? "Сервис тестов подключен" : <button className="btn-main" onClick={addTestService}>Подключить тесты</button>}
                        </div>
                    </div>
                </>
                    :
                    <></>
                }
            </>
        )
    } else {
        return (<></>)
    }
}