import API from "./_axios";
import SessionModel from "../stores/SessionModel";
import {IFillBalance} from "../components/types/IFillBalance";

export function getBalance(_callback: (response: any) => void) {
    API.get(`/wallet/api/v1/balance`).then((response) => {
        _callback(response);
    });
}

export function getBalanceById(id: number, _callback: (response: any) => void) {
    API.get(`/wallet/api/v1/balance/${id}`).then((response) => {
        _callback(response);
    });
}

export function fill(request: IFillBalance, _callback: (response: any) => void) {
    API.post(`/wallet/api/v1/operation/fill`, request).then((response) => {
        _callback(response);
    });
}

export function paymentOk(orderId: string, _callback: (response: any) => void) {
    API.get(`/wallet/api/v1/payment/ok?orderId=${orderId}`).then((response) => {
        _callback(response);
    })
}

export function paymentFailed(_callback: (response: any) => void) {
    API.get(`/wallet/api/v1/payment/failed`).then((response) => {
        _callback(response);
    })
}

export function getOperationList(type: string, _callback: (response: any) => void) {
    API.get(`/wallet/api/v1/operation/list?type=${type}`).then((response) => {
        _callback(response);
    })
}