import React, {useEffect, useState} from "react";
import {ModalMessage} from "../../../common/ModalMessage";
import {CONFIG} from "../../../../../CONFIG";
import {OrgHeader} from "../../../common/OrgHeader";
import {OrgNavigation} from "../../../common/OrgNavigation";
import {toNumber} from "lodash";
import {IChallenge} from "../../../../types/IChallenge";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {useParams, useSearchParams} from "react-router-dom";
import {AssessmentSettings} from "../right/AssessmentSettings";
import {AssessmentDetailsView} from "../right/AssessmentDetailsView";
import {IAssessmentList} from "../../../../types/assessment/IAssessmentList";
import {Spinner} from "../../../common/Spinner";
import {IAssessmentParticipantsListItem} from "../../../../types/assessment/IAssessmentParticipantsListItem";
import {IAssessmentTeamListItem} from "../../../../types/assessment/IAssessmentTeamListItem";
import {AssessmentListPersonal} from "./AssessmentListPersonal";
import {AssessmentListTeam} from "./AssessmentListTeam";

export const AssessmentTab = () => {

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [challenge, setChallenge] = useState<IChallenge>();
    const [assessmentList, setAssessmentList] = useState<IAssessmentList>();
    const [updItems, setUpdItems] = useState<Map<string, boolean>>(new Map<string, boolean>());
    const [page, setPage] = useState(0);
    const [message, setMessage] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [right, setRight] = useState<string | undefined>();
    const [currentParticipant, setCurrentParticipant] = useState<IAssessmentParticipantsListItem | undefined>();
    const [currentTeam, setCurrentTeam] = useState<IAssessmentTeamListItem | undefined>();
    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState(searchParams.get("filter") || "moderation")


    useEffect(() => {
        fetchChallenge();
    }, []);

    useEffect(() => {
        if (page === 0) fetchAssessmentList();
    }, [page]);

    /*useEffect(() => {
        setTimeout(() => setPage(0), 100);
        fetchAssessmentList();
    }, [filter]);
*/
    const fetchChallenge = () => {
        ChallengeAPI.getChallengeById(toNumber(params.id), (response) => {
            if (response.status === 200) {
                setChallenge(response.data);
            } else if (response.status === 404) {
                window.location.href = "/404";
            } else {
                setMessage("Ошибка при загрузке страницы");
            }
        })
    }

    const fetchAssessmentList = () => {
        ChallengeAPI.getAssessmentList(toNumber(params.id), toNumber(params.stageId), page, search, filter, (response) => {
            if (response.status === 200) {
                if (response.data.teams) {
                    let data = response.data as IAssessmentList;
                    let currentUpdItems = updItems;
                    data.teams?.items.forEach(i => {
                        if (i.team.allowAssessment === null) i.team.allowAssessment = true;
                        currentUpdItems.set(i.team.solutionId.toString(), i.team.allowAssessment);
                    });
                    setUpdItems(currentUpdItems);
                    if (!assessmentList?.teams || page == 0) {
                        setAssessmentList(data);
                        setPage(page + 1)
                    } else {
                        let currentTeams = assessmentList.teams;
                        currentTeams.totalPages = response.data.totalPages;
                        currentTeams.total = response.data.total;
                        currentTeams.items = currentTeams.items.concat(data.teams ? data.teams.items : []);
                        setAssessmentList({
                            ...assessmentList,
                            teams: currentTeams,
                            participants: undefined
                        });
                        setPage(page + 1)
                    }
                } else if (response.data.participants) {
                    let data = response.data as IAssessmentList;
                    let currentUpdItems = updItems;
                    data.participants?.items.forEach(i => {
                        if (i.allowAssessment === null) i.allowAssessment = true;
                        currentUpdItems.set(i.solutionId.toString(), i.allowAssessment);
                    });
                    setUpdItems(currentUpdItems);
                    if (!assessmentList?.participants || page == 0) {
                        setAssessmentList(data);
                        setPage(page + 1)
                    } else {
                        let currentParticipants = assessmentList.participants;
                        currentParticipants.totalPages = response.data.totalPages;
                        currentParticipants.total = response.data.total;
                        currentParticipants.items = currentParticipants.items.concat(data.participants ? data.participants.items : []);
                        setAssessmentList({
                            ...assessmentList,
                            teams: undefined,
                            participants: currentParticipants
                        });
                        setPage(page + 1)
                    }
                }
            } else {
                setMessage("Ошибка получения списка участников");
            }
        });
    }

    const handleCheck = (index: number) => {
        if (assessmentList?.participants?.items && assessmentList.participants.items.length > 0) {
            const currentParticipants = assessmentList.participants;
            const elm = currentParticipants.items[index];
            elm.allowAssessment = !elm.allowAssessment;
            currentParticipants.items[index] = elm;
            const currentUpdItems = updItems;
            currentUpdItems.set(elm.solutionId.toString(), elm.allowAssessment);
            setUpdItems(currentUpdItems);
            setAssessmentList({...assessmentList, participants: currentParticipants});
        } else if (assessmentList?.teams?.items && assessmentList.teams.items.length > 0) {
            const currentTeams = assessmentList.teams;
            const elm = currentTeams.items[index];
            elm.team.allowAssessment = !elm.team.allowAssessment;
            currentTeams.items[index] = elm;
            const currentUpdItems = updItems;
            currentUpdItems.set(elm.team.solutionId.toString(), elm.team.allowAssessment);
            setUpdItems(currentUpdItems);
            setAssessmentList({...assessmentList, teams: currentTeams});
        }
    }

    const submitAllowAssessment = () => {
        if (!confirm("Вы собираетесь отправить работы на оценку экспертам, после этого действия станет невозможным добавлять/удалять критерии оценки для этого этапа. Вы уверены, что хотите отправить работы на оценку?")) return;
        setShowSpinner(true);
        ChallengeAPI.updateAssessmentList(toNumber(params.id), toNumber(params.stageId), updItems, (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setMessage("Ошибка при обновлении списка");
            }
            setShowSpinner(false);
        });
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setPage(0);
            //fetchAssessmentList();
        }
    }

    const pickParticipant = (participant: IAssessmentParticipantsListItem) => {
        setCurrentParticipant(participant);
        setRight("details");
    }

    const pickTeam = (team: IAssessmentTeamListItem) => {
        setCurrentTeam(team);
        setRight("details");
    }

    const hideAll = () => {
        setRight(undefined)
        setCurrentParticipant(undefined);
        setCurrentTeam(undefined);
    }

    return (
        <div className="platform-background">
            <Spinner text={"Подождите..."} enabled={showSpinner}/>
            <ModalMessage msg={message} header={"Ошибка"} _callback={() => setMessage("")}/>
            {challenge?.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                        <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                    <source src={require("../../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <OrgNavigation />
                    <div className="main-list-center">
                        {
                            (assessmentList?.participants && assessmentList.participants.items.length > 0) ||
                            (assessmentList?.teams && assessmentList.teams.items.length > 0) ?
                                <button onClick={submitAllowAssessment} className="button-add-rate">
                                    Допустить к оценке
                                </button>
                                :
                                <></>
                        }

                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">{challenge?.name}</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href="?filter=moderation" className={filter === "moderation" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"} style={{cursor: "pointer"}}>На модерации</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href="?filter=allowed" className={filter === "allowed" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"} style={{cursor: "pointer"}}>Допущенные</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href="?filter=not_allowed" className={filter === "not_allowed" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"} style={{cursor: "pointer"}}>Недопущенные</a>
                                </li>
                            </ul>
                        </div>
                        <div className="challenge-info" id="scrollableDiv">
                            <input type="text" placeholder="Поиск"
                                   className="input-challenges"
                                   onChange={(e) => setSearch(e.target.value)}
                                   onKeyDown={handleKeyDown}/>

                                <div className="block-settings-challenge">
                                    <button className="settings-challenge-button" onClick={() => setRight("settings")}>Настройки</button>
                                </div>


                            {
                                assessmentList?.participants && assessmentList.participants.items.length > 0 ?
                                    <AssessmentListPersonal
                                        participants={assessmentList.participants}
                                        page={page} fetchAssessmentList={fetchAssessmentList}
                                        handleCheck={handleCheck}
                                        pickParticipant={pickParticipant}/>
                                    :
                                    <></>
                            }
                            {
                                assessmentList?.teams && assessmentList.teams.items.length > 0 ?
                                    <AssessmentListTeam
                                        teams={assessmentList.teams}
                                        page={page}
                                        handleCheck={handleCheck}
                                        fetchAssessmentList={fetchAssessmentList}
                                        pickTeam={pickTeam}/>
                                    :
                                    <></>
                            }
                            { !assessmentList || ((!assessmentList?.participants || assessmentList.participants.items.length == 0) && (!assessmentList.teams || assessmentList.teams.items.length == 0)) ?
                                <div className="first-message-error">
                                    <p className="fio-or-name">
                                        Поиск не дал результатов
                                    </p>
                                    <p className="city">
                                        Введите реальные вакансии, которые существуют или заявлены
                                        в рамках описания и правил конкурса
                                    </p>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                </main>
            </div>

            {right == "settings" ? <AssessmentSettings close={hideAll}/> : <></>}
            {right == "details" ? <AssessmentDetailsView close={hideAll} teamItem={currentTeam} participantItem={currentParticipant}/> : <></>}
        </div>
    )
}