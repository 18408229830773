import React, {useState} from "react";
import {ModalMessage} from "../ModalMessage";
import * as SsoApi from "../../../../requests/SsoAPI";
import {IMakeSubRequest} from "../../../types/subs/IMakeSubRequest";

export const SubInvite:React.FC<{close: () => void}> = ({close}) => {

    const [subRequest, setSubRequest] = useState<IMakeSubRequest>();
    const [error, setError] = useState("");

    const makeSubRequest = () => {
        if (!subRequest) return;
        SsoApi.makeSubRequest(subRequest, (response) => {
            if (response.status === 200) {
                setSubRequest({...subRequest, username: ""});
                window.location.reload();
            } else {
                setError(response.data.message);
            }
        })
    }

    return (
        <div className="right-block">
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <header className="header">
                <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                <p className="name-menu">Новый администратор</p>
            </header>

            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <h2 className="headline-right-block">Введите email</h2>
                    <p className="challenges-description">
                        Выберите email пользователя на платформе
                    </p>
                    <input type="email" onChange={(e) => setSubRequest({...subRequest, username: e.target.value})} className="input-challenges" placeholder="example@example.ru"/>
                    <input type="button" className="btn-main" value="Добавить" onClick={makeSubRequest}/>
                </div>
            </div>
        </div>
    )
}