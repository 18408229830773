import React, {useEffect, useState} from "react";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {IProfileUniversityForm} from "../../types/IProfileUniversityForm";
import {IProfileSettings} from "../../types/IProfileSettings";
import API from "../../../requests/_axios";
import SessionModel from "../../../stores/SessionModel";
import {IProfileBusinessForm} from "../../types/IProfileBusinessForm";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";

export const ProfileSettings: React.FC<{profile: IProfilePersonalForm | IProfileUniversityForm | IProfileBusinessForm | undefined}> = ({profile}) => {

    const [settings, setSettings] = useState<IProfileSettings>();
    const [settingsError, setSettingsError] = useState("");
    const [url, setUrl] = useState("");

    useEffect(() => {
        if (profile) {
            setSettings({...settings, mailing: profile.mailing});
            if (profile.userId) {
                ChallengeAPI.getProfileById("personal", profile.userId, (response) => {
                    if (response.status === 200) {
                        setUrl("/challenge/api/v1/personal/profile");
                    } else {
                        ChallengeAPI.getProfileById("university", profile.userId || 0, (response) => {
                            if (response.status === 200) {
                                setUrl("/challenge/api/v1/university/profile");
                            } else {
                                ChallengeAPI.getProfileById("business", profile.userId || 0, (response) => {
                                    if (response.status === 200) {
                                        setUrl("/challenge/api/v1/business/profile");
                                    }
                                })
                            }
                        })
                    }
                });
            }
        }
    }, [profile?.mailing]);

    const handleUpdateSettings = () => {
        setSettingsError("");
        if(!settings?.newPassword && !settings?.oldPassword && !settings?.reNewPassword) {
            updateSettings();
            return;
        }
        if (!settings?.newPassword || !settings.oldPassword) {
            setSettingsError("Пароль не может быть пустым");
            return;
        }
        if (settings.newPassword !== settings.reNewPassword) {
            setSettingsError("Пароли не совпадают");
            return;
        }
        updateSettings();
    }

    const updateSettings = () => {
        API.patch(url, settings, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
            if (response.status === 200) {
                alert("Изменения внесены");
            } else {
                setSettingsError("Старый пароль указан неверно");
            }
        });
    }

    return (
        <div className="menu-challenge">
            <h2 className="headline-right-block">Настройки</h2>
            <p className="error-message" id="form-validation-error" style={{display: settingsError === "" ? "none" : ""}}>Ошибка! {settingsError}</p>
            <fieldset className="block-input">
                <legend className="legend">Смена пароля</legend>
                <input type="password" value={settings?.oldPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                    setSettings({...settings, oldPassword: e.target.value});
                }} className="input-challenges" placeholder="Старый пароль"/>
                <input type="password" value={settings?.newPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                    setSettings({...settings, newPassword: e.target.value});
                }} className="input-challenges" placeholder="Новый пароль"/>
                <input type="password" value={settings?.reNewPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                    setSettings({...settings, reNewPassword: e.target.value});
                }} className="input-challenges" placeholder="Подтвердите пароль"/>
            </fieldset>
            <fieldset className="block-input-flex">
                <legend className="legend">Рассылки</legend>
                <label className="input-challenges-flex">
                    <input type="checkbox" name="mailing" checked={settings?.mailing} onChange={() => setSettings({...settings, mailing: !settings?.mailing})} className="radio-btn"/>
                    <span className="span-radio">Подписан</span>
                </label>
            </fieldset>
            <button className="btn-main" onClick={handleUpdateSettings}>Сохранить</button>
        </div>
    )
}