import React, {useEffect, useState} from "react";
import SessionModel from "../../../stores/SessionModel";
import {ChallengeListAdmin} from "../admin/challenge/ChallengeListAdmin";
import {ChallengeListIndividual} from "../personal/challenge/ChallengeListIndividual";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import {BlankPage} from "./BlankPage";
import {Spinner} from "./Spinner";

export const ChallengeList = () => {
  let panel;
  
  const [hasChallengePlan, setHasChallengePlan] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if ((SessionModel.isOrg() || SessionModel.isSub()) && !SessionModel.isAdmin()) {
      setShowSpinner(true);
    }
    getChallengePlan();
    if (SessionModel.isSub()) {
      setIsApproved(true);
      setShowSpinner(false);
    } else {
      ChallengeAPI.getProfile((response) => {
        if (response.status === 200) {
          setIsApproved(response.data.isApproved);
        } else {
          setIsApproved(false);
        }
        setShowSpinner(false);
      });
    }
  }, []);

  const getChallengePlan = () => {
    ChallengeAPI.getActivePlan((response) => {
      if (response.status === 200) {
        setHasChallengePlan(true);
      }
    })
  }

  if (showSpinner) {
    return <Spinner text={"Подождите..."} enabled={showSpinner}/>
  } else if(SessionModel.getCurrentUserAuthData().type === "PERSONAL") {
    panel = <ChallengeListIndividual />
  } else if (SessionModel.isOrg() || SessionModel.isAdmin()) {
    panel = <ChallengeListAdmin />
    if (SessionModel.isOrg() && !hasChallengePlan && !isApproved) {
      panel = <BlankPage message={"В этом разделе вы можете создавать свои собственные мероприятия для участников из вашей организации и/или внешних участников, в зависимости от выбранного тарифа. Для доступа к функционалу вам необходимо подключить бесплатный базовый тариф или один из платных тарифов в разделе \"Тарифы\", далее \"Тарифы\""}/>
    }
    if (SessionModel.isOrg() && !hasChallengePlan && isApproved) {
      panel = <BlankPage message={"В этом разделе вы можете создавать свои собственные мероприятия для участников из вашей организации и/или внешних участников, в зависимости от выбранного тарифа. Для доступа к функционалу вам необходимо подключить бесплатный базовый тариф или один из платных тарифов в разделе \"Тарифы\", далее \"Тарифы\". Посмотрите <a href=\"https://disk.yandex.ru/d/jvAi035p0_Vm0w\" class=\"active-link\" target=\"_blank\">видеообзор</a> сервиса"}/>
    }
  } else if (SessionModel.isSub() && hasChallengePlan) {
    panel = <ChallengeListAdmin />
  }
  return (
      <div>
        {panel}
      </div>
  )
}