import React, {useEffect, useState} from "react";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {CONFIG} from "../../../CONFIG";
import SessionModel from "../../../stores/SessionModel";
import {ITeam} from "../../types/ITeam";
import API from "../../../requests/_axios";
import {useNavigate, useParams} from "react-router-dom";
import {RecruitmentPanel} from "../personal/challenge/right/RecruitmentPanel";
import {toNumber} from "lodash";

export const ProfileIndividualView: React.FC<{
  currentForm: IProfilePersonalForm | undefined,
  team: ITeam | undefined,
  enableRecruitmentPanel: boolean}>
    = ({currentForm,
         team, enableRecruitmentPanel}) => {

  const [viewable, setViewable] = useState(true);
  const [canEditTeamRole, setCanEditTeamRole] = useState(false);
  const [teamRole, setTeamRole] = useState<string | undefined>("");
  const [error, setError] = useState("");
  const params = useParams();

  const getEducationStage = () => {
    switch (currentForm?.educationStage) {
      case 1:
        return "Бакалавриат";
      case 2:
        return "Специалитет";
      case 3:
        return "Магистратура";
      case 4:
        return "Аспирантура";
    }
  }

  useEffect(() => {
    if(SessionModel.getCurrentUserAuthData().type === "EDU") {
      if(currentForm?.transferPersonalAgreement && currentForm.transferPersonalAgreement)
        setViewable(true);
    } else if(SessionModel.getCurrentUserAuthData().type === "PERSONAL" || SessionModel.getCurrentUserAuthData().type === "ADMIN") {
      setViewable(true);
      setTeamRole(currentForm?.teamRole || "");
      if(team && team.userId === toNumber(SessionModel.getCurrentUserAuthData().id) && currentForm?.userId !== toNumber(SessionModel.getCurrentUserAuthData().id)) {
        setCanEditTeamRole(true);
      }
    }
  }, []);

  const submitRole = () => {
    API.post("/challenge/api/v1/challenge/" + team?.challengeId + "/team/" + team?.id + "/user/" + currentForm?.userId + "/role", {"role": teamRole}).then((response) => {
      if(response.status === 200) {
        window.location.reload();
      } else {
        setError("Что-то пошло не так. Попробуйте повторить запрос позже или обратитесь в поддержку");
      }
    })
  }

  const kickMember = () => {
    API.delete(`/challenge/api/v1/challenge/${team?.challengeId}/team/${team?.id}/user/${currentForm?.userId}`).then((response) => {
      if (response.status === 200) {
        window.location.reload();
      }
    });
  }

  return(
      <div className="container-right-block">
      <div className="menu-challenge">
        <h2 className="headline-right-block">Профиль участника</h2>
        <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
        <ul className="responses-list">
          <li className="first-message" style={{display: viewable ? "none" : ""}}>
            <p className="city">
              Пользователь предпочел не предоставлять свои данные
            </p>
          </li>
          <li className="respose-item" style={{display: viewable ? "" : "none"}}>
            <div className="username">
              <img src={CONFIG.STORAGE_URL + "/" + currentForm?.userId + "/avatar/" + currentForm?.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} alt="" className="header-avatar"/>
                <p className="fio-or-name">{(currentForm?.firstname || "Имя") + " " + (currentForm?.lastname || "Фамилия")}</p>
                <p className="city-student">{currentForm?.city}</p>
            </div>
            {canEditTeamRole ? (
                <div>
                  <fieldset className="block-input">
                    <legend className="legend">Роль в команде</legend>
                    <div className="role-wrapper">
                      <input type="text" value={teamRole} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTeamRole(e.target.value)
                      }} className="input-role" placeholder="Назначить роль"/>
                      <button className="btn-ok" onClick={submitRole}/>
                    </div>
                  </fieldset>
                </div>
            ) : (<></>)}
            <div className="block-input">
              <p className="legend-about">
                Навыки
              </p>
              <ul className="interests">
                {
                  currentForm?.skills && currentForm.skills.split(",").map((skill, i) =>
                      <li key={i} className="interest-item">
                        {skill.trim()}
                      </li>
                  )
                }
              </ul>
            </div>

            <div className="block-input">
              <p className="legend-about">
                Образование
              </p>
              <ul className="career-list">
                <li className="career-item">
                  <h4 className="career-heading">
                    {currentForm?.university}
                  </h4>
                  {/*<p className="kurs">*/}
                  {/*  {currentForm?.course + " "}*/}
                  {/*</p>*/}
                  {/*<h4 className="career-heading">*/}
                  {/*  {currentForm?.faculty}*/}
                  {/*</h4>*/}
                  {/*<p className="education-stage">*/}
                  {/*  {getEducationStage()}*/}
                  {/*</p>*/}
                  <p className="education-status" style={{display: currentForm?.personStatus ? "" : "none"}}>
                    {currentForm?.personStatus === 1 ? "Студент" : "Выпускник"}
                  </p>
                </li>
              </ul>
            </div>

            {currentForm?.about ? (
                <div className="block-input">
                  <p className="legend-about">
                    О себе
                  </p>
                  <ul className="career-list">
                    <li className="career-item">
                      <p className="career-description">
                        {currentForm?.about}
                      </p>
                    </li>
                  </ul>
                </div>
            ) : (<></>)}
            {
                SessionModel.getCurrentUserAuthData().type === "EDU" ?
                    <>
                      <div className="block-input">
                        <p className="legend-about">
                          E-mail
                        </p>
                        <ul className="career-list">
                          <li className="career-item">
                            <p className="career-description">
                              {currentForm?.username}
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div className="block-input">
                        <p className="legend-about">
                          Телефон
                        </p>
                        <ul className="career-list">
                          <li className="career-item">
                            <p className="career-description">
                              {currentForm?.phone}
                            </p>
                          </li>
                        </ul>
                      </div>
                    </> : <></>
            }
            {!currentForm?.telegram ? (
                <></>
            ) : (
                <div className="challenge-information">
                  <p className="message-user">
                    <a href={"https://t.me/" + currentForm?.telegram} target="_blank">Сообщение</a>
                  </p>
                </div>
            )}
          </li>

        </ul>
        {canEditTeamRole ? (
            <button className="btn-no" onClick={() => {if(window.confirm('Вы действительно желаете удалить участника?')){kickMember()}}}>Удалить участника</button>
        ) : (<></>)}
        {enableRecruitmentPanel ? <RecruitmentPanel adId={undefined} userId={currentForm?.userId} teamId={undefined} challengeId={toNumber(params.id)}/> : ""}
      </div>
      </div>
  )

}