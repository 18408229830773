import React, {useEffect, useState} from "react";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {toNumber} from "lodash";
import {useParams} from "react-router-dom";
import {ModalMessage} from "../../../common/ModalMessage";
import {IStageRating} from "../../../../types/IStageRating";
import {CONFIG} from "../../../../../CONFIG";
import SessionModel from "../../../../../stores/SessionModel";

export const Rating:React.FC<{stageId: number | undefined}> = ({stageId}) => {

    const params = useParams();
    const [error, setError] = useState("");
    const [ratingList, setRatingList] = useState([]);
    const [myId, setMyId] = useState(0);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (ratingList.length === 0) {
            setMessage("Для данного этапа/задания рейтинг не предусмотрен");
        } else {
            setMessage("");
        }
    }, [ratingList]);

    useEffect(() => {
        if (stageId) {
            setMessage("Загрузка...");
            ChallengeAPI.getRating(toNumber(params.id), stageId, (response) => {
               if (response.status === 200) {
                   setRatingList(response.data);
               } else {
                   setMessage("");
                   setError("Ошибка при получении рейтинга");
               }
            });
            ChallengeAPI.getMyTeam(toNumber(params.id), (response) => {
                if (response.status === 200) {
                    setMyId(response.data.team.id);
                } else {
                    setMyId(SessionModel.getCurrentUserAuthData().id);
                }
            });
        }
    }, [stageId])

    return (
        <>
            <a href={"#id" + myId} className="button-add-question">Где я в рейтинге</a>
            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <ul className="rating-challenge-list">
                        {message === "" ? <></> :
                            <div className="first-message">
                                <p className="fio-or-name">
                                    {message}
                                </p>
                            </div>}
                        {ratingList.map((record: IStageRating, index) =>
                            <li className="rating-challenge-item" key={index} id={"id" + (record.teamId === null ? record.userId || 0 : record.teamId || 0).toString()}>
                                <div className="rating-challenge-wrapper">
                                    <img src={
                                        record.teamId ?
                                            CONFIG.STORAGE_URL + "/" + record.userId + "/team/" + record.teamId + "/avatar/" + record.avatar
                                            :
                                            CONFIG.STORAGE_URL + "/" + record.userId + "/avatar/" + record.avatar
                                    }
                                         onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                                         className="rating-challenge-avatar"/>
                                    <div className="rating-challenge-user">
                                        <p className="rating-challenge-name">
                                            {record.name}
                                        </p>
                                        <p className="rating-challenge-place">
                                            {record.place} место
                                        </p>
                                    </div>
                                </div>
                                <p className="rating-chellenge-num">
                                    {record.points}
                                </p>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </>
    )
}