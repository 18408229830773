import React, {useEffect, useState} from "react";
import {ITeamAd} from "../../../../types/ITeamAd";
import {CONFIG} from "../../../../../CONFIG";
import API from "../../../../../requests/_axios";
import {useParams} from "react-router-dom";
import {IProfilePersonalForm} from "../../../../types/IProfilePersonalForm";
import plural from "plural-ru";
import {RecruitmentPanel} from "./RecruitmentPanel";
import {toNumber} from "lodash";
import SessionModel from "../../../../../stores/SessionModel";
import {ITeam} from "../../../../types/ITeam";

export const AdDetails: React.FC<{
  ad: ITeamAd | undefined,
  showProfilePanel: (profile: IProfilePersonalForm, team: ITeam | undefined, enableRecruitmentPanel: boolean) => void,}>
    = ({ad, showProfilePanel}) => {

  const params = useParams();
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    if (ad) {
      API.get(`/challenge/api/v1/challenge/${params.id}/team/${ad?.team?.id}/members`).then((response) => {
        if(response.status === 200)
          setTeamMembers(response.data);
      })
    }
  }, [ad])

  return (
      <div className="container-right-block">

        <div className="menu-challenge">
          <h2 className="headline-right-block">{ad?.name}</h2>
          <ul className="responses-list">
            <li className="respose-item">
              <div className="username">
                <img src={CONFIG.STORAGE_URL + "/" + ad?.team?.userId + "/team/" + ad?.team?.id + "/avatar/" + ad?.team?.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                     alt="" className="header-avatar"/>
                  <p className="fio-or-name">{ad?.team?.name}</p>
                  <p className="city-student">{plural(teamMembers.length, "%d человек", "%d человека", "%d человек")}</p>
              </div>

              <div className="challenge-card">
              {teamMembers && teamMembers.map((member: IProfilePersonalForm, key) =>
                  <div key={key} className="challenge-card-mini">
                    <img src={CONFIG.STORAGE_URL + "/" + member.userId + "/avatar/" + member.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                         alt=""
                         onClick={() => showProfilePanel(member, undefined, false)}
                         className="challenge-avatar"/>
                    <div className="user-info">
                      <p className="challenge-header" onClick={() => showProfilePanel(member, undefined, false)}>{member.firstname + " " + member.lastname}</p>
                      <p className="city">{member.city}</p>
                    </div>
                  </div>
              )}
              </div>

            </li>

            <div className="block-input">
              <p className="legend-about">
                Необходимые навыки
              </p>
              <ul className="interests">
                {ad?.skills && ad.skills.split(",").map((skill, key) =>
                    <li key={key} className="interest-item">
                      {skill}
                    </li>
                )}
              </ul>
            </div>
            <div className="block-input">
              <ul className="career-list">
                <li className="career-item">
                  <h4 className="career-heading">
                    Описание команды
                  </h4>
                  <p className="career-description">
                    {ad?.team?.description}
                  </p>
                </li>
                <li className="career-item">
                  <h4 className="career-heading">
                    Что делать
                  </h4>
                  <p className="career-description">
                    {ad?.description}
                  </p>
                </li>
                <li className="career-item">
                  <h4 className="career-heading">
                    Название проекта
                  </h4>
                  <p className="career-description">
                    {ad?.team?.projectName}
                  </p>
                </li>
                <li className="career-item">
                  <h4 className="career-heading">
                    Описание проекта
                  </h4>
                  <p className="career-description">
                    {ad?.team?.projectDescription}
                  </p>
                </li>
              </ul>
            </div>

          </ul>
          <RecruitmentPanel challengeId={toNumber(params.id)} userId={undefined} teamId={ad?.teamId} adId={ad?.id}/>
        </div>
      </div>
  )
}