import React, {useEffect} from "react";
import API from "../requests/_axios";
import {useNavigate, useParams} from "react-router-dom";
import cookie from "js-cookie";

export const Confirm = () => {
  let params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    API.post("/sso/api/v1/user").then((response) => {
      if (response.status === 200)
        navigate("/profile");
    });
    confirm();
  }, []);

  const confirm = () => {
    API.get<any>("/sso/api/v1/signup/confirm/" + params.userId + "/" + params.confirmationCode).then((response) => {
      if(response.status === 200) {
          localStorage.removeItem("token");
          localStorage.setItem("token", response.data.token);
          alert("Ваша учетная запись подтверждена");
          const redirectPath = cookie.get("afterLoginRedirect") && response.data.type === "PERSONAL" ? "/signup?step=3" : "/signin";
          navigate(redirectPath);
      } else {
        alert("Ошибка подтверждения. Попробуйте повторить попытку позже");
        navigate("/signin");
      }
    });
  }

  return (
      <></>
  );
}

