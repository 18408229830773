import React, {MutableRefObject, useEffect, useRef, useState} from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {IChallengeStageSolution} from "../../../../types/IChallengeStageSolution";
import {toNumber} from "lodash";
import {ModalMessage} from "../../../common/ModalMessage";
import {IChallengeStageCTFTask} from "../../../../types/IChallengeStageCTFTask";

export const CTFStage: React.FC<{stage: IChallengeStage}> = ({stage}) => {

    const [solutions, setSolutions] = useState<IChallengeStageSolution[]>([]);
    const [currentStage, setCurrentStage] = useState<IChallengeStage>(stage);
    const [error, setError] = useState("");

    useEffect(() => {
        refreshSolutions();
    }, [currentStage]);

    const refreshSolutions = () => {
        if (!currentStage.challengeId || !currentStage.id) return;
        ChallengeAPI.getSolutions(currentStage.challengeId, currentStage.id, (response) => {
            let responseSolutions: IChallengeStageSolution[];
            if(response.status === 200 && response.data) {
                responseSolutions = response.data;
            }
            let initFormSolutions: IChallengeStageSolution[] = [];
            currentStage.ctfTasks.forEach(t => {
                let existentSolution = responseSolutions.find((s) => s.ctfTaskId === t.id)
                let ifs: IChallengeStageSolution = {
                    id: existentSolution ? existentSolution.id : undefined,
                    ctfTaskId: t.id
                }
                initFormSolutions.push(ifs);
            });
            setSolutions(initFormSolutions);
        })
    }

    const getTaskById = (taskId: string): IChallengeStageCTFTask => {
        return currentStage.ctfTasks.filter((t) => t.id === taskId)[0]
    }

    const getMaxPoints = () => {
        let total = 0;
        currentStage.ctfTasks.forEach(t => total += t.points || 0)
        return total;
    }

    const getMyPoints = () => {
        let total = 0;
        currentStage.ctfTasks.forEach(t => {
            total += isSolved(t.id) ? t.points || 0 : 0;
        })
        return total;
    }


    const submit = (taskId: string | undefined, flag: string) => {
        const solution: IChallengeStageSolution = {
            ctfTaskId: taskId,
            flag: flag
        }
        ChallengeAPI.submitSolution(toNumber(currentStage.challengeId), toNumber(currentStage.id), solution, (response) => {
            if (response.status === 200) {
                ChallengeAPI.getStage(toNumber(currentStage.challengeId), toNumber(currentStage.id), (rStage) => {
                    setCurrentStage(rStage.data);
                })
            } else {
                if (response.data.code === "009-040") {
                    setError("Неверный флаг");
                } else if (response.data.code === "009-022") {
                    setError("Для отправки работы вы должны состоять в команде. Капитану необходимо нажать на кнопку \"Закончить формирование команды\"");
                } else {
                    setError(`Ошибка ${response.data.code}. Пожалуйста, повторите попытку позже`);
                }
                ChallengeAPI.getStage(toNumber(currentStage.challengeId), toNumber(currentStage.id), (rStage) => {
                    setCurrentStage(rStage.data);
                })
            }
        })
    }

    const isSolved = (taskId: string | undefined) => {
        const solution = solutions.find(s => s.ctfTaskId === taskId);
        return !!(solution && solution.id);
    }

    const updateSolutionFlag = (index: number, flag: string) => {
        let currentSolutions = solutions;
        const solution = currentSolutions[index];
        solution.flag = flag;
        currentSolutions[index] = solution;
        setSolutions(currentSolutions);
    }

    return (
        <div className="container-right-block">
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <div className="ctf-main-info">
                <h2 className="ctf-heading">{stage.name}</h2>
                <div className="ctf-main-info-rating">
                    <p className="ctf-main-info-rating-info">
                        Мои баллы итого
                        <span className="ctf-rating-num">
                            {getMyPoints()}
                        </span>
                    </p>
                    <p className="ctf-main-info-rating-info">
                        Максимальное количество баллов
                        <span className="ctf-rating-num">
                            {getMaxPoints()}
                        </span>
                    </p>
                </div>
                <a href={`/challenge/${stage.challengeId}/stage/${stage.id}/dashboard`} className="btn-blue-width" target="_blank">Дашборд</a>
                <p className="ctf-main-text" dangerouslySetInnerHTML={{__html: stage?.description || ""}}></p>
            </div>
            <h2 className="headline-right-block">
                Задания
            </h2>
            <div className="menu-challenge">
                {solutions?.map((solution, index) => solution.ctfTaskId ?
                    <div className={isSolved(solution.ctfTaskId) ? "stage-button" : "stage-button-new"} key={index} style={{cursor: "default"}}>
                        <div className="stage-card-info">
                            <h5 className="stage-card-heading">Задание {index + 1}</h5>
                            <p className={isSolved(solution.ctfTaskId) ? "stage-card-done" : "stage-card-active"}>
                                {isSolved(solution.ctfTaskId) ? "Завершено" : "Нет решения"}
                            </p>
                        </div>
                        {
                            isSolved(solution.ctfTaskId) ?
                                <p className="point-system">
                                    Мои баллы <span className="point-system-fact">{getTaskById(solution.ctfTaskId).points}</span> / максимум <span
                                    className="point-system-max">{getTaskById(solution.ctfTaskId).points}</span>
                                </p>
                                :
                                <p className="point-system">
                                    Баллов максимум <span className="point-system-max">{getTaskById(solution.ctfTaskId).points}</span>
                                </p>
                        }
                        <h2 className="stage-card-name">
                            {getTaskById(solution.ctfTaskId).name}
                        </h2>
                        <p className="btn-info-text">
                            {getTaskById(solution.ctfTaskId).description}
                        </p>
                        {
                            isSolved(solution.ctfTaskId) ?
                                <></>
                                :
                                <>
                                    {getTaskById(solution.ctfTaskId).taskUrl ? <a href={getTaskById(solution.ctfTaskId).taskUrl} className="link-flag" target="_blank">Ссылка на задание</a> : <></>}
                                    {
                                        new Date() > new Date(stage?.endDate + "+03:00" || "") ?
                                            <></>
                                            :
                                            <>
                                                <fieldset className="block-input">
                                                    <legend className="legend">Флаг</legend>
                                                    <input type="text" value={solution.flag} onChange={(e) => updateSolutionFlag(index, e.target.value)} className="input-challenges" placeholder="Впишите флаг"/>
                                                    {getTaskById(solution.ctfTaskId).isHintVisible ? <div className="ctf-main-info-rating-info">Подсказка: {getTaskById(solution.ctfTaskId).hint}</div> : <></>}
                                                </fieldset>
                                                <button className="btn-main" onClick={() => submit(solution.ctfTaskId, solution.flag || "")}>Отправить</button>
                                            </>
                                    }
                                </>
                        }
                    </div>
                    :
                    <></>
                )}



            </div>
        </div>
    )
}