import React, {useEffect, useRef, useState} from "react";
import * as TestAPI from "../../../../../requests/TestAPI";
import {ITopic} from "../../../../types/ITopic";
import {ModalMessage} from "../../../common/ModalMessage";
import {toNumber} from "lodash";
import {IQuestion} from "../../../../types/IQuestion";
import {IAnswer} from "../../../../types/IAnswer";
import {IUpdateQuestionsRequest} from "../../../../types/IUpdateQuestionsRequest";
import {b64toBlob, getBase64} from "../../../../helpers/Util";
import {CONFIG} from "../../../../../CONFIG";
import SessionModel from "../../../../../stores/SessionModel";

export const TopicSettings: React.FC<{topicId: string, testId: string, refresh: () => void}> = ({topicId, testId, refresh}) => {

    const [infoMessage, setInfoMessage] = useState("");
    const [error, setError] = useState("");
    const [topic, setTopic] = useState<ITopic>();
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [qNumHint, setQNumHint] = useState(false);
    const [priorityHint, setPriorityHint] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (topicId) {
            TestAPI.getTopic(topicId, (response) => {
                if (response.status === 200) {
                    setTopic(response.data);
                    if (response.data.questions && response.data.questions.length > 0) {
                        setQuestions(response.data.questions);
                    } else {
                        setQuestions([]);
                    }
                } else {
                    setError("Ошибка получения темы");
                }
            })
        } else {
            setTopic({...topic, testId: testId, id: "", name: "", questionNum: 0, pointsPerQuestion: 0, questions: []});
            setQuestions([]);
        }
    }, [topicId]);

    const saveTopic = () => {
        if (!topic) return;
        if (topic?.id) {
            TestAPI.updateTopic(topic, (response) => {
                if (response.status === 200) {
                    setInfoMessage("Тема успешно обновлена");
                    refresh();
                } else {
                    if (response.data.code === "006-001") {
                        setError(response.data.message);
                    } else {
                        setError("Ошибка при публикации/снятии с публикации");
                    }
                }
            })
        } else {
            TestAPI.createTopic(topic, (response) => {
                if (response.status === 200) {
                    setTopic(response.data);
                    addQuestion();
                    refresh();
                } else {
                    if (response.data.code === "006-001") {
                        setError(response.data.message);
                    } else {
                        setError("Ошибка при сохранении темы");
                    }
                }
            })
        }
        refresh();
    }

    const addQuestion = () => {
        let question: IQuestion = {
            id: "",
            name: "",
            b64: "",
            isVisible: true,
            type: "SINGLE",
            answers: []
        }
        setQuestions(current => [...current, question]);
        setTimeout(() => {
            // @ts-ignore
            const lastChildElement = ref.current?.lastElementChild;
            lastChildElement?.scrollIntoView({ behavior: 'smooth' });
        }, 100 );
    }

    const addAnswer = (questionIndex: number) => {
        const newQuestions = [...questions];
        let question = questions[questionIndex];
        let answers = question.answers;
        let answer: IAnswer = {
            id: "",
            name: "",
            correct: false
        }
        answers.push(answer);
        question.answers = answers;
        newQuestions[questionIndex] = question;
        setQuestions(newQuestions);
    }

    const changeAnswerName = (questionIndex: number, index: number, value: string) => {
        const newQuestions = [...questions];
        let question = questions[questionIndex];
        let answers = question.answers;
        let answer = answers[index];
        answer.name = value;
        answers[index] = answer;
        question.answers = answers;
        newQuestions[questionIndex] = question;
        setQuestions(newQuestions);
    }

    const changeAnswerCorrect = (questionIndex: number, index: number) => {
        const newQuestions = [...questions];
        let question = questions[questionIndex];
        let answers = question.answers;
        if (question.type === "SINGLE") {
            answers.forEach(a => a.correct = false);
        }
        let answer = answers[index];
        answer.correct = !answer.correct;
        answers[index] = answer;
        question.answers = answers;
        newQuestions[questionIndex] = question;
        setQuestions(newQuestions);
    }

    const changeQuestionName = (index: number, value: string) => {
        const newQuestions = [...questions];
        const question = questions[index];
        question.name = value;
        newQuestions[index] = question;
        setQuestions(newQuestions);
    }

    const changeQuestionType = (index: number, value: string) => {
        const newQuestions = [...questions];
        const question = questions[index];
        question.type = value;
        if (value === "SINGLE") {
            let answers = question.answers;
            answers.forEach(a => a.correct = false);
            question.answers = answers;
        }
        newQuestions[index] = question;
        setQuestions(newQuestions);
    }

    const attachQuestionImage = (index: number, fileList: FileList | null) => {
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер изображения не должен превышать 5Мб.");
            return;
        }
        const newQuestions = [...questions];
        const question = questions[index];
        getBase64(fileList[0], ((b64) => {
            question.b64 = b64;
            newQuestions[index] = question;
            setQuestions(newQuestions);
        }));
    };

    const deleteQuestion = (index: number) => {
        const question = questions[index];
        if (question.id) {
            TestAPI.deleteQuestion(question.id, (response) => {
               if (response.status !== 200) {
                   if (response.data.code === "006-001") {
                       setError("Удалить нельзя. Вопрос уже используется участниками");
                   } else {
                       setError("Ошибка удаления вопроса");
                   }
                   return;
               } else {
                   const newFirstPartQuestions = questions.slice(0, index);
                   const newSecondPartQuestions = questions.slice(index + 1);
                   const newQuestions = newFirstPartQuestions.concat(newSecondPartQuestions);
                   setQuestions(newQuestions);
               }
            });
        } else {
            const newFirstPartQuestions = questions.slice(0, index);
            const newSecondPartQuestions = questions.slice(index + 1);
            const newQuestions = newFirstPartQuestions.concat(newSecondPartQuestions);
            setQuestions(newQuestions);
        }
    }

    const deleteAnswer = (questionIndex: number, answerIndex: number) => {
        const newQuestions = [...questions];
        const question = questions[questionIndex];
        const answer = question.answers[answerIndex];
        if (answer.id && question.id) {
            TestAPI.deleteAnswer(question.id, answer.id, (response) => {
                if (response.status !== 200) {
                    if (response.data.code === "006-001") {
                        setError("Удалить нельзя. Вопрос уже используется участниками");
                    } else {
                        setError("Ошибка удаления ответа");
                    }
                    return;
                } else {
                    const newFirstPartAnswers = question.answers.slice(0, answerIndex);
                    const newSecondPartAnswers = question.answers.slice(answerIndex + 1);
                    question.answers = newFirstPartAnswers.concat(newSecondPartAnswers);
                    newQuestions[questionIndex] = question;
                    setQuestions(newQuestions);
                }
            })
        } else {
            const newFirstPartAnswers = question.answers.slice(0, answerIndex);
            const newSecondPartAnswers = question.answers.slice(answerIndex + 1);
            question.answers = newFirstPartAnswers.concat(newSecondPartAnswers);
            newQuestions[questionIndex] = question;
            setQuestions(newQuestions);
        }
    }

    const saveQuestions = (withNew: boolean) => {
        if (!validate()) {
            setError("Вы не заполнили название вопроса(ов) и/или не выбрали правильный ответ(ы)");
            return false;
        }
        const request: IUpdateQuestionsRequest = {
            topicId: topicId || topic?.id || "",
            questions: questions
        }
        TestAPI.updateQuestions(request, (response) => {
            if (response.status === 200) {
                setQuestions(response.data.questions);
                let message = "Вопросы сохранены";
                response.data.questions?.map((q : IQuestion) => {
                    if(q.error) message = "Вопросы сохранены. Имеются ошибки";
                })
                refresh();
                if (withNew) {
                    addQuestion();
                } else {
                    setInfoMessage(message);
                }
            } else {
                setError("Ошибка при сохранении вопросов");
            }
        })
        return true;
    }

    const deleteTopic = () => {
        TestAPI.deleteTopic(topicId || topic?.id || "", (response) => {
            if (response.status !== 200) {
                if (response.data.code === "006-001") {
                    setError(response.data.message);
                } else {
                    setError("Ошибка при сохранении темы");
                }
            } else {
                window.location.reload();
            }
        })
    }

    const changeQuestionVisibility = (index: number, isVisible: boolean) => {
        isVisible = isVisible === null ? true : isVisible;
        const newQuestions = [...questions];
        const question = questions[index];
        question.isVisible = !isVisible;
        newQuestions[index] = question;
        setQuestions(newQuestions);
    }

    const resetMessage = () => {
        setInfoMessage("");
        setError("");
    }

    const getImage = (question: IQuestion) => {
        if (question.b64) {
            return URL.createObjectURL(b64toBlob(question.b64.toString(), 'image/jpeg'));
        } else {
            return CONFIG.STORAGE_URL + "/test/" + SessionModel.getCurrentUserAuthData().id + "/" + topicId + "/" + question.image;
        }
    }

    const clearFileForm = (index: number) => {
        // @ts-ignore
        document.getElementById("load-image-test-" + index).value = "";
    }

    const deleteImage = (index: number) => {
        const newQuestions = [...questions];
        const q = questions[index];
        if (q.image || q.b64) {
            if (q.image && q.id) {
                TestAPI.deleteQuestionImage(q.id, (response) => {
                    if (response.status === 200) {
                        q.image = null;
                        q.b64 = null;
                        clearFileForm(index);
                        newQuestions[index] = q;
                        setQuestions(newQuestions);
                    } else {
                        if (response.data.code === "006-001") {
                            setError("Удалить нельзя. Вопрос уже используется участниками");
                        } else {
                            setError("Ошибка удаления изображения");
                        }
                    }
                })
            } else {
                q.image = null;
                q.b64 = null;
                clearFileForm(index);
                newQuestions[index] = q;
                setQuestions(newQuestions);
            }
        }
    }

    const validate = () => {
        let ValidationException = {};
        try {
            questions.forEach((q) => {
                if (!q.name || q.answers.length === 0)
                    throw ValidationException;
                if (q.type !== "INPUT") {
                    let hasAnswer = false;
                    q.answers.forEach((answer) => {
                        if (answer.correct)
                            hasAnswer = true;
                    });
                    if (!hasAnswer)
                        throw ValidationException;
                }
            });
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <>
            {topic?.id ?
            <button className="button-add" onClick={() => saveQuestions(true)}>
                Вопрос
            </button> :
                <></>}
            <ModalMessage msg={infoMessage || error} header={infoMessage ? "Успех" : "Ошибка"} _callback={resetMessage}/>
            <div className="container-right-block">
                <div className="test-theme-block">
                    <div className="test-theme-form">
                        <div className="wrap-answer">
                            <input type="text" className="input-challenges" placeholder="название темы" value={topic?.name} onChange={(e) => setTopic({...topic, name: e.target.value})}/>
                            <a className="del-answer" onClick={deleteTopic}></a>
                        </div>
                        <div className="row-wrapper">
                            <fieldset className="block-input-row">
                                <legend className="legend">Кол-во вопросов</legend>
                                <input type="number" className="input-challenges" placeholder="0" value={topic?.questionNum} onChange={(e) => setTopic({...topic, questionNum: toNumber(e.target.value)})}/>
                                <div className="answer" onMouseOver={() => setQNumHint(!qNumHint)} onMouseOut={() => setQNumHint(!qNumHint)}></div>
                                <p className="answer-text" style={{display: qNumHint ? "" : "none"}}>Укажите, сколько вопросов по данной теме будут выводиться участнику при прохождении тестирования</p>
                            </fieldset>
                            <fieldset className="block-input-row">
                                <legend className="legend">Баллов за вопрос</legend>
                                <input type="number" className="input-challenges" placeholder="0" value={topic?.pointsPerQuestion} onChange={(e) => setTopic({...topic, pointsPerQuestion: toNumber(e.target.value)})}/>
                            </fieldset>
                            <fieldset className="block-input-row">
                                <legend className="legend">Приоритет темы</legend>
                                <input type="number" className="input-challenges" value={topic?.priority && topic.priority > 0 ? topic.priority : undefined} onChange={(e) => setTopic({...topic, priority: toNumber(e.target.value)})}/>
                                <div className="answer" onMouseOver={() => setPriorityHint(!priorityHint)} onMouseOut={() => setPriorityHint(!priorityHint)}></div>
                                <p className="answer-text" style={{display: priorityHint ? "" : "none"}}>Приоритет позволяет выводить темы в тесте по порядку 1, 2, 3 ...</p>
                            </fieldset>
                        </div>
                        <input className="btn-main" type="button" value="Сохранить" onClick={saveTopic}/>
                        {/*<p className="load-image-test-btn">Загрузить вопросы</p>*/}
                    </div>
                </div>

                {topic?.id ?
                    <>
                        {questions?.map((question: IQuestion, questionKey) =>
                            <div className="menu-challenge" key={questionKey} ref={ref}>
                                <div className="row-wrapper">
                                    <h3 className="template-heading">Вопрос {questionKey + 1}</h3>
                                    <a className="delete-stage" onClick={() => deleteQuestion(questionKey)} style={{cursor: "pointer"}}>Удалить вопрос</a>
                                </div>
                                <div className="error-message" style={{display: question.error? "" : "none"}}>Ошибка! {question.error}</div>
                                <fieldset className="test-block-quiestion">
                                    <legend className="test-legend legend">Задание</legend>
                                    <textarea rows={3} className="test-question" placeholder="Введите вопрос" value={question.name} onChange={(e) => changeQuestionName(questionKey, e.target.value)}></textarea>
                                    <input type="file" id={"load-image-test-" + questionKey} className="load-image-test" onChange={(e) => attachQuestionImage(questionKey, e.target.files)} accept=".jpg,.jpeg"/>
                                    {question.b64 || question.image ?
                                        (<div className="img-load-block">
                                            <img src={getImage(question)} alt=""
                                                 className="img-load"/>
                                                <img src="/images/delete_red.svg" alt="" className="img-load-del" style={{cursor: "pointer"}} onClick={() => deleteImage(questionKey)}/>
                                        </div>)
                                        :
                                        (<label htmlFor={"load-image-test-" + questionKey} className="load-image-test-block" style={{cursor: "pointer"}}>
                                        <p className="load-image-test-btn">Изображение</p>
                                        </label>)
                                    }
                                </fieldset>
                                <fieldset className="block-input">
                                    <legend className="legend">Тип вопроса</legend>
                                    <select name="question-type" id="" className="input-challenges" value={question.type} onChange={(e) => changeQuestionType(questionKey, e.target.value)}>
                                        <option value="SINGLE">Одиночный выбор</option>
                                        <option value="MULTIPLE" className="input-challenges">Множественный выбор</option>
                                        <option value="INPUT" className="input-challenges">Эталонное значение</option>
                                    </select>
                                </fieldset>
                                <fieldset className="block-input">
                                    <legend className="legend">Не показывать</legend>
                                    <label className="input-challenges-flex">
                                        <input type="checkbox" name="status" checked={question.isVisible === null ? false : !question.isVisible} onChange={(e) => changeQuestionVisibility(questionKey, question.isVisible)} className="radio-btn"/>
                                        <span className="span-radio">Не отображать вопрос у участников в тестах</span>
                                    </label>
                                </fieldset>
                                <fieldset className="block-input">
                                    <legend className="legend">Ответы</legend>
                                    {question.answers?.map((answer: IAnswer, key) =>
                                        <div className="wrap-answer" key={key}>
                                            <input type="text" value={answer.name} onChange={(e) => changeAnswerName(questionKey, key, e.target.value)} className="input-challenges" placeholder="Введите ответ"/>
                                            {question.type === "INPUT" ? <></> : <div className={answer.correct ? "ok-answer-checked" : "ok-answer"} onClick={() => changeAnswerCorrect(questionKey, key)}/>}
                                            <div className="del-answer" onClick={() => deleteAnswer(questionKey, key)}/>
                                        </div>
                                    )}
                                </fieldset>
                                <input className="btn-blue" type="submit" value="Добавить ответ" onClick={() => addAnswer(questionKey)}/>
                            </div>
                        )}
                    <input className="btn-main" type="button" value="Сохранить" onClick={() => saveQuestions(false)} style={{display: questions.length > 0 ? "" : "none"}}/>
                    </>
                    :
                    <></>}

            </div>
        </>
    )
}