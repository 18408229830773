import React from "react";
import {IDashboardItem} from "../types/ctf/IDashboardItem";

export const CTFOutsiderRating:React.FC<{data: IDashboardItem[]}> = ({data}) => {

    if (data.length > 10) {

        return (
            <>
                <h2 className="headline-right-block">
                    Рейтинг: 11 — 100
                </h2>
                <ul className="dash-rating">
                    {
                        data.map((d, index) => {
                                return index > 9 ?
                                    <li className="dash-rating-item" key={index}>
                                        <p className="dash-rating-position">
                                            <span className="color-dash all-people"></span>
                                            {index}
                                        </p>
                                        <p className="dash-comand-name">
                                            {d.name}
                                        </p>
                                        <p className="dash-rating-num">
                                            {d.totalPoints}
                                        </p>
                                    </li>
                                    :
                                    <React.Fragment key={index}></React.Fragment>
                            }
                        )
                    }
                </ul>
            </>
        )
    } else {
        return (<></>)
    }
}