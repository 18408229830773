import React, {useEffect, useState} from "react";
import {IProfileUniversityForm} from "../../types/IProfileUniversityForm";
import API from "../../../requests/_axios";
import {ProfileUniversityDetailsEditor} from "./ProfileUniversityDetailsEditor";
import {IProfileBusinessForm} from "../../types/IProfileBusinessForm";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import {ProfileBusinessDetailsEditor} from "./ProfileBusinessDetailsEditor";

export const ProfileUniversityView: React.FC<{
  currentForm: IProfileUniversityForm | IProfileBusinessForm | undefined,
  userId: number | null}>
    = ({currentForm, userId}) => {

  const [formType, setFormType] = useState("");

  useEffect(() => {
    if(userId) {
      ChallengeAPI.getProfileById("university", userId, (response) => {
        if (response.status === 200) {
          setFormType("university");
        } else {
          setFormType("business");
        }
      })
    }
  }, [userId]);

  return(
      <>
          <div className="ur-profile-form" id="edit-widget">
            {formType === "university" ? <ProfileUniversityDetailsEditor currentForm={undefined} userId={userId}/> : <ProfileBusinessDetailsEditor currentForm={undefined} userId={userId}/>}
          </div>
      </>
  )
}