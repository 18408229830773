import React from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";

export const InfoButtonRedisign:React.FC<{stage: IChallengeStage, showStage: (stageId: number | undefined) => void}> = ({stage, showStage}) => {

    if (new Date(stage.startDate + "+03:00" || "") > new Date()) {
        return (
            <div className="stage-card-new-future">
                <div className="stage-card-new-info">
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Информация</h5>
                        <p className="stage-card-date">
                            Скоро будет доступен
                        </p>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading">
                            {stage.name}
                        </p>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
            </div>
        )
    } else {
        return (
            <div className="stage-card-new-work">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Информация</h5>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
            </div>
        )
    }

}