import React, {useEffect, useState} from "react";

export const ModalMessage: React.FC<{msg: string, header: string, _callback: () => void}> = ({msg, header, _callback}) => {

    const [message, setMessage] = useState("");
    const [title, setTitle] = useState("");
    useEffect(() => {
        setMessage(msg);
        setTitle(header);
    }, [msg]);

    const closeMessage = () => {
        setMessage("");
        setTitle("");
        _callback();
    }

    if (message && message !== "") {
        return (
            <div className="modal-message">
                <div className="modal-block-message">
                    <div className="modal-header">
                        <h5 className="modal-heading">{title}</h5>
                        <div className="modal-close" onClick={closeMessage}></div>
                    </div>
                    <p className="modal-message-text" dangerouslySetInnerHTML={{__html: message || ""}}></p>
                </div>
            </div>)
    } else {
       return (<></>)
    }
}