import React, {useEffect, useState} from "react";
import API from "../requests/_axios";
import {useNavigate} from "react-router-dom";
import {MainPageHelp} from "./profile/common/MainPageHelp";
import {Spinner} from "./profile/common/Spinner";

interface Props {
  type: string;
}

export const Signup: React.FC<Props> = ({type}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [signupInProgress, setSignupInProgress] = useState(false);
  const [mailing, setMailing] = useState(false);
  const [politics, setPolitics] = useState(false);

  let navigate = useNavigate();

  const PASSWD_PATTERN = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/

  useEffect(() => {
    API.post("/sso/api/v1/user").then((response) => {
      if (response.status === 200)
        navigate("/profile");
    })
  }, [])

  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handleRepassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepassword(event.target.value);
  }

  const handleMailing = () => {
    setMailing(!mailing);
  }

  const handlePolitics = () => {
    setPolitics(!politics);
  }

  const handleSignup = () => {
    if (password.length < 8) {
      alert("Пароль должен быть не короче 8 символов. При создании пароля рекомендуем использовать латинские строчные и заглавные буквы, цифры и специальные символы");
      return;
    }
    if(password !== repassword) {
      alert("Пароли не совпадают");
      return;
    }
    if (!politics || !mailing) {
      alert("Вы должны согласиться с правилами и политикой конфиденциальности");
      return;
    }
    setSignupInProgress(true);
    API.post<any>('/sso/api/v1/signup', {username: username, password: password, type: type, mailing: mailing, personalDataPolitics: politics}).then((response) => {
      if(response.status === 200) {
        alert("На вашу почту отправлена ссылка для подтверждения регистрации");
        navigate('/');
      } else {
        if(response.data.code === "001-007")
          alert("Неправильный E-mail");
        else if(response.data.code === "001-000")
          alert("Пользователь с таким E-mail уже зарегистрирован");
        else if(response.data.code === "001-009")
          alert("Такой логин уже существует");
        // @ts-ignore
        setSignupInProgress(false);
      }
    });
  }

  return (
      <div className="reg-background">
        <MainPageHelp/>
        <a href="https://braim.org" target="_blank">
          <img src={require("../../public/images/logo_white.svg").default} alt="" className="logo-reg"/>
        </a>
        <video autoPlay muted loop playsInline className="video-reg">
          <source src={require("../../public/images/background.mp4").default}/>
        </video>
          <div className="container-registration">
            <div className="registration">
              {type === "EDU" ? (
                  <h3 className="heading-registration">
                    Регистрация образовательных организаций
                  </h3>
              ) :
              type === "BSN" ? (
                  <h3 className="heading-registration">
                    Регистрация компании/НКО
                  </h3>
              ) : (
                  <h3 className="heading-registration">
                    Регистрация участников
                  </h3>
                )}
              <input type="email" className="input-standart" value={username} onChange={handleUsername} placeholder="E-mail"/>
              <input type="password" className="input-standart" value={password} onChange={handlePassword} placeholder="Пароль"/>
              <input type="password" className="input-standart" value={repassword} onChange={handleRepassword} placeholder="Повторите пароль"/>
              <label className="label-checkbox-radio">
                <input type="checkbox" name="email-ads" checked={mailing} onChange={handleMailing} className="checkbox-radio"/>
                <p>Соглашаюсь с <a href="/storage/terms_braim.pdf" target="_blank" className="active">правилами использования платформы Braim</a></p>
              </label>
              <label className="label-checkbox-radio">
                <input type="checkbox" name="email-ads" checked={politics} onChange={handlePolitics} className="checkbox-radio"/>
                  <p>Соглашаюсь с <a href="/storage/privacy_policy.pdf" target="_blank" className="active">политикой конфиденциальности Braim</a></p>
              </label>
              <button className="btn-main" id="signup-btn" onClick={handleSignup} disabled={signupInProgress}>{signupInProgress ? "Подождите..." : "Регистрация"}</button>
              <a href="/signin" className="code-error">Я уже зарегистрирован</a> <a href="/password/reset" className="code-error">Забыли пароль?</a> <a href="/link/reset" className="code-error">Не пришла ссылка?</a>
              {type === "EDU" ? (
                  <>
                    <a href="/personal/signup" className="code-error">Зарегистрироваться как участник</a>
                    <a href="/bsn/signup" className="code-error">Зарегистрировать компанию/НКО</a>
                  </>
              ) :
              type === "BSN" ? (
                  <>
                    <a href="/personal/signup" className="code-error">Зарегистрироваться как участник</a>
                    <a href="/edu/signup" className="code-error">Зарегистрировать образовательную организацию</a>
                  </>
              ) : (
                  <>
                    <a href="/edu/signup" className="code-error">Зарегистрировать образовательную организацию</a>
                    <a href="/bsn/signup" className="code-error">Зарегистрировать компанию/НКО</a>
                  </>
              )}
            </div>
          </div>
      </div>
  )
}