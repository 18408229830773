import React, {useEffect} from "react";
import SessionModel from "../../../stores/SessionModel";

export const ProfileAdminHeader = () => {

  useEffect(() => {
      if (!SessionModel.getCurrentUserAuthData().roles.includes("ROLE_ADMIN") && !SessionModel.isModerator()) {
          window.location.href = "/404";
      }
  }, []);

  const showMenu = () => {
    // @ts-ignore
    document.querySelector(".menu").style.display = 'flex';
  }

  return (
      <header className="header">
        <div id="burger" className="burger" onClick={showMenu}>
          <span className="first-line"></span>
          <span className="two-line"></span>
          <span className="three-line"></span>
        </div>
        <img src={require("../../../../public/images/logo_plat.svg").default} alt="" className="header-logo"/>
        <div className="username">
          <img src="/images/no_ava_university.svg" alt="" className="header-avatar"/>
          <p className="fio-or-name">{SessionModel.isModerator() ? "Moderator" : "Admin"}</p>
        </div>
      </header>
  )
}