import React, {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import API from "../../../../../requests/_axios";

export const BottomNav: React.FC<{isTeam: boolean, styleName: string}> = ({isTeam, styleName}) => {

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [inStat, setInStat] = useState(0);
  const [outStat, setOutStat] = useState(0);

  useEffect(() => {
    fetchStat();
    const interval = setInterval(() => {
      fetchStat();
    }, 30000);
  }, []);

  const fetchStat = () => {
    API.get("/challenge/api/v1/challenge/" + params.id + "/ad/my/stat").then((response) => {
      if(response.status === 200) {
        setInStat(response.data.in);
        setOutStat(response.data.out);
      }
    });
  }

  return (
      <nav className={styleName}>
        <ul className="nav-notification-list">
          <li className={!searchParams.get("tab") || searchParams.get("tab") === "all" ? "nav-notification-item-active" : "nav-notification-item"}>
            <a href={"/challenge/" + params.id + "/my/recruitment?tab=all"} className="notification-link">
              Все
            </a>
          </li>
          <li className={searchParams.get("tab") === "out" ? "nav-notification-item-active" : "nav-notification-item"}>
            <a href={"/challenge/" + params.id + "/my/recruitment?tab=out"} className="notification-link">
              Исходящие
            </a>
            <p className="notification-notification" style={{display: outStat === 0 ? "none" : ""}}>
              {outStat}
            </p>
          </li>
          <li className={searchParams.get("tab") === "in" ? "nav-notification-item-active" : "nav-notification-item"}>
            <a href={"/challenge/" + params.id + "/my/recruitment?tab=in"} className="notification-link">
              Входящие
            </a>
            <p className="notification-notification" style={{display: inStat === 0 ? "none" : ""}}>
              {inStat}
            </p>
          </li>
        </ul>
      </nav>
  )
}