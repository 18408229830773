import React from "react";
import {IChallenge} from "../../../types/IChallenge";
import {JuryStages} from "./JuryStages";
import {useSearchParams} from "react-router-dom";
import {JuryStage} from "./JuryStage";

export const Jury:React.FC<{challenge: IChallenge}> = ({challenge}) => {

    const [searchParams, setSearchParams] = useSearchParams();

    if (searchParams.get("stageId")) {
        return (<JuryStage challenge={challenge}/>)
    } else {
        return (<JuryStages challenge={challenge}/>)
    }
}