import API from "./_axios";

export function getBill(id: string, _callback: (response: any) => void) {
    API.get(`/payment/public/api/v1/bill/${id}`).then((response) => {
        _callback(response);
    });
}

export function getBillTransactions(_callback: (response: any) => void) {
    API.get(`/payment/public/api/v1/bill/list`).then((response) => {
        _callback(response);
    });
}

export function uploadBills(formData: FormData, _callback: (response: any) => void) {
    API.post(`/payment/public/api/v1/bill/load`, formData).then((response) => {
        _callback(response);
    })
}