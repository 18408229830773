import React, {useEffect, useState} from "react";
import {IChallengeCertificate} from "../../../types/IChallengeCertificate";
import {CONFIG} from "../../../../CONFIG";
import * as CertificateAPI from "../../../../requests/CertificateAPI";
import {formatDate} from "../../../helpers/DateHelper";


export const CertificatePreview: React.FC<{certificateId: string | undefined, closePreview: () => void}> = ({certificateId, closePreview}) => {

    useEffect(() => {
        if (certificateId) {
            CertificateAPI.getChallengeCertificatePreview(certificateId, (response) => {
                if (response.status === 200)
                    setCertificate(response.data);
            });
        } else {
            setCertificate(undefined);
        }
    }, [certificateId]);

    const getDocName = () => {
        if (certificate?.type === "CHARTER")
            return "Грамота";
        else if (certificate?.type === "DIPLOMA")
            return "Диплом";
        else
            return "Сертификат";
    }


    const [certificate, setCertificate] = useState<IChallengeCertificate>();

    if (certificate) {
        return (
            <div className="cert-body">
                <div className="close-window" onClick={closePreview} style={{cursor: "pointer"}}></div>
                <div className="container-cert">
                    <div className="cert-top-block">
                        <div className="qr-cert-info">
                            <img src={"data:image/png;base64," + certificate.qr} alt="" className="qr-cert"/>
                            <p className="cert-number">
                                {formatDate(new Date().toDateString())}
                            </p>
                        </div>
                        <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.background}`} alt="" className="cert-image-main"/>
                        <h6 className="cert-sheet-heading">
                            {getDocName()}
                        </h6>
                        {/* @ts-ignore */}
                        <h7 className="cert-challenge-name">
                            {certificate.challengeName}
                        {/* @ts-ignore */}
                        </h7>
                        <div className="cert-top-block-info">
                            <p className="cert-text-info">
                                подтверждает, что
                            </p>
                            <p className="cert-top-block-name">
                                Иванов Иван Иванович
                            </p>
                            <p className="cert-text-bottom">
                                {certificate.body}
                            </p>
                        </div>
                    </div>
                    <div className="cert-bottom-block">
                        <div className="podpisant-info">
                            <p className="cert-text-signatory">
                                {certificate.signedBy}
                            </p>
                            <p className="cert-text-jobtitle">
                                {certificate.signedByPosition}
                            </p>
                        </div>
                        <div className="about-braim">
                            <div className="logo-braim-cert">
                                <img src="/images/logo_plat.svg" alt=""/>
                                    <p className="about-braim-text">
                                        Создано на braim.org
                                    </p>
                            </div>
                            <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.logo}`} alt="" className="logo-cert"/>
                        </div>
                        <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.signature}`} alt="" className="podpis-full"/>
                        <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.stamp}`} alt="" className="pechat"/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}