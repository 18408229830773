import React, {useEffect, useState} from "react";
import {ModalMessage} from "../../../common/ModalMessage";
import * as TestAPI from "../../../../../requests/TestAPI";
import {ITest} from "../../../../types/ITest";
import {toNumber} from "lodash";

export const TestSettings: React.FC<{testId: string}> = ({testId}) => {

    const [error, setError] = useState("");
    const [test, setTest] = useState<ITest>();
    const [skillHint, setSkillHint] = useState(false);

    useEffect(() => {
        if (testId) {
            TestAPI.fetchTestData(testId, (response) => {
                if (response.status !== 200)
                    setError("Все поля являются обязательными");
                else
                    setTest(response.data);
            })
        }
    }, [testId]);

    const submit = () => {
        if (test) {
            if (test.id) {
                TestAPI.updateTest(test, (response) => {
                    if (response.status === 200)
                        window.location.reload();
                    else
                        setError("Все поля являются обязательными");
                });
            } else {
                TestAPI.createTest(test, (response) => {
                    if (response.status === 200)
                        window.location.reload();
                    else
                        setError("Все поля являются обязательными");
                });
            }
        }
    }

    return (
        <>
            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="test-theme-block">
                    <fieldset className="block-input">
                        <legend className="legend">Название теста*</legend>
                        <input type="text" className="input-challenges" placeholder="Введите текст" value={test?.name} onChange={(e) => setTest({...test, name: e.target.value})}/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Время на тестирование (в минутах)*</legend>
                        <input type="number" className="input-challenges" placeholder="В минутах" value={test?.time} onChange={(e) => setTest({...test, time: toNumber(e.target.value)})}/>
                        {/*<input type="time" className="input-challenges without_ampm" placeholder="0" value={getTime()}/>*/}
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Навыки для прохождения (укажите через запятую)*</legend>
                        <input type="text" className="input-challenges" placeholder="Начните вводить" value={test?.tags} onChange={(e) => setTest({...test, tags: e.target.value})}/>
                        <div className="answer" onMouseOver={() => setSkillHint(!skillHint)} onMouseOut={() => setSkillHint(!skillHint)}></div>
                        <p className="answer-text" style={{display: skillHint ? "" : "none"}}>Укажите навыки, которые требуются участникам для прохождения теста</p>
                    </fieldset>
                    {/*<fieldset className="row-wrapper-fieldset">
                        <legend className="legend">Оценка тестирования</legend>
                        <input type="button" value="Командная" onClick={() => setTest({...test, assessment: "TEAM"})} className={test?.assessment === "TEAM" ? "btn test-btn-change-active" : "btn test-btn-change"}/>
                        <input type="button" value="Индивидуальная" onClick={() => setTest({...test, assessment: "INDI"})} className={test?.assessment === "INDI" ? "btn test-btn-change-active" : "btn test-btn-change"}/>
                    </fieldset>*/}
                    <input className="btn-main" type="button" value="Сохранить" onClick={submit}/>
                </div>
            </div>
        </>
    )
}