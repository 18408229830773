import React, {useEffect, useState} from "react";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import * as CertificateAPI from "../../../../../requests/CertificateAPI";
import {IChallengeCertificateItem} from "../../../../types/IChallengeCertificateItem";
import {IChallengeCertificate} from "../../../../types/IChallengeCertificate";

export const UserCertificatesList: React.FC<{challengeId: number | null, type: string | null}> = ({challengeId, type}) => {

    const [certificateList, setCertificateList] = useState<IChallengeCertificate[]>([])

    useEffect(() => {
        if (challengeId && type) {
            ChallengeAPI.fetchCertificateByChallenge(type, challengeId, (response) => {
                let ids : string[] = [];
                response.data.forEach((certificate: IChallengeCertificateItem) => {
                    ids.push(certificate.id);
                });
                CertificateAPI.getChallengeCertificateViews(ids, (response) => {
                    setCertificateList(response.data);
                });
            });
        }
    }, [challengeId]);

    if (challengeId !== null && type !== null) {
        return (
            <div className="container-right-block">
                <div className="menu-challenge">
                    {certificateList.length === 0 ? "Подождите..." : <></>}
                    <h2 className="headline-right-block">{certificateList.length > 0 ? certificateList[0].challengeName : ""}</h2>
                    <ul className="challenge-info">
                        {certificateList && certificateList.map((certificate) =>
                            <li className="challenge-card" key={certificate.id}>
                                <p className="challenge-header">
                                    {certificate.name}
                                </p>
                                <p className="challenge-description">
                                    {certificate.body}
                                </p>
                                <div className="flex-btn-stage">
                                    <a>
                                        &nbsp;
                                    </a>
                                    <a href={`https://certificates.braim.org/challenge/${certificate.userCertificateId}`} target="_blank" className="challenge-landing">
                                        Просмотр
                                    </a>
                                </div>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        )
    } else {
        return <></>
    }
}