import {TDefaultResponse} from "./_axios";
//import ErrorUtils, { ProjectError } from "@utils/ErrorUtils";
import {AxiosResponse} from "axios";
import SessionModel from "../stores/SessionModel";

export function createInterceptorResponse() {

    return function interceptorResponse(
        response: AxiosResponse<TDefaultResponse<any>>
    ) {
        if (response.status === (999 || 999)) {
            console.log("ERROR IN AXIOSINTERCEPT");
            // ErrorUtils.handleError(
            //     new ProjectError({
            //         statusCode: response.status,
            //         message: response.data.data.message,
            //         pid: response.data.pid,
            //     })
            // );
            return;
        }
        if (response.status === 401) {
            localStorage.removeItem("token");
            //window.location.href="/signin";
        }
        return response;
    };
}

export function createInterceptorBeforeRequest(token: string | null) {
    return function interceptorBeforeRequest(request: any) {
        /*if (token) {
            request.headers["Authorization"] = `Bearer ${token}`;
        }*/
        if (SessionModel.getToken()) {
            request.headers["Authorization"] = `Bearer ${SessionModel.getToken()}`;
        }

        return request;
    };
}
