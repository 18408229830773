import React, {useEffect, useState} from "react";
import {IChallengeCertificateDto, IChallengeCertificateRuleDto} from "../../../../types/IChallengeCertificateDto";
import * as CertificateAPI from "../../../../../requests/CertificateAPI";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {IChallenge} from "../../../../types/IChallenge";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {toNumber} from "lodash";
import {getBase64} from "../../../../helpers/Util";
import {CONFIG} from "../../../../../CONFIG";
import SessionModel from "../../../../../stores/SessionModel";
import {ModalMessage} from "../../ModalMessage";

export const CertificateSettings: React.FC<{certificateId: string}> = ({certificateId}) => {

    const [certificate, setCertificate] = useState<IChallengeCertificateDto>();
    const [challenge, setChallenge] = useState<IChallenge>();
    const [challengeList, setChallengeList] = useState([]);
    const [challengeName, setChallengeName] = useState("");
    const [error, setError] = useState("");
    const [backgroundPreview, setBackgroundPreview] = useState("");
    const [logoPreview, setLogoPreview] = useState("");
    const [stampPreview, setStampPreview] = useState("");
    const [signPreview, setSignPreview] = useState("");
    const [rule, setRule] = useState<IChallengeCertificateRuleDto>();
    const [placeFilter, setPlaceFilter] = useState(false);
    const [percentFilter, setPercentFilter] = useState(false);
    const [currentFilter, setCurrentFilter] = useState("none");
    const [ruleHint, setRuleHint] = useState(false);

    useEffect(() => {
        if (!"DIPLOMA CERT CHARTER".includes(certificateId) && certificateId !== null) {
            CertificateAPI.getChallengeCertificate(certificateId, true,(response) => {
               if (response.status === 200) {
                   setCertificate(response.data);
                   if (response.data.rule) {
                       initFilter(response.data);
                   }
                   if (response.data.logo) {
                       setLogoPreview(`${CONFIG.STORAGE_URL}/certificate/${response.data.userId}/challenge/${response.data.challengeId}/certificate/${response.data.logo}`);
                   }
                   if (response.data.stamp) {
                       setStampPreview(`${CONFIG.STORAGE_URL}/certificate/${response.data.userId}/challenge/${response.data.challengeId}/certificate/${response.data.stamp}`);
                   }
                   if (response.data.background) {
                       setBackgroundPreview(`${CONFIG.STORAGE_URL}/certificate/${response.data.userId}/challenge/${response.data.challengeId}/certificate/${response.data.background}`);
                   }
                   if (response.data.signature) {
                       setSignPreview(`${CONFIG.STORAGE_URL}/certificate/${response.data.userId}/challenge/${response.data.challengeId}/certificate/${response.data.signature}`);
                   }
                   if (response.data.rule && response.data.rule.placeFrom && response.data.rule.placeTo) {
                       setPlaceFilter(true);
                   }
                   if (response.data.rule && response.data.rule.percentFrom && response.data.rule.percentTo) {
                       setPercentFilter(true);
                   }
                   ChallengeAPI.getChallengeById(response.data.challengeId, (response) => {
                       if (response.status === 200) {
                           setChallengeName(response.data.name);
                           setChallenge(response.data);
                       }
                   })
               } else {
                   setError("Ошибка при загрузке сертификата");
               }
            });
        } else {
            setCertificate({...certificate, type: certificateId, sendByEmail: true, rule: rule})
        }
    }, [certificateId]);

    const updateChallengeList = (name: string) => {
        setError("");
        setChallengeName(name);
        ChallengeAPI.fetchChallenges(name, "all", SessionModel.getCurrentUserAuthData().id, (response) => {
            if (response.status === 200) {
                setChallengeList(response.data);
            } else {
                setError("Не удалось загрузить список мероприятий");
            }
        })
    }

    const setCertificateChallenge = (challenge: IChallenge) => {
        setChallenge(challenge);
        setChallengeName(challenge.name);
        setCertificate({...certificate, challengeId: challenge.id, stageIds: []})
        setChallengeList([]);
    }

    const getCertificatesStageValue = () => {
        if (certificate && certificate.stageIds && certificate.stageIds.length > 1) {
            return 0;
        } else if (certificate && certificate.stageIds && certificate.stageIds?.length === 1) {
            return certificate.stageIds.map((id: number | undefined) => id)[0];

        }
    }

    const setCertificateStages = (id: number) => {
        if (id === 0) {
            setCertificate({...certificate, stageIds: challenge?.stages?.map((stage: IChallengeStage) => stage.id)})
        } else {
            setCertificate({...certificate, stageIds: [id]})
        }
    }

    const handleLogoChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const fileList = e.target.files;
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер изображения не должен превышать 5Мб.");
            return;
        }
        getBase64(fileList[0], ((b64) => {
            setCertificate({...certificate, logoB64: b64});
        }));
        setLogoPreview(URL.createObjectURL(fileList[0]));
    };

    const handleStampChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const fileList = e.target.files;
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер изображения не должен превышать 5Мб.");
            return;
        }
        getBase64(fileList[0], ((b64) => {
            setCertificate({...certificate, stampB64: b64});
        }));
        setStampPreview(URL.createObjectURL(fileList[0]));
    };

    const handleBackgroundChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const fileList = e.target.files;
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер изображения не должен превышать 5Мб.");
            return;
        }
        getBase64(fileList[0], ((b64) => {
            setCertificate({...certificate, backgroundB64: b64});
        }));
        setBackgroundPreview(URL.createObjectURL(fileList[0]));
    };

    const handleSignChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const fileList = e.target.files;
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер изображения не должен превышать 5Мб.");
            return;
        }
        getBase64(fileList[0], ((b64) => {
            setCertificate({...certificate, signB64: b64});
        }));
        setSignPreview(URL.createObjectURL(fileList[0]));
    };

    const saveCertificate = () => {
        setError("");
        if (!validate())
            return;
        if (certificate) {
            if (certificate.id) {
                CertificateAPI.update(certificate, (response) => {
                    if (response.status !== 200)
                        setError("Ошибка при обновлении сертификата");
                    else
                        window.location.reload();
                })
            } else {
                CertificateAPI.createCertificate(certificate, (response) => {
                    if (response.status !== 200)
                        setError("Ошибка при сохранении сертификата");
                    else
                        window.location.replace("/certificates?filter=draft");
                })
            }
        }
    }

    const validate = () => {
        if (certificate?.rule?.percentFrom && (certificate.rule.percentFrom < 0 || certificate.rule.percentFrom === 0 || certificate.rule.percentFrom > 100)) {
            setError("Некорректно указан фильтр результата (в процентах)");
            return false;
        }
        if (certificate?.rule?.percentTo && (certificate.rule.percentTo < 0 || certificate.rule.percentTo == 0 || certificate.rule.percentTo > 100)) {
            setError("Некорректно указан фильтр результата (в процентах)");
            return false;
        }
        if (certificate?.rule?.placeFrom && certificate.rule.placeFrom < 0) {
            setError("Некорректно указан фильтр места");
            return false;
        }
        if (certificate?.rule?.placeTo && certificate.rule.placeTo < 0) {
            setError("Некорректно указан фильтр места");
            return false;
        }
        return true;
    }

    const getDocName = (type: string | undefined) => {
        if (certificate?.type === "CHARTER")
            return "Грамота";
        else if (certificate?.type === "DIPLOMA")
            return "Диплом";
        else
            return "Сертификат";
    }

    const changeCurrentFilter = (value: string) => {
        let rule: IChallengeCertificateRuleDto = {
            hasResult: null,
            percentFrom: null,
            percentTo: null,
            placeFrom: null,
            placeTo: null,
            passStage: null,
            notPassStage: null
        };
        if (value === 'pass_stage') {
            rule = {...rule, passStage: true}
        } else if (value === 'fail_stage') {
            rule = {...rule, notPassStage: true}
        }
        setCertificate({...certificate, rule: rule});
        setCurrentFilter(value);
    }

    const initFilter = (data: any) => {
        if (data.rule.passStage) {
            setCurrentFilter("pass_stage");
        } else if (data.rule.percentFrom) {
            setCurrentFilter("result")
        } else if (data.rule.placeFrom) {
            setCurrentFilter("place")
        } else {
            setCurrentFilter("none");
        }
    }

    return (
        <>
            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <div className="template">
                        <h3 className="template-heading">{getDocName(certificate ? certificate.type : "")}</h3>
                        <input type="text"
                               onChange={(e) => setCertificate({...certificate, name: e.target.value})}
                               className="input-challenges tamplate-name"
                               value={certificate?.name}
                               placeholder="Название"/>
                        <p className="template-text">
                            Будьте корректны с названием документа, так как после выдачи оно будет отображаться в личных кабинетах участников.
                        </p>
                        <input type="file" className="template-logo" accept=".jpg,.jpeg,.png" id="templogo" onChange={handleLogoChange}/>
                        <div className="flex-btn-template">
                            <label htmlFor="templogo" className="load-logo">
                                {logoPreview ? "Заменить логотип" : "Загрузите логотип"}
                            </label>
                            <p className="about-template"></p>
                            {/*<a href="src/components/profile/common/certificate/right/CertificateSettings" className="template-dowload">Скачать шаблон psd</a>*/}
                        </div>
                    </div>
                    <fieldset className="block-input">
                        <legend className="legend">Мероприятие</legend>
                        <p className="template-text">
                            Начните вводить название мероприятия и выберите его из предложенного списка
                        </p>
                        <input type="text" className="input-challenges city-search"
                               placeholder="Начните вводить" value={challengeName}
                               onChange={(e) => updateChallengeList(e.target.value)}/>
                        <div className="container-city">
                            {challengeList && challengeList.map((challenge: IChallenge) =>
                                <div className="cert-name-input" key={challenge.id} onClick={() => setCertificateChallenge(challenge)}>
                                    {challenge.name}
                                </div>
                            )}
                        </div>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Этап/Задание</legend>
                        <select value={getCertificatesStageValue()} onChange={(e) => setCertificateStages(toNumber(e.target.value))} className="input-challenges-select">
                            <option value={""} key={""}>Выберите из списка</option>
                            {challenge?.stages && challenge.stages.map((stage: IChallengeStage) =>
                                <option value={stage.id} key={stage.id}>{stage.name}</option>
                            )}
                            <option value={0} key={0}>Привязать к каждому</option>
                        </select>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Основной текст</legend>
                        <textarea cols={30} rows={10}
                                  onChange={(e) => setCertificate({...certificate, body: e.target.value})}
                                  value={certificate?.body}
                                  className="input-challenges"
                                  placeholder="Начните вводить"></textarea>
                        <p className="cert-info-bold">Применяте специальные символы</p>
                        <p className="cert-info"><span className="cert-symbol">&stage</span> - название этапа</p>
                        <p className="cert-info"><span className="cert-symbol">&points</span> - процент от макс. балла</p>
                        <p className="cert-info"><span className="cert-symbol">&rating</span> - место</p>
                        <p className="cert-info"><span className="cert-symbol">&project</span> - название проекта</p>
                        <p className="cert-info"><span className="cert-symbol">&team</span> - название команды</p>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">ФИО подписанта</legend>
                        <input type="text" onChange={(e) => setCertificate({...certificate, signedBy: e.target.value})}
                               className="input-challenges"
                               value={certificate?.signedBy}
                               placeholder="Иванов Иван Иванович"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Должность полностью</legend>
                        <input type="text" onChange={(e) => setCertificate({...certificate, signedByPosition: e.target.value})}
                               value={certificate?.signedByPosition}
                               className="input-challenges"
                               placeholder="Председатель правления..."/>
                    </fieldset>
                    <fieldset className="block-input">
                        <input type="file" accept=".jpg,.jpeg,.png" id="stamp" className="load-file-hidden" onChange={handleStampChange}/>
                        <label htmlFor="stamp" className="btn-blue width-btn">{stampPreview ? "Заменить печать" : "Загрузить печать"}</label>
                    </fieldset>
                    <fieldset className="block-input">
                        <input type="file" accept=".jpg,.jpeg,.png" id="sign" className="load-file-hidden" onChange={handleSignChange}/>
                        <label htmlFor="sign" className="btn-blue width-btn">{signPreview ? "Заменить подпись" : "Загрузить подпись"}</label>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Фоновое изображение</legend>
                        <input type="file" accept=".jpg,.jpeg,.png" id="certimg" className="cert-image" onChange={handleBackgroundChange}/>
                        <div className="cert-img-label">
                            {backgroundPreview ?
                                (<label htmlFor="certimg" className="cert-link">заменить</label>) :
                                (<><p className="cert-img-info">Размер изображения в пикселях
                                    составляет 2480 X 3508
                                </p><label htmlFor="certimg" className="cert-link">загрузить</label></>)
                            }
                            <img src={backgroundPreview} alt=""
                                 className="background-cert"/>
                            <div className="cert-transparent"></div>

                        </div>
                    </fieldset>
                    <fieldset className="block-input-flex-column">
                        <legend className="legend">Привязка</legend>
                        <label className="input-challenges-flex">
                            <input type="checkbox" onChange={() => setCertificate({...certificate, sendByEmail: !certificate?.sendByEmail})} className="radio-btn" checked={certificate?.sendByEmail}/>
                            <span className="span-radio">Отправлять по e-mail</span>
                        </label>
                    </fieldset>

                    {/* Rules new */}
                    <div className="block-rules-cert">
                        <fieldset className="block-input">
                            <legend className="legend">Правила</legend>
                            <p className="template-text">
                                Укажите критерии, кому будет выдан сертификат
                            </p>
                            <select name="question-type" className="input-challenges" onChange={(e) => changeCurrentFilter(e.target.value)} value={currentFilter}>
                                <option value="none">Не применять</option>
                                <option value="pass_stage" className="input-challenges">Тем кто
                                    прошел в следующий этап
                                </option>
                                {/*<option value="fail_stage" className="input-challenges">Тем, кто не
                                    прошел этап
                                </option>*/}
                                <option value="result" className="input-challenges">Выдать по
                                    результату
                                </option>
                                <option value="place" className="input-challenges">Выдать по
                                    месту
                                </option>
                            </select>
                            <div className="answer" onMouseOver={() => setRuleHint(!ruleHint)} onMouseOut={() => setRuleHint(!ruleHint)}></div>
                            <p className="answer-text" style={{display: ruleHint ? "" : "none"}}>Если правило не задано, сертификат будет выдаваться каждому участнику, с ненулевым результатом</p>
                        </fieldset>
                        <div className="criteria" style={{display: currentFilter === "result" ? "" : "none"}}>
                            <fieldset className="block-input">
                                <legend className="legend">По результатам</legend>
                                <p className="template-text">
                                    Укажите результаты участников в процентах, кому будет выдан
                                    сертификат
                                </p>
                                <div className="result-block">
                                    <p className="result-block-text">от</p>
                                    <input type="number" className="input-challenges" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, percentFrom: toNumber(e.target.value)}})} value={certificate?.rule?.percentFrom || 0} min={0} max={100}/>
                                    <p className="result-block-text">до</p>
                                    <input type="number" className="input-challenges" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, percentTo: toNumber(e.target.value)}})} value={certificate?.rule?.percentTo || 0} min={0} max={100}/>
                                </div>
                            </fieldset>
                        </div>
                        <div className="criteria" style={{display: currentFilter === "place" ? "" : "none"}}>
                            <fieldset className="block-input">
                                <legend className="legend">По месту</legend>
                                <p className="template-text">
                                    Укажите места участников, кому будет выдан
                                    сертификат
                                </p>
                                <div className="result-block">
                                    <p className="result-block-text">от</p>
                                    <input type="number" className="input-challenges" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, placeFrom: toNumber(e.target.value)}})} value={certificate?.rule?.placeFrom || 0} min={0}/>
                                    <p className="result-block-text">до</p>
                                    <input type="number" className="input-challenges" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, placeTo: toNumber(e.target.value)}})} value={certificate?.rule?.placeTo || 0} min={0}/>
                                </div>
                            </fieldset>
                        </div>

                    </div>




                    {/* Rules */}
                    {/*<fieldset className="block-input-flex-column">*/}
                    {/*    <legend className="legend">Правила</legend>*/}
                    {/*    <label className="input-challenges-flex">*/}
                    {/*        <input type="checkbox" onChange={() => setCertificate({...certificate, rule: {...certificate?.rule, hasResult: !certificate?.rule?.hasResult}})} className="radio-btn" checked={certificate?.rule?.hasResult || undefined}/>*/}
                    {/*        <span className="span-radio">Выдавать всем, кто отправил работу/прошел тест <p style={{color: "red"}}>В настоящий момент не работает (и вероятно излишне, надо обсудить)</p> </span>*/}
                    {/*    </label>*/}
                    {/*    <label className="input-challenges-flex">*/}
                    {/*        <label className="input-challenges-flex">*/}
                    {/*            <input type="checkbox" className="radio-btn" onChange={togglePercentFilter} checked={percentFilter}/>*/}
                    {/*            <span className="span-radio">Результат</span>*/}
                    {/*        </label>*/}
                    {/*        <label className="input-challenges-flex">*/}
                    {/*            <input type="number" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, percentFrom: toNumber(e.target.value)}})} className="radio-btn" value={certificate?.rule?.percentFrom || 0} disabled={!percentFilter} min={0} max={100}/>*/}
                    {/*            <span className="span-radio">Результат в процентах с</span>*/}
                    {/*        </label>*/}
                    {/*        <label className="input-challenges-flex">*/}
                    {/*            <input type="number" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, percentTo: toNumber(e.target.value)}})} className="radio-btn" value={certificate?.rule?.percentTo || 0} disabled={!percentFilter} min={0} max={100}/>*/}
                    {/*            <span className="span-radio">Результат в процентах по</span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*    <label className="input-challenges-flex">*/}
                    {/*        <label className="input-challenges-flex">*/}
                    {/*            <input type="checkbox" className="radio-btn" onChange={togglePlaceFilter} checked={placeFilter}/>*/}
                    {/*            <span className="span-radio">Места</span>*/}
                    {/*        </label>*/}
                    {/*        <label className="input-challenges-flex">*/}
                    {/*            <input type="number" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, placeFrom: toNumber(e.target.value)}})} className="radio-btn" value={certificate?.rule?.placeFrom || 0} disabled={!placeFilter} min={0}/>*/}
                    {/*            <span className="span-radio">Место с</span>*/}
                    {/*        </label>*/}
                    {/*        <label className="input-challenges-flex">*/}
                    {/*            <input type="number" onChange={(e) => setCertificate({...certificate, rule: {...certificate?.rule, placeTo: toNumber(e.target.value)}})} className="radio-btn" value={certificate?.rule?.placeTo || 0} disabled={!placeFilter} min={0}/>*/}
                    {/*            <span className="span-radio">Место по</span>*/}
                    {/*        </label>*/}
                    {/*    </label>*/}
                    {/*    <label className="input-challenges-flex">*/}
                    {/*        <input type="checkbox" onChange={() => setCertificate({...certificate, rule: {...certificate?.rule, passStage: !certificate?.rule?.passStage}})} className="radio-btn" checked={certificate?.rule?.passStage || undefined}/>*/}
                    {/*        <span className="span-radio">Выдавать всем, кто прошел этап</span>*/}
                    {/*    </label>*/}
                    {/*    <label className="input-challenges-flex">*/}
                    {/*        <input type="checkbox" onChange={() => setCertificate({...certificate, rule: {...certificate?.rule, notPassStage: !certificate?.rule?.notPassStage}})} className="radio-btn" checked={certificate?.rule?.notPassStage || undefined}/>*/}
                    {/*        <span className="span-radio">Выдавать всем, кто не прошел этап</span>*/}
                    {/*    </label>*/}
                    {/*</fieldset>*/}
                    <input className="btn-main" type="submit" value="Сохранить" onClick={saveCertificate}/>
                </div>
            </div>
        </>
    )
}