import React, {useEffect, useState} from "react";
import {IAssessmentParticipantsListItem} from "../../../../types/assessment/IAssessmentParticipantsListItem";
import {IAssessmentTeamListItem} from "../../../../types/assessment/IAssessmentTeamListItem";
import {CONFIG} from "../../../../../CONFIG";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {toNumber} from "lodash";
import {useParams} from "react-router-dom";
import {ModalMessage} from "../../../common/ModalMessage";

interface IUpdateSolutionRequest {
    solutionId: number,
    position: number | null | undefined
}
export const AssessmentDetailsView:React.FC<{
    close: () => void,
    participantItem: IAssessmentParticipantsListItem | undefined,
    teamItem: IAssessmentTeamListItem | undefined}> = ({close, participantItem, teamItem}) => {

    const params = useParams();
    const [updateRequest, setUpdateRequest] = useState<IUpdateSolutionRequest>();
    const [error, setError] = useState("");

    const updatePosition = () => {
        ChallengeAPI.updateSolutionPosition(toNumber(params.id), toNumber(params.stageId), updateRequest, (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setError("Ошибка сохранения позиции");
            }
        })
    }

    useEffect(() => {
        if (participantItem?.solutionId) {
            setUpdateRequest({solutionId: participantItem.solutionId, position: participantItem.position});
        } else if (teamItem?.team?.solutionId) {
            setUpdateRequest({solutionId: teamItem.team.solutionId, position: teamItem.team.position});
        }
    }, [participantItem, teamItem]);

    if (teamItem) {
        return (
            <div className="right-block">
                <header className="header">
                    <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                    <p className="name-menu">Члены команды</p>
                </header>
                <div className="container-right-block">
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="menu-challenge">
                        <h2 className="headline-right-block">{teamItem.team.projectName}</h2>
                        <ul className="responses-list">
                            <li className="respose-item">
                                <div className="username">
                                    <img src={CONFIG.STORAGE_URL + "/" + teamItem.team.userId + "/team/" + teamItem.team.id + "/avatar/" + teamItem.team.avatar}
                                         onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                         alt="" className="header-avatar"/>
                                    <p className="fio-or-name">{teamItem.team.name}</p>
                                </div>

                                {teamItem.members.map(m =>
                                    <div className="challenge-card">
                                        <div className="challenge-card-mini">
                                            <img src={CONFIG.STORAGE_URL + "/" + m.userId + "/avatar/" + m.avatar} alt=""
                                                 onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                                 className="challenge-avatar"/>
                                            <div className="user-info">
                                                <p className="challenge-header">{`${m.lastname} ${m.firstname}`}</p>
                                                <p className="city">{m.teamRole}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </li>
                            <div className="block-input">
                                <ul className="career-list">
                                    <li className="career-item">
                                        <h4 className="career-heading">
                                            Описание проекта
                                        </h4>
                                        <p className="career-description">
                                            {teamItem.team.projectDescription}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <fieldset className="block-input">
                                <legend className="legend">Сортировка</legend>
                                <input type="number" value={updateRequest?.position || 0} onWheel={(e) => (e.target as HTMLElement).blur()} onChange={(e) => setUpdateRequest({...updateRequest, solutionId: teamItem?.team.solutionId, position: toNumber(e.target.value)})} className="input-challenges" placeholder="Очередность"/>
                            </fieldset>
                            <button className="btn-main" id="submit" onClick={updatePosition}>Сохранить</button>
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else if (participantItem) {
        return (
            <div className="right-block">
                <header className="header">
                    <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                    <p className="name-menu">Участник</p>
                </header>
                <div className="container-right-block">
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="menu-challenge">
                        <h2 className="headline-right-block">Профиль участника</h2>
                        <ul className="responses-list">
                            <li className="respose-item">
                                <div className="username">
                                    <img src={CONFIG.STORAGE_URL + "/" + participantItem?.userId + "/avatar/" + participantItem?.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} alt="" className="header-avatar"/>
                                    <p className="fio-or-name">{(participantItem?.lastname || "Фамилия") + " " + (participantItem?.firstname || "Имя")}</p>
                                    <p className="city-student">{participantItem?.city}</p>
                                </div>

                                <div className="block-input">
                                    <p className="legend-about">
                                        Образование
                                    </p>
                                    <ul className="career-list">
                                        <li className="career-item">
                                            <h4 className="career-heading">
                                                {participantItem?.university}
                                            </h4>
                                        </li>
                                    </ul>
                                </div>

                                <div className="challenge-information">
                                    <p className="message-user">
                                        <a href={"https://t.me/" + participantItem?.telegram} target="_blank">Сообщение</a>
                                    </p>
                                </div>
                            </li>
                            <fieldset className="block-input">
                                <legend className="legend">Сортировка</legend>
                                <input type="number" value={updateRequest?.position || 0} onWheel={(e) => (e.target as HTMLElement).blur()} onChange={(e) => setUpdateRequest({...updateRequest, solutionId: participantItem?.solutionId, position: toNumber(e.target.value)})} className="input-challenges" placeholder="Очередность"/>
                            </fieldset>
                            <button className="btn-main" id="submit" onClick={updatePosition}>Сохранить</button>
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<></>);
    }
}