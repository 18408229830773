import React from "react";
import {IUniversityProfile} from "../../types/IUniversityProfile";
import {IBusinessProfile} from "../../types/IBusinessProfile";

export const UniversityAdminButtons: React.FC<{
    profile: IUniversityProfile | IBusinessProfile,
    approve: (id: number) => void,
    reject: (id: number) => void,
    remove: (id: number) => void}> = ({profile, approve, reject, remove}) => {

    // New tab
    if (!profile.isApproved && !profile.isRejected && !profile.onModeration) {
        return (
            <>
                <a onClick={() => remove(profile.userId)} className="challenge-activity-no">
                    Удалить
                </a>
                <a onClick={() => approve(profile.userId)} className="challenge-activity">
                    Одобрить
                </a>
            </>
        )
    }
    // OnModeration tab
    else if (profile.onModeration && !profile.isApproved && !profile.isRejected) {
        return (
            <>
                <a onClick={() => reject(profile.userId)} className="challenge-activity-no">
                    Отклонить
                </a>
                <a onClick={() => approve(profile.userId)} className="challenge-activity">
                    Одобрить
                </a>
            </>
        )
    }
    // Approved tab
    else if (profile.isApproved) {
        return (
            <>
                <a onClick={() => reject(profile.userId)} className="challenge-activity-no">
                    Отклонить
                </a>
            </>
        )
    }
    // Rejected tab
    else if (profile.isRejected) {
        return (
            <>
                <a onClick={() => reject(profile.userId)} className="challenge-activity">
                    На модерацию
                </a>
                <a onClick={() => remove(profile.userId)} className="challenge-activity-no">
                    Удалить
                </a>
            </>
        )
    } else {
        return <></>
    }
}