import React from "react";
import {IDashboardItem} from "../types/ctf/IDashboardItem";
import {CONFIG} from "../../CONFIG";
import plural from "plural-ru";

export const CTFDetailModal:React.FC<{
    item?: IDashboardItem,
    flagIndex?: number,
    setItem: (flagIndex: number | undefined, item: IDashboardItem | undefined) => void
}> = ({item, flagIndex, setItem}) => {

    if (item && flagIndex !== undefined) {

        const getAvatar = () => {
            if (item.teamId) {
                return CONFIG.STORAGE_URL + "/" + item.userId + "/team/" + item.teamId + "/avatar/" + item.avatar;
            } else {
                return CONFIG.STORAGE_URL + "/" + item.userId + "/avatar/" + item.avatar;
            }
        }

        return (
            <div className="dot-info">
                <div className="close-window" onClick={() => setItem(undefined, undefined)}/>
                <div className="dot-info-block">
                    <div className="comand-card">
                        <div className="comand-card-info">
                            <img src={getAvatar()}
                                 onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                 alt="" className="comand-avatar"/>
                            <div className="comand-info-text">
                                <h3 className="comand-name">
                                    {item.name}
                                </h3>
                                {
                                    item.teamId ?
                                        <p className="comand-description">
                                            {plural(item.numPersons || 0, "%d человек", "%d человека", "%d человек")}
                                        </p>
                                        :
                                        <></>
                                }
                            </div>
                        </div>
                    </div>
                    <h2 className="headline-right-block">
                        {item.plotData[flagIndex].ctfTaskName}
                    </h2>
                    <p className="text-block">
                        {item.plotData[flagIndex].ctfTaskDescription}
                    </p>
                    <p className="point-system">
                        Мои баллы <span className="point-system-fact">{item.plotData[flagIndex].endPoint}</span> / максимум <span
                        className="point-system-max">{item.plotData[flagIndex].ctfTaskMaxPoints}</span>
                    </p>
                    <p className="point-system">
                        Время прохождения в минутах <span className="point-system-fact">
                        {((item.plotData[flagIndex].endTime - item.plotData[flagIndex].startTime) / 60).toFixed(2)}</span>
                    </p>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}