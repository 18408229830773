import React, {useEffect, useState} from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import API from "../../../../../requests/_axios";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {toNumber} from "lodash";
import {IChallengeStageCTFTask} from "../../../../types/IChallengeStageCTFTask";

export const StageCTF: React.FC<{
    stage: IChallengeStage,
    refreshStage: (stage: IChallengeStage) => void,
    setErrorMessage: (msg: string) => void}> = ({stage, refreshStage, setErrorMessage}) => {

    const [ctfTaskList, setCtfTaskList] = useState<IChallengeStageCTFTask[]>([]);
    const [totalPoints, setTotalPoints] = useState(0);


    useEffect(() => {
        setCtfTaskList(stage.ctfTasks);
        recalculatePoints(stage.ctfTasks);
    }, [stage]);

    useEffect(() => {
        ctfTaskList.map(v => {
            if (!v.position) v.position = getNextPosition();
            return v;
        })
    }, [ctfTaskList])

    const handleSubmit = () => {
        setErrorMessage("");
        API.put("/challenge/api/v1/challenge/" + stage.challengeId + "/stage/" + stage.id, stage).then((response) => {
            if(response.status === 200) {
                //refreshStage(response.data);
                window.location.reload();
            } else {
                setErrorMessage("Ошибка! " + response.data.message);
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0,0);
            }
        });
    }

    const addTask = () => {
        API.put("/challenge/api/v1/challenge/" + stage.challengeId + "/stage/" + stage.id, stage).then((response) => {
            if(response.status === 200) {
                const task: IChallengeStageCTFTask = {position: getNextPosition()};
                response.data.ctfTasks.push(task);
                refreshStage(response.data);
            } else {
                setErrorMessage("Ошибка! " + response.data.message);
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0,0);
            }
        });
    }

    const deleteTask = (index: number) => {
        if(!confirm("Вы действительно желаете удалить задание?"))
            return;
        const task = ctfTaskList[index];
        if (task.id && stage.challengeId && stage.id) {
            ChallengeAPI.deleteStageCtfTask(stage.challengeId, stage.id, task.id, (response) => {
                if (response.status === 200) {
                    const newFirstPartTasks = ctfTaskList.slice(0, index);
                    const newSecondPartTasks = ctfTaskList.slice(index + 1);
                    const newTasks = newFirstPartTasks.concat(newSecondPartTasks);
                    refreshStage({...stage, ctfTasks: newTasks});
                } else {
                    setErrorMessage("Ошибка! " + response.data.message);
                    // @ts-ignore
                    document.querySelector('.container-right-block').scrollTo(0,0);
                }
            });
        } else {
            const newTasks = ctfTaskList.slice(0, index);
            refreshStage({...stage, ctfTasks: newTasks});
        }
    }

    const changeName = (index: number, value: string) => {
        const task = ctfTaskList[index];
        task.name = value;
        updateTaskList(index, task);
    }

    const changeDescription = (index: number, value: string) => {
        const task = ctfTaskList[index];
        task.description = value;
        updateTaskList(index, task);
    }

    const changeUrl = (index: number, value: string) => {
        const task = ctfTaskList[index];
        task.taskUrl = value;
        updateTaskList(index, task);
    }

    const changePoints = (index: number, value: number) => {
        const task = ctfTaskList[index];
        task.points = value;
        updateTaskList(index, task);
    }

    const changeFlag = (index: number, value: string) => {
        const task = ctfTaskList[index];
        task.flag = value;
        updateTaskList(index, task);
    }

    const changePosition = (index: number, value: number) => {
        const task = ctfTaskList[index];
        task.position = value;
        updateTaskList(index, task);
    }

    const changeHint = (index: number, value: string) => {
        const task = ctfTaskList[index];
        task.hint = value;
        updateTaskList(index, task);
    }

    const changeHintVisibility = (index: number) => {
        const task = ctfTaskList[index];
        task.isHintVisible = !task.isHintVisible;
        updateTaskList(index, task);
    }

    const updateTaskList = (index: number, task: IChallengeStageCTFTask) => {
        const newTasks = [...ctfTaskList];
        newTasks[index] = task;
        setCtfTaskList(newTasks);
        recalculatePoints(newTasks);
    }

    const getNextPosition = () => {
        let maxPosition = 0;
        ctfTaskList.map(task => maxPosition = task.position && task.position > maxPosition ? task.position : maxPosition);
        return maxPosition + 1;
    }

    const recalculatePoints = (newTasks: IChallengeStageCTFTask[]) => {
        let points = 0;
        newTasks.forEach(t => {
            points += t.points || 0;
        });
        setTotalPoints(points);
    }

    return (
        <>
            {ctfTaskList?.map((task, index) =>
                    <div className="menu-challenge"
                         key={index}>
                        <div className="row-wrapper">
                            <h3 className="template-heading">Задание {index + 1}</h3>
                            <a onClick={() => deleteTask(index)} className="delete-stage">Удалить задание</a>
                        </div>
                        <fieldset className="block-input">
                            <legend className="legend">Название*</legend>
                            <input type="text" value={task.name} onChange={(e) => changeName(index, e.target.value)} className="input-challenges" placeholder="Введите название"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Описание*</legend>
                            <textarea className="input-challenges" value={task.description} onChange={(e) => changeDescription(index, e.target.value)} cols={30} rows={5} placeholder="Введите описание до 500 символов"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Кол-во баллов*</legend>
                            <input type="number" onWheel={(e) => (e.target as HTMLElement).blur()} className="input-challenges" value={task.points} onChange={(e) => changePoints(index, toNumber(e.target.value))} placeholder="Кол-во баллов"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Флаг*</legend>
                            <input type="text" value={task.flag} onChange={(e) => changeFlag(index, e.target.value)} className="input-challenges" placeholder="Введите флаг"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Ссылка на задание</legend>
                            <input type="text" value={task.taskUrl} onChange={(e) => changeUrl(index, e.target.value)} className="input-challenges" placeholder="Вставьте ссылку"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Подсказка</legend>
                            <textarea className="input-challenges" value={task.hint} onChange={(e) => changeHint(index, e.target.value)} cols={30} rows={2} placeholder="Введите подсказку"/>
                            <label className="input-challenges-flex">
                                <input type="checkbox" name="status" checked={task.isHintVisible} onChange={(e) => changeHintVisibility(index)} className="radio-btn"/>
                                <span className="span-radio">Отображать подсказку</span>
                            </label>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Порядок</legend>
                            <input type="number" value={task.position} onWheel={(e) => (e.target as HTMLElement).blur()} onChange={(e) => changePosition(index, toNumber(e.target.value))} className="input-challenges" placeholder="Очередность задания"/>
                        </fieldset>
                        {/*<div className="btn-info">
                            <h4 className="btn-info-heading">
                                Внимание
                            </h4>
                            <p className="btn-info-text">
                                Загружайте ваши уроки на youtube с доступом по ссылке. Видео из остальных
                                сервисов не будут открываться в модальном окне
                            </p>
                        </div>*/}
                    </div>
            )}
            <div className="criteria">
                <fieldset className="block-input">
                    <p className="summa-criteria">
                        {totalPoints} баллов
                    </p>
                </fieldset>
            </div>
            <a onClick={addTask} className="btn-blue-width">Добавить задание</a>
            <button className="btn-main" id="submit" onClick={handleSubmit}>Сохранить</button>
        </>
    );
}