import React, {useEffect, useState} from "react";
import {ModalMessage} from "../../common/ModalMessage";
import {OrgHeader} from "../../common/OrgHeader";
import {ProfileNavigationAdmin} from "../ProfileNavigationAdmin";
import {IAdminPlan} from "../../../types/IAdminPlan";
import {AdminPlanSettings} from "./right/AdminPlanSettings";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";

export const AdminPlan = () => {

    const [error, setError] = useState("");
    const [planList, setPlanList] = useState([]);
    const [rightBlockVisible, setRightBlockVisible] = useState(false);
    const [currentPlan, setCurrentPlan] = useState<IAdminPlan | undefined>();

    useEffect(() => {
        ChallengeAPI.getPlans((response) => {
            if (response.status === 200) {
                setPlanList(response.data);
            } else {
                setError("Ошибка получения тарифных планов");
            }
        })
    }, []);

    const pickPlan = (plan: IAdminPlan) => {
        setCurrentPlan(plan);
        setRightBlockVisible(true);
    }

    const createNewPlan = () => {
        setCurrentPlan(undefined);
        setRightBlockVisible(true);
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../../public/images/main_video.mp4").default}/>
            </video>
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <ProfileNavigationAdmin />
                    <div className="main-list-center">
                        <button className="button-add" onClick={createNewPlan}>
                            Создать
                        </button>
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Тарифные планы</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href="?tab=all" className="link-nav-center-item">Все</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info">
                            {planList?.map((plan: IAdminPlan) =>
                                <li className="challenge-card" key={plan.id}>
                                    <div className="challenge-card-main">
                                        <img src="/images/no_ava_challenge.svg" alt="" className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header" onClick={() => pickPlan(plan)}>{plan.name}</p>
                                            <p className="challenge-company">{plan.price ? plan.price / 100 : 0}</p>
                                        </div>
                                    </div>
                                    <p className="challenge-description">
                                        {plan.role}
                                    </p>
                                </li>
                            )}
                        </ul>
                    </div>
                </main>
            </div>
            <AdminPlanSettings plan={currentPlan} setVisible={setRightBlockVisible} visible={rightBlockVisible}/>
        </div>
    )
}