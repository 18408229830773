import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import SessionModel from "../../../stores/SessionModel";
import {IChallengeCategory} from "../../types/IChallengeCategory";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";

export const ProfileNavigationAdmin = () => {

  const navigate = useNavigate();
  const [challengeCategories, setChallengeCategories] = useState<IChallengeCategory[]>([]);

  useEffect(() => {
    ChallengeAPI.getChallengeCategories((response) => {
        if (response.status === 200) {
            setChallengeCategories(response.data);
        }
    })
  }, []);

  const handleExit = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  }

  const hideMenu = () => {
    // @ts-ignore
    document.querySelector(".menu").style.display = 'none';
  }

  return (
      <nav className="menu">
        <div id="closemenu" className="burger-back arrow-back" onClick={hideMenu}>
          Закрыть
        </div>
        <ul className="menu-list">
          <li className="menu-item"><a href="/profile" className="menu-link vuz-menu">ВУЗы</a></li>
          <li className="menu-item"><a href="/business" className="menu-link users-menu">Организации</a></li>
          {SessionModel.isAdmin() ?
              <>
                  {challengeCategories && challengeCategories.map((category: IChallengeCategory, key) =>
                      <li className="menu-item" key={key}>
                          <a href={`/challenge?cat=${category.code}`} className={`menu-link ${category.code}-menu`}>{category.name}</a>
                      </li>
                  )}
                <li className="menu-item"><a href="/certificates" className="menu-link loot-menu">Достижения</a></li>
              </>
          : <></>}
          <li className="menu-item"><a href="/unloads" className="menu-link unloads-menu">Выгрузки</a></li>
          {SessionModel.isAdmin() ?
              <>
                <li className="menu-item"><a href="/users" className="menu-link users-menu">Пользователи</a></li>
                <li className="menu-item"><a href="/test" className="menu-link tests-menu">Тесты</a></li>
                <li className="menu-item"><a href="/payment/bills/list" className="menu-link bills-menu" target="_blank">Счета</a></li>
                <li className="menu-item"><a href="/plan" className="menu-link plans-menu">Тарифы</a></li>
              </>
          : <></>}
          <li className="menu-item"><a href="#0" onClick={handleExit} className="menu-link exit-menu">Выход</a></li>
        </ul>
      </nav>
  )
}