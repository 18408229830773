import React, {ChangeEvent, useEffect, useState} from "react";
import {IJuryAssessmentListTeam} from "../../../../types/assessment/IJuryAssessmentListTeam";
import {IJuryAssessmentListParticipant} from "../../../../types/assessment/IJuryAssessmentListParticipant";
import {IChallengeStageGradeCriteria} from "../../../../types/IChallengeStageGradeCriteria";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {useParams, useSearchParams} from "react-router-dom";
import {toNumber} from "lodash";
import {IJuryAssessment} from "../../../../types/assessment/IJuryAssessment";
import {IJuryAssessmentGrade} from "../../../../types/assessment/IJuryAssessmentGrade";
import {ModalMessage} from "../../../common/ModalMessage";
import {GradeDetailsView} from "./GradeDetailsView";

export const GradeDetails:React.FC<{
  allowComments: boolean,
  rulesLink?: string,
  instance: IJuryAssessmentListParticipant | IJuryAssessmentListTeam | undefined,
  criteriaList: IChallengeStageGradeCriteria[],
  closeSelf: (instance: any) => void}> = ({allowComments, rulesLink, instance, criteriaList, closeSelf}) => {

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [team, setTeam] = useState<IJuryAssessmentListTeam>();
  const [participant, setParticipant] = useState<IJuryAssessmentListParticipant>();
  const [assessment, setAssessment] = useState<IJuryAssessment>();
  const [assessmentGrades, setAssessmentGrades] = useState<IJuryAssessmentGrade[]>([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (instance === undefined) return;
    if (instanceOfTeam(instance))
      setTeam(instance as IJuryAssessmentListTeam)
    else if (instanceOfParticipant(instance))
      setParticipant(instance as IJuryAssessmentListParticipant);
    if (instance.assessmentId) {
      ChallengeAPI.getAssessment(toNumber(params.id), toNumber(searchParams.get("stageId")), instance.assessmentId, (response) => {
        if (response.status === 200) {
          setAssessment(response.data);
          setAssessmentGrades(response.data.grades);
        }
      });
    } else {
      initAssessment();
    }
  }, [instance]);

  const initAssessment = () => {
    const grades = new Array<IJuryAssessmentGrade>();
    criteriaList.forEach(c => {
      const grade: IJuryAssessmentGrade = {
        criteriaId: c.id || 0,
        points: 0
      }
      grades.push(grade);
    });
    setAssessmentGrades(grades);
    const newAssessment: IJuryAssessment = {
      solutionId: instance?.solutionId || 0,
      grades: grades
    }
    setAssessment(newAssessment);
  }

  const submitAssessment = () => {
    if (!assessment) return;
    ChallengeAPI.submitAssessment(toNumber(params.id), toNumber(searchParams.get("stageId")), assessment, (response) => {
      if (response.status === 200) {
        window.location.reload();
      } else {
        setError("Ошибка отправки оценок");
      }
    })
  }

  function instanceOfTeam(object: any): object is IJuryAssessmentListTeam {
    return 'name' in object;
  }

  function instanceOfParticipant(object: any): object is IJuryAssessmentListParticipant {
    return 'firstname' in object;
  }

  const getCriteriaById = (criteriaId: number): IChallengeStageGradeCriteria => {
    return criteriaList.filter(c => c.id === criteriaId)[0];
  }

  const changeGradePoints = (criteriaId: number, points: number) => {
    const newGrades = assessmentGrades.map(ag => {
      if (ag.criteriaId === criteriaId) {
        ag.points = points;
      }
      return ag;
    });
    setAssessmentGrades(newGrades);
  }

  const getTotalPoints = () => {
    let total = 0;
    assessmentGrades.forEach(ag => {total += ag.points});
    return total;
  }

  const handleChangeGradePoints = (event: ChangeEvent<HTMLInputElement>, criteriaId: number) => {
    let { value, min, max } = event.target;
    let validatedValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
    changeGradePoints(criteriaId, validatedValue);
  };

  if (instance) {
    return (
        <div className="right-block">
          <header className="header">
            <p className="header-back" id="backmenu" onClick={() => closeSelf(undefined)}>Назад</p>
            <p className="name-menu">{team?.name || `${participant?.lastname} ${participant?.firstname}`}</p>
          </header>
          <div className="container-right-block">

            <div className="menu-challenge">
              <h2 className="headline-right-block">Оценка проекта</h2>
              <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
              <ul className="responses-list">
                {instance.submitted ?
                    <GradeDetailsView
                        allowComments={allowComments}
                        comment={instance.comment || ""}
                        getCriteriaById={getCriteriaById}
                        gradeList={assessmentGrades}
                        nameOrProject={team?.name || `${participant?.lastname} ${participant?.firstname}`}/>
                    :
                    <div className="block-input">
                      <a href={team?.solutionLink || participant?.solutionLink} target="_blank" className="btn-blue">Ссылка на решение</a>
                      {rulesLink ?
                          <div className="career-list">
                            <div className="career-item">
                              <h4 className="career-heading">
                                Правила оценки и комментариев
                              </h4>
                              <p className="career-description">
                                Ознакомьтесь с <a href={rulesLink} className="active-link"
                                                  target="_blank">правилами</a> оценки
                              </p>
                            </div>
                          </div>
                          :
                          <></>
                      }
                      <div className="career-item">
                        {
                          assessmentGrades.map((ag, index) =>
                              <fieldset className="fieldset-hidden" key={index}>
                                <legend className="legend">{`${getCriteriaById(ag.criteriaId).name} (0 - ${getCriteriaById(ag.criteriaId).points})`}</legend>
                                <input className="input-challenges" type="number" name="" id=""
                                       placeholder={`0 - ${getCriteriaById(ag.criteriaId).points}`}
                                       value={ag.points}
                                       min={0}
                                       max={toNumber(getCriteriaById(ag.criteriaId).points)}
                                       onChange={(e) => handleChangeGradePoints(e, ag.criteriaId)}
                                       onWheel={(e) => (e.target as HTMLElement).blur()}/>
                              </fieldset>
                          )
                        }
                        {
                          allowComments ?
                              <fieldset className="fieldset-hidden">
                                <legend className="legend">Комментарий</legend>
                                <textarea className="input-challenges"
                                          onChange={(e) => setAssessment({...assessment,
                                            comment: e.target.value,
                                            solutionId: assessment?.solutionId || 0,
                                            grades: assessment?.grades || new Array<IJuryAssessmentGrade>()})}
                                          value={assessment?.comment}
                                          placeholder="Введите комментарий">
                                </textarea>
                              </fieldset>
                              :
                              <></>
                        }
                        <p className="result-rating-form">
                          {
                            getTotalPoints()
                          }
                        </p>
                        <input type="button" value="Оценить" className="btn-main" onClick={submitAssessment}/>
                      </div>
                    </div>
                }
              </ul>
            </div>

          </div>

        </div>
    )
  } else {
    return (<></>)
  }
}