import React, {useEffect, useState} from "react";
import API from "../../../../../requests/_axios";
import {ITeam} from "../../../../types/ITeam";
import {CONFIG} from "../../../../../CONFIG";
import SessionModel from "../../../../../stores/SessionModel";
import {toNumber} from "lodash";

export const NewTeam: React.FC<{team: ITeam | undefined, challengeId: number | undefined}> = ({challengeId, team}) => {

  const [preview, setPreview] = useState<any>("/images/no_ava_team.svg");
  const [stateTeam, setStateTeam] = useState<ITeam>();
  const [error, setError] = useState("");
  const [tgHint, setTgHint] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    if(team) {
      setStateTeam(team);
      setPreview(CONFIG.STORAGE_URL + "/" + team.userId + "/team/" + team.id + "/avatar/" + team.avatar);
      if (team.userId !== toNumber(SessionModel.getCurrentUserAuthData().id) && SessionModel.getCurrentUserAuthData().role === "ADMIN")
         setDisableEdit(true);
    }
  }, [team]);

  const handleAvatarChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    const fileList = e.target.files;
    if (!fileList) return;
    if (fileList[0].size >= 5000000) {
      setError("Размер изображения не должен превышать 5Мб.");
      return;
    }
    const objectUrl = URL.createObjectURL(fileList[0])
    setPreview(objectUrl);
    let reader = new FileReader();
    reader.readAsDataURL(fileList[0]);
    reader.onload = function () {
      let b64 = reader.result !== null ? reader.result.toString().split(',')[1] : "";
      setStateTeam({...stateTeam, b64avatar: b64});
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const handleSubmit = () => {
    setIsSaveDisabled(true);
    setError("");
    const url = "/challenge/api/v1/challenge/" + challengeId + "/team/my";
    if (validate()) {
      if(team) {
        API.put(url, stateTeam).then((response) => {
          if(response.status === 200) {
            window.location.reload();
          } else {
            setError(response.data.message);
            // @ts-ignore
            document.querySelector('.container-right-block').scrollTo(0,0);
          }
          setIsSaveDisabled(false);
        })
      } else {
        API.post(url, stateTeam).then((response) => {
          if(response.status === 200) {
            window.location.href=`/challenge/${challengeId}/my/ads`;
          } else {
            setError(response.data.message);
            // @ts-ignore
            document.querySelector('.container-right-block').scrollTo(0,0);
          }
          setIsSaveDisabled(false);
        })
      }
    } else {
      setIsSaveDisabled(false);
    }
  }

  const validate = () : boolean => {
    if (!stateTeam?.avatar && !stateTeam?.b64avatar) {
      setError("Загрузите аватар команды");
      // @ts-ignore
      document.querySelector('.container-right-block').scrollTo(0,0);
      return false;
    }
    return true;
  }

  return (
      <div className="container-right-block">
        <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
        <div className="menu-challenge">
          <div className="avatar-right-block">
            <label htmlFor="addimage" className="addpost-btn">
              <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} className="avater-user" alt=""/>
            </label>
              <div className="user-info">
                <p className="fio-or-name">Аватар команды*</p>
                <input type="file" accept=".jpg,.jpeg" id="addimage" onChange={handleAvatarChange} style={{display: 'none' }} disabled={disableEdit}/>
              </div>
          </div>
          <fieldset className="block-input">
            <legend className="legend">Название команды*</legend>
            <input type="text" value={stateTeam?.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStateTeam({...stateTeam, name: e.target.value});
            }} className="input-challenges" placeholder="Введите текст" disabled={disableEdit}/>
          </fieldset>
          {SessionModel.isOrg() ?
           <></>
              :
              <fieldset className="block-input">
                <legend className="legend">Общий чат</legend>
                <input type="text" value={stateTeam?.telegram} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStateTeam({...stateTeam, telegram: e.target.value});
                }} className="input-challenges" placeholder="Ссылка на группу в Telegram" disabled={disableEdit}/>
                <div className="answer" onMouseOver={() => setTgHint(!tgHint)} onMouseOut={() => setTgHint(!tgHint)}></div>
                <p className="answer-text" style={{display: tgHint ? "" : "none"}}>Когда у вашей команды появится группа в Telegram для взаимодействия, укажите ссылку на неё в этом поле. Для получения ссылки зайдите в Telegram в вашу группу, щёлкните на её названии, на далее на значке карандаша (редактирование) затем на "Пригласительные ссылки" и скопируйте значение поля "Ссылка-приглашение".</p>
              </fieldset>
          }
          <fieldset className="block-input">
            <legend className="legend">Описание команды*</legend>
            <textarea value={stateTeam?.description} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=> {
              setStateTeam({...stateTeam, description: e.target.value});
            }} cols={30} rows={10} className="input-challenges" placeholder="Введите текст не более 500 символов" disabled={disableEdit} maxLength={500}/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Название проекта*</legend>
            <input type="text" value={stateTeam?.projectName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStateTeam({...stateTeam, projectName: e.target.value});
            }} className="input-challenges" placeholder="Введите текст" disabled={disableEdit}/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Описание проекта (заполняется только для проектных конкурсов)</legend>
            <textarea value={stateTeam?.projectDescription} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=> {
              setStateTeam({...stateTeam, projectDescription: e.target.value});
            }} cols={30} rows={10} className="input-challenges" placeholder="Введите текст не более 500 символов" disabled={disableEdit} maxLength={500}/>
          </fieldset>
          {disableEdit ? (<></>) : (<button className="btn-main" onClick={handleSubmit} id="submit-button" disabled={isSaveDisabled}>{isSaveDisabled ? "Сохранение..." : "Сохранить"}</button>)}
        </div>
      </div>
  );
}