import React, {useState} from "react";
import {CONFIG} from "../../../CONFIG";

export const MainPageHelp = () => {

    const[showHelp, setShowHelp] = useState(false);

    return (
        <div className="spravka">
            <p className="spravka-text" onClick={() => setShowHelp(!showHelp)} style={{display: showHelp ? "none" : ""}}>
                ?
            </p>
            <div className="spravka-text-block" style={{display: showHelp ? "" : "none"}}>
                <a href={CONFIG.PLATFORM_ADMIN_USER_TG} target="_blank" className="spravka-link">Техподдержка Braim</a>
                <a href="https://disk.yandex.ru/d/mhHsBkKAovviNA" target="_blank" className="spravka-link">Видеоинструкции</a>
                <a href="#" className="spravka-link" onClick={() => setShowHelp(false)}>Закрыть</a>
            </div>
        </div>
    );
}