import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {UniversityStudents} from "./UniversityStudents";
import {UniversityChallenges} from "./UniversityChallenges";
import SessionModel from "../../../stores/SessionModel";
import {UnloadsUniversity} from "./UnloadsUniversity";

export const UniversityStatistics = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [component, setComponent] = useState("students");

    useEffect(() => {
        if (SessionModel.getCurrentUserAuthData().type !== "EDU") {
            localStorage.getItem("token");
            window.location.href = "/";
        }
        setComponent(searchParams.get("tab") || "students");
    }, []);

    if (component === "students") {
        return (<UniversityStudents/>)
    } else if (component === "challenges") {
        return (<UniversityChallenges/>)
    } else if (component === "unloads") {
        return (<UnloadsUniversity/>)
    } else {
        return (<></>)
    }

}