import React, {useEffect, useState, DragEvent} from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {IChallengeStageVideo} from "../../../../types/IChallengeStageVideo";
import API from "../../../../../requests/_axios";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {toNumber} from "lodash";

export const StageVideo:React.FC<{
    stage: IChallengeStage,
    refreshStage: (stage: IChallengeStage) => void,
    setErrorMessage: (msg: string) => void}> = ({stage, refreshStage, setErrorMessage}) => {

    const [videoList, setVideoList] = useState<IChallengeStageVideo[]>([]);
    const [dragId, setDragId] = useState(0);


    useEffect(() => {
        setVideoList(stage.videos);
    }, [stage]);

    useEffect(() => {
        videoList.map(v => {
            if (!v.position) v.position = getNextPosition();
            return v;
        })
    }, [videoList])

    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        setDragId(toNumber(event.currentTarget.id));
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        const dragBox = videoList.find((box) => box.position === dragId);
        const dropBox = videoList.find((box) => box.position === toNumber(event.currentTarget.id));

        if (dragBox && dropBox) {
            const dragBoxOrder = dragBox.position;
            const dropBoxOrder = dropBox.position;

            const newVideoListState = videoList.map((box) => {
                if (box.position === dragId) {
                    box.position = dropBoxOrder;
                } else if (box.position === toNumber(event.currentTarget.id)) {
                    box.position = dragBoxOrder;
                }
                return box;
            });
            setVideoList(newVideoListState);
        }
    };

    const handleSubmit = () => {
        setErrorMessage("");
        let validUrls = true;
        stage.videos.map((video) => {if (validUrls) validUrls = validateUrl(video.url || "") });
        if (!validUrls) {
            setErrorMessage("Ошибка! Проверьте корректность ссылок на видео");
            // @ts-ignore
            document.querySelector('.container-right-block').scrollTo(0,0);
            return;
        }
        API.put("/challenge/api/v1/challenge/" + stage.challengeId + "/stage/" + stage.id, stage).then((response) => {
            if(response.status === 200) {
                //refreshStage(response.data);
                window.location.reload();
            } else {
                setErrorMessage("Ошибка! " + response.data.message);
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0,0);
            }
        });
    }

    const validateUrl = (url: string) => {
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
    }

    const addVideo = () => {
        API.put("/challenge/api/v1/challenge/" + stage.challengeId + "/stage/" + stage.id, stage).then((response) => {
            if(response.status === 200) {
                const video: IChallengeStageVideo = {position: getNextPosition()};
                response.data.videos.push(video);
                refreshStage(response.data);
            } else {
                setErrorMessage("Ошибка! " + response.data.message);
                // @ts-ignore
                document.querySelector('.container-right-block').scrollTo(0,0);
            }
        });
    }

    const deleteVideo = (index: number) => {
        if(!confirm("Вы действительно желаете удалить урок?"))
            return;
        const video = videoList[index];
        if (video.id && stage.challengeId && stage.id) {
            ChallengeAPI.deleteStageVideo(stage.challengeId, stage.id, video.id, (response) => {
                if (response.status === 200) {
                    const newFirstPartVideos = videoList.slice(0, index);
                    const newSecondPartVideos = videoList.slice(index + 1);
                    const newVideos = newFirstPartVideos.concat(newSecondPartVideos);
                    refreshStage({...stage, videos: newVideos});
                } else {
                    setErrorMessage("Ошибка! " + response.data.message);
                    // @ts-ignore
                    document.querySelector('.container-right-block').scrollTo(0,0);
                }
            });
        } else {
            const newFirstPartVideos = videoList.slice(0, index);
            const newSecondPartVideos = videoList.slice(index + 1);
            const newVideos = newFirstPartVideos.concat(newSecondPartVideos);
            refreshStage({...stage, videos: newVideos});
            //setVideoList(newVideos);
        }
    }

    const changeName = (index: number, value: string) => {
        const video = videoList[index];
        video.name = value;
        updateVideoList(index, video);
    }

    const changeDescription = (index: number, value: string) => {
        const video = videoList[index];
        video.description = value;
        updateVideoList(index, video);
    }

    const changeUrl = (index: number, value: string) => {
        const video = videoList[index];
        video.url = value;
        updateVideoList(index, video);
    }

    const changeAddonsUrl = (index: number, value: string) => {
        const video = videoList[index];
        video.addonsUrl = value;
        updateVideoList(index, video);
    }

    const changePosition = (index: number, value: number) => {
        const video = videoList[index];
        video.position = value;
        updateVideoList(index, video);
    }

    const updateVideoList = (index: number, video: IChallengeStageVideo) => {
        const newVideos = [...videoList];
        newVideos[index] = video;
        setVideoList(newVideos);
    }

    const getNextPosition = () => {
        let maxPosition = 0;
        videoList.map(video => maxPosition = video.position && video.position > maxPosition ? video.position : maxPosition);
        return maxPosition + 1;
    }

    return (
        <>
            {videoList?.map((video, index) =>
                    <div className="menu-challenge"
                         key={index}
                         /*id={index.toString()}
                         draggable={true}
                         onDragOver={(event) => event.preventDefault()}
                         onDragStart={handleDrag}
                         onDrop={handleDrop}*/>
                        <div className="row-wrapper">
                            <h3 className="template-heading">Урок {index + 1}</h3>
                            <a onClick={() => deleteVideo(index)} className="delete-stage">Удалить урок</a>
                        </div>
                        <fieldset className="block-input">
                            <legend className="legend">Название урока*</legend>
                            <input type="text" value={video.name} onChange={(e) => changeName(index, e.target.value)} className="input-challenges" placeholder="Введите название"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Описание урока*</legend>
                            <textarea className="input-challenges" value={video.description} onChange={(e) => changeDescription(index, e.target.value)} cols={30} rows={5} placeholder="Введите описание до 500 символов"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Ссылка на видео*</legend>
                            <input type="text" value={video.url} onChange={(e) => changeUrl(index, e.target.value)} className="input-challenges" placeholder="Вставьте ссылку"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Ссылка на дополнительные материалы</legend>
                            <input type="text" value={video.addonsUrl} onChange={(e) => changeAddonsUrl(index, e.target.value)} className="input-challenges" placeholder="Вставьте ссылку"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Порядок</legend>
                            <input type="number" value={video.position} onWheel={(e) => (e.target as HTMLElement).blur()} onChange={(e) => changePosition(index, toNumber(e.target.value))} className="input-challenges" placeholder="Очередность видео"/>
                        </fieldset>
                        {/*<div className="btn-info">
                            <h4 className="btn-info-heading">
                                Внимание
                            </h4>
                            <p className="btn-info-text">
                                Загружайте ваши уроки на youtube с доступом по ссылке. Видео из остальных
                                сервисов не будут открываться в модальном окне
                            </p>
                        </div>*/}
                    </div>
            )}
            <a onClick={addVideo} className="btn-blue-width">Добавить урок</a>
            <button className="btn-main" id="submit" onClick={handleSubmit}>Сохранить</button>
        </>
    );
}