import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {IChallengeCategory} from "../../../types/IChallengeCategory";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import * as TestAPI from "../../../../requests/TestAPI";
import * as CertificateAPI from "../../../../requests/CertificateAPI";

export const NavigationSub = () => {
  const navigate = useNavigate();
  const [challengeCategories, setChallengeCategories] = useState<IChallengeCategory[]>([]);

  const [testAccess, setTestAccess] = useState(false);
  const [certificateAccess, setCertificateAccess] = useState(false);

  useEffect(() => {
    ChallengeAPI.getChallengeCategories((response) => {
      if (response.status === 200) {
        setChallengeCategories(response.data);
      }
    });
    TestAPI.getSubRoleList(null, (response) => {
      if (response.status === 200) {
        setTestAccess(true);
      }
    });
    CertificateAPI.getSubRoleList(null, (response) => {
      if (response.status === 200) {
        setCertificateAccess(true);
      }
    })
  }, []);

  const handleExit = () => {
    localStorage.removeItem("token");
    navigate("/signin");
  }

  const getLocation = () : string => {
    let loc = location.pathname + location.search
    if(loc !== undefined)
      return loc;
    else
      return "";
  }

  const hideMenu = () => {
    // @ts-ignore
    document.querySelector(".menu").style.display = 'none';
  }

  return (
      <nav className="menu">
        <div id="closemenu" className="burger-back arrow-back" onClick={hideMenu}>
          Закрыть
        </div>
        <ul className="menu-list">
          <ul className="menu-list">
            <li className="menu-item"><a href="/profile"
                                         className={getLocation() === "/profile" ? "menu-link profile-menu acvtive-menu" : "menu-link profile-menu"}>Профиль</a></li>
            {challengeCategories && challengeCategories.map((category: IChallengeCategory, key) =>
                <>
                  <li className="menu-item">
                    <a href={`/challenge?cat=${category.code}`} className={getLocation() === `/challenge?cat=${category.code}` ? (`menu-link ${category.code}-menu acvtive-menu`) : (`menu-link ${category.code}-menu`)} key={key}>{category.name}</a></li>
                </>
            )}
            {
              certificateAccess ?
                  <li className="menu-item"><a href="/certificates" className="menu-link loot-menu">Достижения</a></li>
                  :
                  <></>
            }
            {testAccess ?
                <li className="menu-item"><a href="/test" className="menu-link tests-menu">Тесты</a></li>
                :
                <></>
            }
            <li className="menu-item"><a href="#0" className="menu-link exit-menu" onClick={handleExit}>Выход</a></li>
          </ul>
        </ul>
      </nav>
  )
}