import React, {useEffect, useState} from "react";
import * as WalletAPI from "../../../../../requests/WalletAPI";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {IFillBalance} from "../../../../types/IFillBalance";
import {ModalMessage} from "../../ModalMessage";
import {toNumber} from "lodash";
import {CONFIG} from "../../../../../CONFIG";

export const BillPayment: React.FC<{visible: boolean, setVisible: (visibility: boolean) => void}> = ({visible, setVisible}) => {

    const [fillRequest, setFillRequest] = useState<IFillBalance>();
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState("");
    const [acceptOffer, setAcceptOffer] = useState(true);

    useEffect(() => {
        ChallengeAPI.getProfile((response) => {
            if (response.status === 200) {
                setFillRequest({...fillRequest, inn: response.data.inn, orgName: response.data.fullName});
            } else {
                setError("Ошибка получения ИНН и названия организации. Пожалуйста, заполните вручную");
            }
        })
    }, []);

    const goPay = () => {
        if (fillRequest?.amount && (fillRequest.amount > 0 && fillRequest.orgName && fillRequest.inn)) {
            if (!acceptOffer) {
                setError("Перед оплатой счета необходимо согласиться с офертой");
                return;
            }
            fillRequest.amount = fillRequest.amount * 100;
            WalletAPI.fill(fillRequest, (response) => {
                if (response.status === 200) {
                    //window.open(response.data, '_blank');
                    window.location.href = response.data;
                } else {
                    setError("Ошибка при получении ссылки на оплату");
                    setShowSpinner(false);
                }
            });
        } else {
            setError("Заполните корректно все поля");
        }
    }

    return (
        <div className="right-block" style={{right: visible ? "0vw" : "-100vw"}}>
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <header className="header">
                <p className="header-back" id="backmenu" onClick={() => setVisible(false)}>Назад</p>
                <p className="name-menu">1 этап</p>
            </header>
            <div className="container-right-block">

                <div className="menu-challenge">
                    <div className="row-wrapper">
                        <p className="number-question">
                            Пополнение счета
                        </p>
                    </div>
                    <h2 className="headline-right-block">
                        Запрос счета для оплаты
                    </h2>
                    {/*<div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Счет в виде файла pdf будет отправлен на указанную Вами
                            электронную почту. Для уточнения достовернности данных с Вами
                            может связаться наш сотрудник по указанному номеру телефона
                        </p>
                    </div>*/}
                    <fieldset className="block-input">
                        <legend className="legend">Сумма для зачисления*</legend>
                        <input type="number" min={1} onChange={(e) => setFillRequest({...fillRequest, amount: toNumber(e.target.value), paymentSource: "BILL"})} className="input-challenges" placeholder="Введите сумму"/>
                        <input type="number" min={10} max={12} onChange={(e) => setFillRequest({...fillRequest, inn: e.target.value})} className="input-challenges" placeholder="ИНН" value={fillRequest?.inn}/>
                        <input type="text" onChange={(e) => setFillRequest({...fillRequest, orgName: e.target.value})} className="input-challenges" placeholder="Полное наименование организации" value={fillRequest?.orgName}/>
                        <label className="label-checkbox-radio-center">
                            <input type="checkbox" name="email-ads" checked={acceptOffer} className="checkbox-radio-center" onChange={() => setAcceptOffer(!acceptOffer)}/>
                            <p>Оплачивая счет, я соглашаюсь с <a href={`${CONFIG.STORAGE_URL}/offer_braim.pdf`} target="_blank" className="active">офертой</a></p>
                        </label>
                    </fieldset>
                    {/*<fieldset className="block-input">
                        <legend className="legend">E-mail для отправки счета*</legend>
                        <input type="email" name="" id="" className="input-challenges" placeholder="info@site.ru"/>
                    </fieldset>
                    <fieldset className="block-input">
                        <legend className="legend">Телефон для связи*</legend>
                        <input type="tel" name="" id="" className="input-challenges" placeholder="+7(900) 000-00-00"/>
                    </fieldset>*/}
                    <input type="submit" className="btn-main"  value={showSpinner ? "Подождите..." : "Сформировать"} onClick={() => goPay()} disabled={showSpinner} />
                </div>
            </div>
        </div>
    )
}