import React, {useEffect} from "react";
import SessionModel from "../../../stores/SessionModel";
import {ProfileAdminHeader} from "../admin/ProfileAdminHeader";
import {ProfileUniversityHeader} from "../university/ProfileUniversityHeader";
import {ProfileBusinessHeader} from "../business/ProfileBusinessHeader";
import {ProfileIndividualHeader} from "../personal/profile/ProfileIndividualHeader";

export const OrgHeader:React.FC<{preview: any}> = ({preview}) => {

    useEffect(() => {
        if (!SessionModel.isOrg() && !SessionModel.isAdmin() && !SessionModel.isSub()) {
            window.location.href = '/404';
        }
    }, []);

    if (SessionModel.isAdmin()) {
        return <ProfileAdminHeader/>
    } else if (SessionModel.isOrg()) {
        if (SessionModel.isBsn()) {
            return <ProfileBusinessHeader newPreview={preview}/>
        } else {
            return <ProfileUniversityHeader newPreview={preview}/>
        }
    } else if (SessionModel.isSub()) {
        return <ProfileIndividualHeader newPreview={preview}/>
    } else {
        return <></>
    }
}