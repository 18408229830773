import React, {useEffect, useState} from "react";
import {IBIllTransaction} from "../../../types/IBIllTransaction";
import {formatDate} from "../../../helpers/DateHelper";
import SessionModel from "../../../../stores/SessionModel";
import * as PaymentAPI from "../../../../requests/PaymentAPI";
import {ModalMessage} from "../../common/ModalMessage";
import {formatNumber} from "../../../helpers/Util";

export const BillList = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState("");
    const [billList, setBillList] = useState([]);

    useEffect(() => {
        if (!SessionModel.isAdmin()) {
            window.location.href = "/404";
        }
        setShowSpinner(true);
        PaymentAPI.getBillTransactions((response) => {
            if (response.status === 200) {
                setBillList(response.data);
                setShowSpinner(false);
            } else {
                setShowSpinner(false);
                setError("Ошибка при загрузке счетов");
            }
        });
    }, []);


    const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowSpinner(true);
        const fileList = e.target.files;
        if (!fileList) {
            setShowSpinner(false);
            return;
        }
        const formData = new FormData();
        formData.append("file", fileList[0], fileList[0].name);
        PaymentAPI.uploadBills(formData, (response) => {
           if (response.status === 200) {
               window.location.reload();
           } else {
               setShowSpinner(false);
               setError("Ошибка загрузки счетов");
           }
        });
    }

    const getErrorDescription = (bill: IBIllTransaction) : string => {
        if (bill.amount !== bill.actualAmount) {
            return "Ошибка: суммы не совпадают"
        } else if (bill.internalInn !== bill.corrInn) {
            return "Ошибка: ИНН не совпадают (платеж проведен)"
        } else {
            return "Ошибка";
        }
    }

    if (showSpinner) {
        return (
            <div className="load-block">
                <p className="load-sheet">
                    загрузка
                </p>
                <div className="circle-block">
                    <div className="circle-load one-load"></div>
                    <div className="circle-load two-load"></div>
                    <div className="circle-load three-load"></div>
                </div>
            </div>
        )
    } else if (SessionModel.isAdmin()) {
        return (
            <div className="left-block-rating">

                <header className="header-rating">
                    <div className="user-info-rating">
                        <p className="fio-or-name">Счета</p>
                        <div className="btn-flex-rating">
                            <label htmlFor="upload" className="btn rating-btn">
                                Загрузить счета
                                <input type="file" accept=".xlsx" onChange={(e) => handleUpload(e)} name="" id="upload" className="hidden"/>
                            </label>
                        </div>
                    </div>

                    <ul className="nav-block-center">
                        <li className="nav-center-item">
                            <a href="?tab=all" className="link-nav-center-item">Все</a>
                        </li>
                    </ul>
                </header>

                <main className="content-rating">
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="main-list-center-rating">
                        <div className="rating-info">
                            {billList.length === 0 ? (
                                <div className="first-message-error">
                                    <p className="fio-or-name">
                                        Счета не найдены
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <div className="rating-row-header">
                                        <p className="rating-cell-header point">
                                            #
                                        </p>
                                        <p className="rating-cell-header position">
                                            Дата операции
                                        </p>
                                        <p className="rating-cell-header position">
                                            Контрагент
                                        </p>
                                        <p className="rating-cell-header fio-rating">
                                            Счет
                                        </p>
                                        <p className="rating-cell-header position">
                                            ИНН
                                        </p>
                                        <p className="rating-cell-header position">
                                            ИНН факт.
                                        </p>
                                        <p className="rating-cell-header position">
                                            Сумма
                                        </p>
                                        <p className="rating-cell-header position">
                                            Статус
                                        </p>
                                        <p className="rating-cell-header fio-rating">
                                            Назначение платежа
                                        </p>
                                    </div>

                                    {billList?.map((bill : IBIllTransaction, index) =>
                                        <div key={index} className={bill.status === 3 || (bill.corrInn && bill.internalInn != bill.corrInn) ? "rating-row incorrect-tin" : "rating-row"}>
                                            <p className="rating-cell point">
                                                <a href={"/payment/bill/" + bill.id} target="_blank" className="active-link">{"BRM-" + bill.number}</a>
                                            </p>
                                            <p className="rating-cell position">
                                                {bill.operationDate ? formatDate(bill.operationDate) : ""}
                                            </p>
                                            <p className="rating-cell position">
                                                {bill.corrName}
                                            </p>
                                            <p className="rating-cell fio-rating">
                                                {bill.corrAcc}
                                            </p>
                                            <p className="rating-cell position">
                                                <a href={"/profile/university/" + bill.userId} target="_blank" className="active-link">{bill.internalInn}</a>
                                            </p>
                                            <p className="rating-cell position" style={{color: bill.corrInn !== bill.internalInn ? "red" : ""}}>
                                                {bill.corrInn}
                                            </p>
                                            <p className="rating-cell position">
                                                {formatNumber((bill.actualAmount / 100).toString())}<br/>
                                                {formatNumber((bill.amount / 100).toString())}
                                            </p>
                                            <p className="rating-cell position">
                                                {bill.status === 0 ? "Новый" :
                                                 bill.status === 10 ? "Оплачен" : getErrorDescription(bill)}
                                            </p>
                                            <p className="rating-cell fio-rating">
                                                {bill.purpose}
                                            </p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </main>

            </div>
        );
    } else {
        return (<></>)
    }
}