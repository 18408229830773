import React, {useEffect, useState} from "react";
import {CTFInsiderRatingItem} from "./CTFInsiderRatingItem";
import {IDashboardResponse} from "../types/ctf/IDashboardResponse";

export const CTFInsiderRating:React.FC<{
    dashboard: IDashboardResponse,
    setDashboard: (board: IDashboardResponse) => void}> = ({dashboard, setDashboard}) => {

    const [showReset, setShowReset] = useState(false);

    useEffect(() => {
        let rst = false;
        dashboard.data.forEach(i => {
            if (i.visible !== undefined) {
                rst = true;
            }
        });
        setShowReset(rst);
    }, [dashboard]);

    const resetVisibility = () => {
        const items = dashboard.data;
        items.forEach(i => {
            i.visible = undefined;
        });
        setDashboard({...dashboard, data: items});
    }

    return (
        <div className="left-dashboard">
            <ul className="dashboard-list">
                <div className="header-dash-top">
                    <h2 className="heading-dash">
                        Топ-10
                    </h2>
                    <div className="btn-wrap-dash">
                        {
                            showReset ?
                                <div className="dash-select" onClick={resetVisibility}>
                                    Все
                                </div>
                                :
                                <></>
                        }
                        {/*                       <div className="dash-select">
                            Несколько
                        </div>*/}
                    </div>
                </div>
                {dashboard.data.map((d, i) => <CTFInsiderRatingItem
                    item={d}
                    setDashboard={setDashboard}
                    dashboard={dashboard}
                    reset={resetVisibility}
                    index={i}
                    key={i}/>)}
                <div className="dash-footer">
                    Вы можете смотреть график всех, одной или нескольких команд одновременно
                </div>
            </ul>
        </div>
    )
}