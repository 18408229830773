import React from "react";
import SessionModel from "../../../../stores/SessionModel";
import {NavigationSub} from "./NavigationSub";
import {NavigationParticipant} from "./NavigationParticipant";

export const NavigationIndividualMain:React.FC<{categoryCode?: string}> = ({categoryCode}) => {

  if (SessionModel.isSub()) {
    return <NavigationSub/>
  } else {
    return <NavigationParticipant categoryCode={categoryCode}/>
  }
}