import React, {createRef, RefObject, useEffect, useRef, useState} from "react";
import {CTFGraphGrid} from "./CTFGraphGrid";
import {CTFOutsiderRating} from "./CTFOutsiderRating";
import {CTFDetailModal} from "./CTFDetailModal";
import * as ChallengeAPI from "../../requests/ChallengeAPI";
import {IDashboardResponse} from "../types/ctf/IDashboardResponse";
import {CTFInsiderRating} from "./CTFInsiderRating";
import {IDashboardItem} from "../types/ctf/IDashboardItem";
import {useParams} from "react-router-dom";
import {toNumber} from "lodash";
import {IDashboardPlotData} from "../types/ctf/IDashboardPlotData";

interface IDot {
    left?: string;
    top?: string;
}
export const CTFDashboard = () => {

    const params = useParams();
    const xcord = 12; // 1%
    const ycord = 6;
    const colors = [
        'red',
        'rgb(255, 123, 0)',
        'rgb(255, 187, 0)',
        'rgb(74, 202, 0)',
        'rgb(0, 202, 175)',
        'rgb(1, 153, 255)',
        'rgb(1, 48, 255)',
        'rgb(132, 1, 255)',
        'rgb(255, 1, 242)',
        'rgb(177, 0, 103)'
    ]
    const dotsTemp: Array<IDot[]> = [[], [], [], [], [], [], [], [], [], []];
    const canvasRefs = useRef(new Map());
    const [dotsList, setDotsList] = useState<Array<IDot[]>>();
    const [dashboardData, setDashboardData] = useState<IDashboardResponse>();
    const [detailModal, setDetailModal] = useState<IDashboardItem | undefined>();
    const [detailModalIndex, setDetailModalIndex] = useState<number | undefined>();

    useEffect(() => {
        //setDotsList(dotsTemp);
        ChallengeAPI.getDashboard(toNumber(params.stageId), (response) => {
            setDashboardData(response.data);
        });
    }, []);

    useEffect(() => {
        initCanvas();
        dashboardData?.data.forEach((item, idx) => {
            if (idx < 10) {
                _cleanUp(idx);
                if (item.visible === undefined || item.visible) {
                    item.plotData.forEach((d, i) => {
                        drawLine(idx, d.startTime, d.startPoint, d.endTime, _calculateEndPoint(i, d)); //i == 0 ? d.endPoint : d.endPoint + item.plotData[i - 1].endPoint
                    })
                }
            }
        })
    }, [dashboardData]);

    const initCanvas = () => {
        const maxLines = dashboardData && dashboardData?.data.length > 10 ? 10 : dashboardData?.data.length || 0;
        for (let i = 0; i < maxLines; i++) {
            canvasRefs.current.get(i).getContext('2d').strokeStyle = colors[i];
        }
    }

    const showModal = (index: number | undefined, item?: IDashboardItem | undefined) => {
        setDetailModalIndex(index);
        setDetailModal(item);
    }

    const _calculateEndPoint = (dataIdx: number, data: IDashboardPlotData) => {
        switch (dataIdx) {
            case 0: return data.endPoint;
            default: return data.startPoint + data.endPoint;
        }
    }

    const drawLine = (index: number, startX: number, startY: number, endX: number, endY: number) => {
        _drawLine(index, startX, startY, endX, endY).then(() => setDotsList(dotsTemp));
    }

    const _drawLine = (index: number, startX: number, startY: number, endX: number, endY: number) => {
        canvasRefs.current.get(index).getContext('2d').beginPath();
        canvasRefs.current.get(index).getContext('2d').moveTo(xcord * _calculateXPercent(startX), ycord * _calculateYPercent(startY));
        canvasRefs.current.get(index).getContext('2d').lineTo(xcord * _calculateXPercent(endX), ycord * _calculateYPercent(endY));
        canvasRefs.current.get(index).getContext('2d').stroke();
        canvasRefs.current.get(index).getContext('2d').closePath();
        return new Promise((resolve, reject) => { resolve(dotsTemp[index].push({left: _calculateXPercent(endX) + "%", top: _calculateYPercent(endY) + "%"})) });
    }

    const _cleanUp = (index: number) => {
        const ctx = canvasRefs.current.get(index).getContext('2d');
        ctx.clearRect(0, 0, canvasRefs.current.get(index).width, canvasRefs.current.get(index).height);
    }

    const _calculateXPercent = (x: number) => {
        return dashboardData ? 100 / dashboardData.maxTime * x : 0;
    }

    const _calculateYPercent = (y: number) => {
        return dashboardData ? 100 / dashboardData.maxPoints * y : 0;
    }

    if (dashboardData) {
        return (
            <div className="dashboard">
                <div className="header-dashboard">
                    <img src="/images/logo_braim.svg" alt="" className="logo-braim-dash"/>
                </div>
                <div className="container-dashboard">
                    <h2 className="headline-right-block">
                        {dashboardData.challengeName}
                    </h2>
                    <div className="wrap-dash">
                        <CTFInsiderRating dashboard={dashboardData} setDashboard={setDashboardData}/>

                        <div className="right-block-dash">
                            <div className="dahsboard-right">
                                <div className="graph-container">
                                    <div className="graph-position">
                                        <ul className={`grapth-dot-list`}>
                                        {Array.from(Array(10), (e, i) => {
                                            return <React.Fragment key={i}>
                                                {dotsList && dotsList[i].map((dot, index) =>
                                                    <li className={`graph-dot top-${i + 1}`} key={`${i}-${index}`} style={{left: dot.left, top: dot.top}} onClick={() => showModal(index, dashboardData?.data[i])}/>
                                                )}
                                            </React.Fragment>
                                        })}
                                        </ul>

                                        {Array.from(Array(10), (e, i) => {
                                           return <canvas id={`graph${i+1}`} width="1200px" height="600px" key={i} ref={el => {canvasRefs.current.set(i, el);}}/>
                                        })}
                                    </div>
                                </div>
                                <CTFDetailModal item={detailModal} flagIndex={detailModalIndex} setItem={showModal}/>
                                <CTFGraphGrid maxPoints={dashboardData.maxPoints} startDate={dashboardData.startDate} endDate={dashboardData.endDate}/>
                            </div>

                            <CTFOutsiderRating data={dashboardData.data}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}