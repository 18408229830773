import React, {useEffect, useState} from "react";
import {ProfileAdminHeader} from "../admin/ProfileAdminHeader";
import {ProfileNavigationAdmin} from "../admin/ProfileNavigationAdmin";
import SessionModel from "../../../stores/SessionModel";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";

export const UnloadsAdmin = () => {

    const [unloadingUniversities, setUnloadingUniversities] = useState(false);
    const [unloadingStudents, setUnloadingStudents] = useState(false);
    const [unloadingBusiness, setUnloadingBusiness] = useState(false);
    const [unloadingMentors, setUnloadingMentors] = useState(false);
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        if (SessionModel.getCurrentUserAuthData().type !== "ADMIN" && !SessionModel.isModerator()) {
            localStorage.getItem("token");
            window.location.href = "/";
        }
    }, []);

    const unloadUniversities = () => {
        if (unloadingUniversities)
            return;
        setUnloadingUniversities(true);
        ChallengeAPI.unloadUniversities(password, (status) => {
            setPassword("");
            setUnloadingUniversities(false);
            if (status === 400) {
                setError("Неправильный пароль");
            }
        });
    }

    const unloadStudents = (visibleOnly: number) => {
        if (unloadingStudents)
            return
        setUnloadingStudents(true);
        ChallengeAPI.unloadStudents(password, visibleOnly,(status) => {
            setPassword("");
            setUnloadingStudents(false);
            if (status === 400) {
                setError("Неправильный пароль");
            }
        });
    }

    const unloadBusiness = () => {
        if (unloadingBusiness)
            return;
        setUnloadingBusiness(true);
        ChallengeAPI.unloadBusiness(password, (status) => {
            setPassword("");
            setUnloadingBusiness(false);
            if (status === 400) {
                setError("Неправильный пароль");
            }
        });
    }

    const unloadMentors = () => {
        if (unloadingMentors)
            return;
        setUnloadingMentors(true);
        ChallengeAPI.unloadMentors(password, (status) => {
            setPassword("");
            setUnloadingMentors(false);
            if (status === 400) {
                setError("Неправильный пароль");
            }
        });
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../public/images/main_video.mp4").default}/>
            </video>
            <div className="left-block">
                <ProfileAdminHeader/>
                <main className="content">
                    <ProfileNavigationAdmin />
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Выгрузки</p>
                            </div>
                        </div>
                        <ul className="challenge-info">
                            <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : "", cursor: "default"}} dangerouslySetInnerHTML={{__html: error || ""}}></p>
                            <input type="password" value={password} placeholder="Введите пароль" onChange={(e) => setPassword(e.target.value)} className="input-challenges" style={{display: "inline-block"}}/>
                            <li className="challenge-card" style={{display: "inline-block"}}>
                                <button onClick={() => unloadUniversities()} className="challenge-activity" style={{marginRight: "10px"}} disabled={unloadingUniversities}>{unloadingUniversities ? "Выгружаются..." : "Выгрузить ВУЗЫ"}</button>
                                <button onClick={() => unloadBusiness()} className="challenge-activity" style={{marginRight: "10px"}} disabled={unloadingBusiness}>{unloadingBusiness ? "Выгружаются..." : "Выгрузить организации"}</button>
                                {SessionModel.isAdmin() ?
                                    <>
                                        <button onClick={() => unloadStudents(0)} className="challenge-activity" disabled={unloadingStudents}>{unloadingStudents ? "Выгружаются..." : "Выгрузить студентов"}</button>
                                        <button onClick={() => unloadStudents(1)} className="challenge-activity" disabled={unloadingStudents}>{unloadingStudents ? "Выгружаются..." : "Выгрузить студентов по ленточным"}</button>
                                        <button onClick={() => unloadMentors()} className="challenge-activity" disabled={unloadingMentors}>{unloadingMentors ? "Выгружаются..." : "Выгрузить наставников"}</button>
                                    </> : <></>}
                            </li>
                            <li className="challenge-card">
                            </li>
                        </ul>
                    </div>
                </main>
            </div>
        </div>
    );
}