import React, {useEffect, useState} from "react";
import API from "../../../../../requests/_axios";
import {ITeamAdRequest} from "../../../../types/ITeamAdRequest";
import {ITeamAd} from "../../../../types/ITeamAd";
import {ModalMessage} from "../../../common/ModalMessage";

export const RecruitmentPanel: React.FC<{
  challengeId: number | undefined,
  teamId: number | undefined,
  userId: number | undefined,
  adId: number | undefined}> =
    ({challengeId, userId, teamId, adId}) => {

  const [teamAdRequest, setTeamAdRequest] = useState<ITeamAdRequest>();
  const [ads, setAds] = useState([]);
  const [showInvitationError, setShowInvitationError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [disableButtons, setDisableButtons] = useState(false);

  useEffect(() => {
    fetchRequest();
    if (userId) {
      fetchAds();
    }
  }, [challengeId, userId]);

  const fetchAds = () => {
    API.get("/challenge/api/v1/challenge/" + challengeId + "/ad/my").then((response) => {
      if(response.status === 200)
        setAds(response.data);
    });
  }

  const fetchRequest = () => {
    API.get(`/challenge/api/v1/challenge/${challengeId}/ad/my/requests/user/${userId ? userId : 0}?teamId=${teamId ? teamId : ""}`).then((response) => {
      if(response.status === 200) {
        setTeamAdRequest(response.data);
      }
    });
  }

  const openInvitePopup = () => {
    setShowInvitationError(false);
    if (!teamId) {
      if (ads.length === 0) {
        setShowInvitationError(true);
      } else {
        setShowPopup(true);
      }
    } else {
      submitRequest(adId || 0);
    }
  }

  const submitRequest = (adId: number) => {
    setDisableButtons(true);
    API.post(`/challenge/api/v1/challenge/${challengeId}/ad/my/requests`,
        {instanceId: teamId ? teamId : userId, teamAdId: adId, type: teamId ? "TO_TEAM" : "FROM_TEAM"}).then((response) => {
      if(response.status === 200) {
        fetchRequest();
      }
      setDisableButtons(false);
    });
  }

  const acceptRequest = () => {
    setDisableButtons(true);
    API.post(`/challenge/api/v1/challenge/${challengeId}/ad/my/requests/accept`,
        {userId: teamId ? null : userId, teamId: userId ? null : teamId}).then((response) => {
          if (response.status === 200) {
            window.location.href = `/challenge/${challengeId}/my/team`;
          } else {
            if (response.data.code === "009-025") {
              setMessage("Вы не можете принять запрос, поскольку достигли максимального кол-ва участников команды");
            }
          }
          setDisableButtons(false);
    })
  }

  const rejectRequest = () => {
    API.post(`/challenge/api/v1/challenge/${challengeId}/ad/my/requests/reject`,
        {userId: teamId ? null : userId, teamId: userId ? null : teamId}).then((response) => {
          setDisableButtons(true);
      if (response.status === 200)
        window.location.reload();
      setDisableButtons(false);
    })
  }

  if (teamAdRequest && teamAdRequest.type === "FROM_TEAM" && userId) {
    return (
        <button className="btn-main" type="submit" onClick={rejectRequest} disabled={disableButtons}>{disableButtons ? "Подождите..." : "Отозвать приглашение"}</button>
    )
  } else if (teamAdRequest && teamAdRequest.type === "TO_TEAM" && teamId) {
    return (
        <button className="btn-main" type="submit" onClick={rejectRequest} disabled={disableButtons}>{disableButtons ? "Подождите..." : "Отозвать заявку"}</button>
    )
  } else if (teamAdRequest && ((teamAdRequest.type === "TO_TEAM" && userId) || (teamAdRequest?.type === "FROM_TEAM" && teamId))) {
    return (
        <>
          <ModalMessage msg={message} header={"Ошибка"} _callback={() => setMessage("")}/>
          <div className="btn-wrapper">
            <button className="btn-main" onClick={acceptRequest} disabled={disableButtons}>{disableButtons ? "Подождите..." : "Подтвердить"}</button>
            <button className="btn-no" onClick={rejectRequest} disabled={disableButtons}>{disableButtons ? "Подождите..." : "Отказаться"}</button>
          </div>
        </>
    )
  } else if (!teamId) {
      return (
          <>
            <p className="error-message" id="form-validation-error" style={{display: showInvitationError ? "" : "none"}}>Вначале вам необходимо создать объявление в разделе "Объявления", чтобы участник понимал, для выполнения каких задач вы приглашаете его в команду</p>
            <fieldset className="block-input" style={{display: showPopup ? "" : "none"}}>
              <legend className="legend">Выберите объявления</legend>
              <div className="container-ads">
                {ads && ads.map((ad: ITeamAd, key) =>
                  <div key={key} className="ads-input" onClick={() => {if(window.confirm(`Вы действительно желаете отправить приглашение по объявлению "${ad.name}"?`)){submitRequest(ad.id || 0)}}}>
                    {disableButtons ? "Подождите..." : ad.name}
                  </div>
                )}
                <div className="ads-input" onClick={() => setShowPopup(false)}>
                  Отмена
                </div>
              </div>
            </fieldset>
            <button className="btn-main" type="submit" onClick={openInvitePopup} style={{display: showPopup ? "none" : ""}}>{disableButtons ? "Подождите..." : "Подать заявку"}</button>
          </>
      )
  } else if (teamId) {
    return (
        <>
          <button className="btn-main" type="submit" onClick={openInvitePopup}>{disableButtons ? "Подождите..." : "Подать заявку"}</button>
        </>
    )
  } else {
    return (
        <></>
    )
  }
}