import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as CertificateAPI from "../../../../requests/CertificateAPI";
import {IChallengeCertificate} from "../../../types/IChallengeCertificate";
import {CONFIG} from "../../../../CONFIG";
import {formatDate} from "../../../helpers/DateHelper";
import {ModalMessage} from "../ModalMessage";

export const CertificateView = () => {

    const params = useParams();
    const [certificate, setCertificate] = useState<IChallengeCertificate>();
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (params.id) {
            CertificateAPI.getChallengeCertificateView(params.id, (response) => {
                if (response.status === 200)
                    setCertificate(response.data);
                else
                    window.location.href = '/404';
            })
        }

        const handleContextMenu = (e: Event) => {
            e.preventDefault()
        }

        document.addEventListener("contextmenu", handleContextMenu)

        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, [])



    const getDocName = () => {
        if (certificate?.type === "CHARTER")
            return "Грамота";
        else if (certificate?.type === "DIPLOMA")
            return "Диплом";
        else
            return "Сертификат";
    }


    if (certificate) {

        return (
            <div className="cert-body-full">
                <div className="how-print no-print" style={{cursor: "pointer"}} onClick={() => setMessage("Зайдите в меню вашего браузера с ПК или ноутбука и выберите пункт «Печать». <br/><br/>" +
                    "Затем выберите принтер «Сохранить как PDF».<br/><br/>" +
                    "В дополнительных настройках* установите: <br/>" +
                    "размер бумаги «A4»; <br/>" +
                    "поля «Нет»; <br/>" +
                    "масштаб «Персонализированные» значение 100%. <br/>" +
                    "Установите галочку «Фон» и нажмите «Сохранить». <br/> " +
                    "Сохранённый файл можно печатать. Перед печатью убедитесь, что QR-код не обрезан. <br/><br/>" +
                    "*наименование настроек может отличаться в разных браузерах.<br/><br/>" +
                    "Для заказа оригинала обратитесь к организатору.")}>Инструкция по печати</div>
                <ModalMessage msg={message} header={"Инструкция"} _callback={() => setMessage("")}/>
                {/*<div className="how-print-popup" style={{display: showInstruction ? "" : "none"}}>
                    <div className="close-window" onClick={() => setShowInstruction(false)}></div>
                    <div className="how-print-block-popup">
                        <p className="how-print-info-text">
                            Выберите пункт "Печать" в вашем браузере. Принтер "Сохранить как PDF". Настройки
                            принтера используете как изображении ниже
                        </p>
                        <img src="/images/print.jpg" className="how-print-info"/>
                    </div>
                </div>*/}
                <div className="container-cert-full">
                    <div className="cert-top-block-full">
                        <div className="qr-cert-info-full">
                            <img src={"data:image/png;base64," + certificate.qr} alt="" className="qr-cert-full"/>
                            <p className="cert-number">
                                {formatDate(certificate.data?.createdAt || "")}
                            </p>
                        </div>
                        <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.background}`} alt="" className="cert-image-main-full"/>
                        <h6 className="cert-sheet-heading">
                            {getDocName()}
                        </h6>
                        {/* @ts-ignore */}
                        <h7 className="cert-challenge-name-full">
                            {certificate.challengeName}
                            {/* @ts-ignore */}
                        </h7>
                        <div className="cert-top-block-info-full">
                            <p className="cert-text-info-full">
                                подтверждает, что
                            </p>
                            <p className="cert-top-block-name-full">
                                {`${certificate.data?.lastname} ${certificate.data?.firstname} ${certificate.data?.patronymic || ""}`}
                            </p>
                            <p className="cert-text-bottom-full">
                                {certificate.body}
                            </p>
                        </div>
                    </div>
                    <div className="cert-bottom-block-full">
                        <div className="podpisant-info-full">
                            <p className="cert-text-signatory-full">
                                {certificate.signedBy}
                            </p>
                            <p className="cert-text-jobtitle-full">
                                {certificate.signedByPosition}
                            </p>
                        </div>
                        <div className="about-braim-full">
                            <div className="logo-braim-cert-full">
                                <img src="/images/logo_plat.svg" alt=""/>
                                    <p className="about-braim-text-full">
                                        Создано на braim.org
                                    </p>
                            </div>
                            <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.logo}`} alt="" className="logo-cert-full"/>
                        </div>
                        <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.signature}`} alt="" className="podpis-full" onDragStart={(event)=> event.preventDefault()}/>
                        <img src={`${CONFIG.STORAGE_URL}/certificate/${certificate.userId}/challenge/${certificate.challengeId}/certificate/${certificate.stamp}`} alt="" className="pechat-full" onDragStart={(event)=> event.preventDefault()}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}