import React, {useEffect} from "react";
import {ITestResult} from "../../../types/ITestResult";
import {Answer} from "./Answer";
import * as TestAPI from "../../../../requests/TestAPI";
import {AnswerList} from "./AnswerList";

export const TestAdminResult: React.FC<{result: ITestResult, reset: () => void}> = ({result, reset}) => {

    const doNothing = (id: string) => {}

    return (
        <div className="container-right-block">
            <div className="menu-challenge">
                <h2 className="headline-right-block">
                    Тестирование завершено
                </h2>
                <div className="stage-button">
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            {result.questions.length > 0 ? "Ниже представлены вопросы, на которые вы дали неверные ответы" : "Вы ответили правильно на все вопросы"}
                        </p>
                    </div>
                    {result.questions?.map((question, index) =>
                    <div key={index}>
                        {question.question.image ? (
                            <div className="container-img-test">
                                <img src={question.question.image} alt="" className="img-test-question-background"/>
                                <img src={question.question.image} alt="" className="img-test-question"/>
                            </div>
                        ) : ""}
                        <h2 className="headline-right-block">
                            {question?.question.name}
                        </h2>
                        <p className="weight-question">
                            За ответ на этот вопрос вы получите
                            <span className="weight-question-num"> {question?.question.points}</span> баллов
                        </p>
                        <fieldset className="block-input">
                            <legend className="legend">
                                {question?.question.type === "SINGLE" ? "Выберите один из вариантов" :
                                    question.question.type === "INPUT" ? "Введите ответ" : "Выберите несколько вариантов"}
                            </legend>
                            {
                                question?.question.answers?.map((qa, key) =>
                                        <AnswerList answer={qa} answerKey={key} type={question?.question.type} pick={doNothing} fill={doNothing}/>
                                    // <Answer answerKey={key} answer={qa} type={question?.question.type} pick={doNothing}/>
                                )
                            }
                        </fieldset>
                    </div>
                    )}
                    <input className="btn-blue" type="button" onClick={reset} value="Пройти заново"/>
                </div>
            </div>
        </div>
    )
}