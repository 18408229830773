import React, {useState} from "react";
import {IFillBalance} from "../../../../types/IFillBalance";
import {toNumber} from "lodash";
import * as WalletAPI from "../../../../../requests/WalletAPI";
import {ModalMessage} from "../../ModalMessage";
import {CONFIG} from "../../../../../CONFIG";


export const CardPayment: React.FC<{visible: boolean, setVisible: (visibility: boolean) => void}> = ({visible, setVisible}) => {

    const [fillRequest, setFillRequest] = useState<IFillBalance>();
    const [error, setError] = useState("");
    const [showSpinner, setShowSpinner] = useState(false);
    const [acceptOffer, setAcceptOffer] = useState(true);

    const goPay = () => {
        if (fillRequest?.amount && fillRequest.amount > 0) {
            if (!acceptOffer) {
                setError("Перед оплатой счета необходимо согласиться с офертой");
                return;
            }
            setShowSpinner(true);
            fillRequest.amount = fillRequest.amount * 100;
            WalletAPI.fill(fillRequest, (response) => {
                if (response.status === 200) {
                    window.location.href = response.data;
                } else {
                    setError("Ошибка при получении ссылки на оплату");
                    setShowSpinner(false);
                }
            });
        } else {
            setError("Введите корректную сумму");
        }
    }

    return (
        <div className="right-block" style={{right: visible ? "0vw" : "-100vw"}}>
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <header className="header">
                <p className="header-back" id="backmenu" onClick={() => setVisible(false)}>Назад</p>
                <p className="name-menu">Пополнение счета</p>
            </header>
            <div className="container-right-block">
                <div className="menu-challenge">
                    <div className="row-wrapper">
                        <p className="number-question">
                            Пополнить баланс
                        </p>
                    </div>
                    <h2 className="headline-right-block">
                        Оплата картой
                    </h2>
                    <div className="btn-info">
                        <h4 className="btn-info-heading">
                            Внимание
                        </h4>
                        <p className="btn-info-text">
                            Мы не сохраняем и не привязываем данные Ваших карт
                            на платформу. При оплате картой Вы будете переведены на
                            безопасную страницу оплаты.
                        </p>
                    </div>
                    <fieldset className="block-input">
                        <legend className="legend">Сумма для зачисления*</legend>
                        <input type="number" min={1} onChange={(e) => setFillRequest({...fillRequest, amount: toNumber(e.target.value), paymentSource: "CARD"})} className="input-challenges" placeholder="Введите сумму"/>
                        <label className="label-checkbox-radio-center">
                            <input type="checkbox" name="email-ads" checked={acceptOffer} className="checkbox-radio-center" onChange={() => setAcceptOffer(!acceptOffer)}/>
                            <p>Оплачивая счет, я соглашаюсь с <a href={`${CONFIG.STORAGE_URL}/offer_braim.pdf`} target="_blank" className="active">офертой</a></p>
                        </label>
                    </fieldset>
                    <input type="submit" className="btn-main" value={showSpinner ? "Подождите..." : "Оплатить"} onClick={() => goPay()} disabled={showSpinner}/>
                </div>

            </div>
        </div>
    )
}