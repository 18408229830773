import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as PaymentAPI from "../../../../requests/PaymentAPI";
import {ModalMessage} from "../ModalMessage";
import {IBillData} from "../../../types/IBillData";
import {formatNumber} from "../../../helpers/Util";

export const BillTemplate = () => {

    const params = useParams();
    const [message, setMessage] = useState("");
    const [billData, setBillData] = useState<IBillData>();

    useEffect(() => {
        if (params.id) {
            PaymentAPI.getBill(params.id, (response) => {
                if (response.status === 200) {
                    setBillData(response.data);
                } else {
                    setMessage("Ошибка получения счета");
                }
            })
        }

        const handleContextMenu = (e: Event) => {
            e.preventDefault()
        }

        document.addEventListener("contextmenu", handleContextMenu)

        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, []);


    return (
        <div className="schet">
            <ModalMessage msg={message} header={"Ошибка"} _callback={() => setMessage("")}/>
            <div className="container-schet">
                <div className="header-schet no-print">
                    <img src="/assets/images/logo_braim.svg" alt="" className="logo-schet no-print"/>
                </div>
                <h1 className="schet-heading no-print">
                    Счет на оплату заказа
                </h1>
                <p className="schet-text no-print">
                    Для оплаты распечатайте эту страницу и проведите платеж. После этого сообщите нам об оплате,
                    чтобы мы ожидали поступления средств. Как только перевод поступит, мы сообщим вам об этом.
                </p>
                <div className="button-print no-print" onClick={() => window.print()} style={{cursor: "pointer"}}>
                    Распечатать
                </div>
                <div className="button-print no-print" onClick={() => window.location.href = "/plan?tab=enroll"} style={{cursor: "pointer"}}>
                    Назад
                </div>
                <p className="schet-text-red no-print">
                    В назначении каждого платежа указывайте: номер счета, наименование услуги или лицензии.
                    Платежи без указания этих данных не будут применены для заказанных услуг.
                </p>
                <div className="schet-block">
                    <div className="schet-block-header">
                        <div className="schet-rekvizity">
                            <p className="schet-rekvizity-text">
                                {billData?.name}
                            </p>
                            <p className="schet-rekvizity-text">
                                350002, Краснодарский край, г Краснодар, Северная ул, д. 405, офис 201
                            </p>
                            <p className="schet-rekvizity-text">
                                Тел .: +7 (499) 703-39-49
                            </p>
                        </div>
                        <img src={"data:image/gif;base64," + billData?.qr} alt="" className="schet-qr"/>
                    </div>
                    <h3 className="schet-heading-second">
                        Образец заполнения платежного поручения
                    </h3>
                    <table className="full-table">
                        <tr className="table">
                            <td className="table first-td-one">
                                ИНН {billData?.inn}
                            </td>
                            <td className="table first-td-one">
                                КПП {billData?.kpp}
                            </td>
                            <td rowSpan={2} className="table first-td-two">
                                Сч.№
                            </td>
                            <td rowSpan={2} className="table first-td-three">
                                {billData?.accountNumber}
                            </td>
                        </tr>
                        <tr className="table">
                            <td colSpan={2} className="table first-td-one">
                                <p>
                                    Получатель
                                </p>
                                <p>
                                    {billData?.name}
                                </p>
                            </td>
                        </tr>
                        <tr className="table">
                            <td colSpan={2} rowSpan={2} className="table first-td-one">
                                <p>
                                    Банк получателя
                                </p>
                                <p>
                                    {billData?.bankName}
                                </p>
                            </td>
                            <td className="table first-td-two">
                                БИК
                            </td>
                            <td rowSpan={2} className="table first-td-one">
                                <p>
                                    {billData?.bik}
                                </p>
                                <br/>
                                <p>
                                    {billData?.correspondentNumber}
                                </p>
                            </td>
                        </tr>
                        <tr className="table">
                            <td className="table first-td-two">
                                Сч.№
                            </td>
                        </tr>
                    </table>

                    <p className="schet-big-heading">Счет №&nbsp;
                        <span className="shet-number">{billData?.billNumber}</span>
                        &nbsp;от&nbsp;
                        <span className="sсhet-date">
                            {billData?.billDate}
                        </span>
                    </p>
                    <p className="schet-rekvizity-text">
                        <span className="poluchatel">&nbsp;{billData?.name}&nbsp;</span>
                        <span className="inn">&nbsp;{billData?.inn}</span>
                    </p>
                    <p className="schet-rekvizity-text">
                        <span className="payer">&nbsp;{billData?.payerOrgName}&nbsp;</span>
                        <span className="inn">&nbsp;{billData?.payerInn}</span>
                    </p>
                    <table className="full-table">
                        <tr className="table">
                            <td className="table small-col">
                                №
                            </td>
                            <td className="table big-col">
                                Наименование товаров и услуг
                            </td>
                            <td className="table small-col">
                                Ед. изм
                            </td>
                            <td className="table small-col">
                                Кол-во
                            </td>
                            <td className="table">
                                Цена
                            </td>
                            <td className="table">
                                Сумма
                            </td>
                        </tr>
                        <tr className="table">
                            <td className="table small-col">
                                1
                            </td>
                            <td className="table big-col">
                                {billData?.purpose}
                            </td>
                            <td className="table small-col">
                                шт.
                            </td>
                            <td className="table small-col">
                                1
                            </td>
                            <td className="table">
                                {formatNumber(billData?.amount)}
                            </td>
                            <td className="table">
                                {formatNumber(billData?.amount)}
                            </td>
                        </tr>
                        <tr className="table">
                            <td colSpan={5} className="table small-col">
                                Итого
                            </td>
                            <td className="table">{formatNumber(billData?.amount)}</td>
                        </tr>
                    </table>

                    <p className="schet-rekvizity-text">
                        <span className="schet-quant">
                            &nbsp;1,&nbsp;
                        </span>
                        <span className="schet-summ">
                            &nbsp;{formatNumber(billData?.amount)}
                        </span>
                        {/*<p className="shet-summ-letter">Один миллион рублей 00 копеек</p>*/}
                    </p>

                    <div className="rukovoditel">
                        <p className="shalashniy">
                            Генеральный директор
                        </p>
                        <div className="line"></div>
                        <p className="shalashniy">
                            Шалашный Сергей Игоревич
                        </p>
                        <img src="/images/pechat_praim.png" alt="" className="schet-podpis" onDragStart={(event)=> event.preventDefault()}/>
                        <img src="/images/podpis.jpg" alt="" className="schet-pechat" onDragStart={(event)=> event.preventDefault()}/>
                    </div>
                </div>
            </div>
        </div>
    );
}