import React, {useEffect, useState} from "react";
import {IChallenge} from "../../types/IChallenge";
import SessionModel from "../../../stores/SessionModel";
import {CONFIG} from "../../../CONFIG";

export const Help: React.FC<{challenge: IChallenge | undefined}> = ({challenge}) => {

    const[showHelp, setShowHelp] = useState(false);
    const[challengeAdmin, setChallengeAdmin] = useState("");

    useEffect(() => {
        setChallengeAdmin(challenge?.adminTelegram || "");
    }, [challenge]);

    return (
        <div className="spravka">
            <p className="spravka-text" onClick={() => setShowHelp(!showHelp)} style={{display: showHelp ? "none" : ""}}>
                ?
            </p>
            <div className="spravka-text-block" style={{display: showHelp ? "" : "none"}}>
                {SessionModel.getCurrentUserAuthData().type === "PERSONAL" ? (
                    <>
                        {challenge ? <a href={`https://t.me/${challengeAdmin}`} target="_blank" className="spravka-link">Администратор конкурса</a> : <></>}
                        <a href={CONFIG.PLATFORM_ADMIN_USER_TG} target="_blank" className="spravka-link">Техподдержка Braim</a>
                        <a href="https://disk.yandex.ru/d/mhHsBkKAovviNA" target="_blank" className="spravka-link">Видеоинструкции</a>
                    </>
                ) : (<></>)}
                {SessionModel.getCurrentUserAuthData().type === "EDU" ? (
                    <>
                        <a href={CONFIG.PLATFORM_ADMIN_ORG_TG} target="_blank" className="spravka-link">Техподдержка Braim</a>
                    </>
                ) : (<></>)}
                {SessionModel.getCurrentUserAuthData().type === "BSN" ? (
                    <>
                        <a href={CONFIG.PLATFORM_ADMIN_ORG_TG} target="_blank" className="spravka-link">Техподдержка Braim</a>
                    </>
                ) : (<></>)}

                {/*<a href={SessionModel.getCurrentUserAuthData().type === "PERSONAL" ? (CONFIG.HOWTO_USER_LINK) : (CONFIG.HOWTO_ORG_LINK)*/}
                {/*} target="_blank" className="spravka-link">Как пользоваться платформой</a>*/}
                <a href="#" className="spravka-link" onClick={() => setShowHelp(false)}>Закрыть</a>
            </div>
        </div>
    );
}