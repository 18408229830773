import React, {useEffect, useState} from "react";
import API from "../../../../requests/_axios";
import SessionModel from "../../../../stores/SessionModel";
import {CONFIG} from "../../../../CONFIG";
import {IProfilePersonalForm} from "../../../types/IProfilePersonalForm";
import cookie from "js-cookie";

export const ProfileIndividualHeader: React.FC<{newPreview: any}> = ({newPreview}) => {

  const [form, setForm] = useState<IProfilePersonalForm>({
    firstname: "",
    lastname: "",
    patronymic: "",
    telegram: "",
    birthDate: "",
    skills: "",
    phone: "",
    fias: ""
  });

  const[preview, setPreview] = useState<any>();

  useEffect(() => {
    const interval = setInterval(() => {
      API.post("/sso/api/v1/user").then((response) => {
        if (response.status !== 200) {
          cookie.set("afterLoginRedirect", window.location.pathname);
          window.location.href="/signup";
        }
      })
    }, 60000);
  }, []);

  useEffect(() => {
    if (!SessionModel.isPersonal() && !SessionModel.isSub()) {
      alert("Для доступа к данной странице вы должны быть авторизованы на платформе в статусе \"Участник\".");
      window.location.href = "/profile";
    }
    let url = "/challenge/api/v1/personal/profile";
    API.get(url, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      if (response.status === 200) {
        setForm({...form,
          firstname: response.data.firstname,
          lastname: response.data.lastname
        });
        if(response.data.avatar !== null)
          setPreview(CONFIG.STORAGE_URL + "/" + SessionModel.getCurrentUserAuthData().id + "/avatar/" + response.data.avatar);
        else
          setPreview("/images/no_ava_user.svg");
      } else {
        window.location.href = "/profile";
      }
    });
    if(newPreview !== null) {
      setPreview(newPreview);
    }
  },[newPreview]);

  const showMenu = () => {
    // @ts-ignore
    document.querySelector(".menu").style.display = 'flex';
  }

  return (
      <header className="header">
        <div id="burger" className="burger" onClick={showMenu}>
          <span className="first-line"></span>
          <span className="two-line"></span>
          <span className="three-line"></span>
        </div>
        <img src={require("../../../../../public/images/logo_plat.svg").default} alt="" className="header-logo"/>
        <a href="/profile">
          <div className="username">
            <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} alt="" className="header-avatar"/>
            <p className="fio-or-name">Профиль</p>
          </div>
        </a>
      </header>
  )
}