import React, {useEffect, useState} from "react";
import {ModalMessage} from "../../common/ModalMessage";
import {useParams} from "react-router-dom";
import {IStageResult} from "../../../types/IStageResult";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import {toNumber} from "lodash";

interface ISRComment {
    comment?: string;
}
export const StageResultCommentModal:React.FC<{
    currentIndex: number | undefined,
    setCurrentIndex: (idx: number | undefined) => void,
    stageResults: IStageResult[],
    setStageResults: (results: IStageResult[]) => void}> = ({currentIndex, setCurrentIndex, stageResults, setStageResults}) => {

    const [error, setError] = useState("");
    const params = useParams();
    const [comment, setComment] = useState<ISRComment>();
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (currentIndex !== undefined) {
            setComment({...comment, comment: stageResults[currentIndex].comment});
        }
    }, [currentIndex]);

    const update = () => {
        if (currentIndex !== undefined) {
            ChallengeAPI.addComment(
                toNumber(params.id),
                toNumber(params.stageId),
                stageResults[currentIndex].id,
                comment, (response) => {
                    if (response.status === 200) {
                        const result = stageResults[currentIndex];
                        result.comment = comment?.comment;
                        stageResults[currentIndex] = result;
                        setStageResults(stageResults);
                        setCurrentIndex(undefined);
                    } else {
                        setError("Ошибка добавления комментария");
                    }
                }
            );
        }
    }

    return (
        <div className="rating-print-settings" style={{display: currentIndex !== undefined ? "" : "none"}}>
            <ModalMessage msg={error} header={"Инфо"} _callback={() => setError("")}/>
            <div className="rating-print-export">
                <a className="close-window" onClick={() => setCurrentIndex(undefined)} style={{cursor: "pointer"}}></a>
                <p className="fio-or-name">
                    Оставьте комментарий к результатам участника
                </p>
                <textarea rows={5} placeholder="Комментарий" className="input-challenges" onChange={(e) => setComment({...comment, comment: e.target.value})} value={comment?.comment}></textarea>
                <div className="flex-btn-stage">
                    <div className="flex-btn-template">
                        <a className="btn-blue-width" onClick={update}>Сохранить</a>
                        <a onClick={() => setCurrentIndex(undefined)} className="btn-blue-width">Отмена</a>
                    </div>
                </div>
            </div>
        </div>
    )
}