import React, {useEffect, useState} from "react";
import {CONFIG} from "../../../CONFIG";
import API from "../../../requests/_axios";
import {IChallengeUniversityAssumption} from "../../types/IChallengeUniversityAssumption";
import {formatDate} from "../../helpers/DateHelper";
import {getChallengeStatus} from "../../helpers/ChallengeHelper";
import SessionModel from "../../../stores/SessionModel";

export const UniversityChallengePicker = () => {

  const [assumptions, setAssumptions] = useState([]);
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (SessionModel.getCurrentUserAuthData().type !== "EDU") {
      window.location.href = "/";
    }
    fetchAssumptions();
  }, []);

  const fetchAssumptions = () => {
    API.get("/challenge/api/v1/university/profile/assumption", {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      if(response.status === 200) {
        setAssumptions(response.data)
      }
    });
  }

  const handleCheck = (id: number | undefined) => {
    setDisplayError(false);
    API.post(`/challenge/api/v1/university/profile/assumption/challenge/${id}`, {}, {headers: {"Authorization": "Bearer " + localStorage.getItem("token")}}).then((response) => {
      if(response.status === 200) {
        fetchAssumptions();
      } else {
        setDisplayError(true);
      }
    });
  }

  return (
      <div className="container-right-block">
      <div className="menu-challenge">
        <h2 className="headline-right-block">Мероприятия</h2>
        <p className="error-message" id="error-message" style={{display: displayError ? "" : "none"}}>Должен быть выбран хотя бы один конкурс</p>
        <ul className="challenge-info">
          {assumptions && assumptions.map((a: IChallengeUniversityAssumption, i) =>
              <li className="challenge-card">
                <div className="challenge-card-main">
                  <img
                      src={CONFIG.STORAGE_URL + "/" + a.challengeUserId + "/challenge/" + a.challengeId + "/avatar/" + a.avatar}
                      onError={({currentTarget}) => {
                        currentTarget.onerror = null;
                        currentTarget.src = "/images/no_ava_challenge.svg"
                      }} alt="" className="challenge-avatar"/>
                  <div className="user-info">
                    <p className="challenge-header">{a.name}</p>
                    <p className="challenge-company">{a.orgName || "Не указано"}</p>
                  </div>
                </div>
                <p className="challenge-date">
                  {getChallengeStatus(a)}
                </p>
                <p className="challenge-description">
                  {a.description}
                </p>
                <div className="challenge-information">
                  {
                    a.checked ? (
                        <>
                          <a href="#0" className="challenge-activity-no"
                             onClick={() => handleCheck(a.challengeId)}>
                            Участвуем
                          </a>
                        </>
                    ) : (
                        <>
                          <a href="#0" className="challenge-activity"
                             onClick={() => handleCheck(a.challengeId)}>
                            Выбрать конкурс
                          </a>
                        </>
                    )
                  }
                  <a href={a.site} target="_blank" className="challenge-activity" style={{display: a.site ? "" : "none"}}>
                    Сайт
                  </a>
                </div>
              </li>
          )}
        </ul>
      </div>
      </div>
  )
}