import React from "react";
import {formatDate, formatDateTime} from "../helpers/DateHelper";

export const CTFGraphGrid:React.FC<{
    maxPoints?: number,
    startDate: string,
    endDate: string}> = ({maxPoints, startDate, endDate}) => {

    if (maxPoints) {
        return (
            <>
                <ul className="graph-vert-line">
                    {Array.from(Array(11), (e, i) => {
                        return <li className="graph-vert-item" key={i}>{(maxPoints / 10 * i).toFixed(0)}</li>
                    })}
                </ul>
                <ul className="graph-gor-line">
                    <p className="graph-vert-item date-item-left">{formatDateTime(startDate)}</p>
                    <p className="graph-vert-item date-item-right">{formatDateTime(endDate)}</p>
                    {Array.from(Array(11), (e, i) => {
                        return <li className="graph-gor-item" key={i}><div className="dash-line-time"/></li>
                    })}
                </ul>
            </>
        )
    } else {
        return <></>
    }
}