import React from "react";
import {ITeam} from "../../../types/ITeam";
import {CONFIG} from "../../../../CONFIG";
import plural from "plural-ru";
import {IProfilePersonalForm} from "../../../types/IProfilePersonalForm";
import {ITeamResponse} from "../../../types/ITeamResponse";

export const TeamView: React.FC<{
    teamResponse: ITeamResponse | undefined,
    showProfilePanel: (profile: IProfilePersonalForm, team: ITeam | undefined, enableRecruitmentPanel: boolean) => void}> = ({teamResponse, showProfilePanel}) => {

    return (
        <div className="container-right-block">

            <div className="menu-challenge">
                <h2 className="headline-right-block">{teamResponse?.team.name}</h2>
                <ul className="responses-list">
                    <li className="respose-item">
                        <div className="username">
                            <img src={CONFIG.STORAGE_URL + "/" + teamResponse?.team.userId + "/team/" + teamResponse?.team.id + "/avatar/" + teamResponse?.team.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                                 alt="" className="header-avatar"/>
                            <p className="fio-or-name">{teamResponse?.team.name}</p>
                            <p className="city-student">{plural(teamResponse?.members.length || 0, "%d человек", "%d человека", "%d человек")}</p>
                        </div>

                        <div className="challenge-card">
                            {teamResponse?.members && teamResponse.members.map((member: IProfilePersonalForm, key) =>
                                <div key={key} className="challenge-card-mini">
                                    <img src={CONFIG.STORAGE_URL + "/" + member.userId + "/avatar/" + member.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                         alt=""
                                         onClick={() => showProfilePanel(member, undefined, false)}
                                         className="challenge-avatar" style={{cursor: "pointer"}}/>
                                    <div className="user-info">
                                        <p className="challenge-header" onClick={() => showProfilePanel(member, undefined, false)}>{member.firstname + " " + member.lastname}</p>
                                        <p className="city">{member.city}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}