import API from "./_axios";

export function getLanding(challengeId: number, _callback: (response: any) => void) {
    API.get(`/landing/api/v1/landing/${challengeId}`).then((response) => {
        _callback(response);
    });
}

export function initLanding(challengeId: number, _callback: (response: any) => void) {
    API.post(`/landing/api/v1/manage/${challengeId}/init`).then((response) => {
        _callback(response);
    });
}

export function publish(challengeId: number, _callback: (response: any) => void) {
    API.post(`/landing/api/v1/manage/${challengeId}/publish`).then((response) => {
        _callback(response);
    });
}