import React, {useEffect, useState} from "react";
import API from "../../../../requests/_axios";
import {IChallenge} from "../../../types/IChallenge";
import {useNavigate, useSearchParams} from "react-router-dom";
import {CONFIG} from "../../../../CONFIG";
import {ProfileIndividualHeader} from "../profile/ProfileIndividualHeader";
import {NavigationIndividualMain} from "../navigation/NavigationIndividualMain";
import {ChallengeInfo} from "./ChallengeInfo";
import {formatDate} from "../../../helpers/DateHelper";
import {Help} from "../../common/Help";
import {getChallengeStatus} from "../../../helpers/ChallengeHelper";

export const ChallengeListIndividual = () => {

  const navigate = useNavigate();
  const [challengeList, setChallengeList] = useState<any>([]);
  const [currentChallengeId, setCurrentChallengeId] = useState<any>(null);
  const [tab, setTab] = useState("current");
  const [cat, setCat] = useState("challenge");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    setTab(searchParams.get("tab") || "current");
    setCat(searchParams.get("cat") || "challenge");
    fetchChallenges(searchParams.get("tab") || "current", searchParams.get("cat") || "challenge");
  }, []);

  const fetchChallenges = (tab: string, cat: string) => {
    API.get(`/challenge/api/v1/challenge?filter=${tab}&cat=${cat}&search=${searchQuery}`).then((response => {
      setChallengeList(response.data);
    }));
  }

  const handleShowDetails = (id: number | undefined) => {
    if (tab === "my") {
      navigate(`/challenge/${id}/my`);
    }
    // @ts-ignore
    document.querySelector('.right-block').style.right = "-100vw";
    setCurrentChallengeId(id);
    // @ts-ignore
    document.querySelector('.right-block').style.right = "0";
  }

  const handleCloseDetails = () => {
    setCurrentChallengeId(null);
    // @ts-ignore
    document.querySelector('.right-block').style.right = "-100vw";
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setChallengeList([]);
      fetchChallenges(tab, cat);
    }
  }

  return (
      <div className="platform-background">
        <Help challenge={undefined}/>
        <video autoPlay muted loop playsInline className="image-back-video">
          <source src={require("../../../../../public/images/main_video.mp4").default}/>
        </video>
        <div className="left-block">
          <ProfileIndividualHeader newPreview={""}/>
          <main className="content">
            <NavigationIndividualMain />
            <div className="main-list-center">
              <div className="my-challenge">
                <div className="user-info">
                  <p className="fio-or-name">Мероприятия</p>
                </div>
                <ul className="nav-block-center">
                  {/*<li className="nav-center-item">
                    <a href="?tab=all" className={tab === "all" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Все</a>
                  </li>*/}
                  <li className="nav-center-item">
                    <a href={`?tab=current&cat=${cat}`} className={tab === "current" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Текущие</a>
                  </li>
                  <li className="nav-center-item">
                    <a href={`?tab=future&cat=${cat}`} className={tab === "future" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Будущие</a>
                  </li>
                  <li className="nav-center-item">
                    <a href={`?tab=finished&cat=${cat}`} className={tab === "finished" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Прошедшие</a>
                  </li>
                  <li className="nav-center-item">
                    <a href={`?tab=my&cat=${cat}`} className={tab === "my" ? ("link-nav-center-item") : ("link-nav-center-item non-active-nav")}>Участвую</a>
                  </li>
                </ul>
              </div>
                <ul className="challenge-info">
                  <input type="search" placeholder="Поиск" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchQuery(e.target.value);
                  }} onKeyDown={handleKeyDown} className="input-challenges"/>
                  {challengeList && challengeList.length === 0  && tab === "current" ? (
                      <>
                        <li className="first-message">
                          <p className="fio-or-name">
                            На текущий момент нет доступных мероприятий
                          </p>
                          <p className="city">
                            Возможно, скоро они появятся
                          </p>
                        </li>
                      </>
                  ) : (<></>)}
                  {challengeList && challengeList.length === 0  && tab === "my" ? (
                      <>
                        <li className="first-message">
                          <p className="fio-or-name">
                            Вы не выбрали ни одного мероприятия для участия
                          </p>
                          <p className="city">
                            Посмотреть список доступных мероприятий вы можете во вкладке "Все"
                          </p>
                        </li>
                      </>
                  ) : (<></>)}
                {challengeList && challengeList.map((challenge: IChallenge) =>
                        <li className="challenge-card">
                          <div className="challenge-card-main">
                            <img id={"challenge-avatar-" + challenge.id} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_challenge.svg"}}
                                 src={CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challenge.id + "/avatar/" + challenge.avatar} alt=""
                                 onClick={() => handleShowDetails(challenge.id)}
                                 className="challenge-avatar"/>
                              <div className="user-info">
                                <p className="challenge-header" onClick={() => handleShowDetails(challenge.id)}>{challenge.name}
                                </p>
                                <p className="challenge-company">{challenge.orgName || ""}</p>
                              </div>
                          </div>
                          <p className="challenge-description">
                            {challenge.description}
                          </p>
                          <div className="challenge-information">
                            <a onClick={() => handleShowDetails(challenge.id)} className="challenge-landing">
                              {tab === "my" ? "Перейти" : "Подробнее" }
                            </a>
                            <p className="challenge-date">
                              {getChallengeStatus(challenge)}
                            </p>
                          </div>
                        </li>
                )}
              </ul>
            </div>
          </main>
        </div>
        <div className="right-block" style={{right: "-100vw"}}>
          <header className="header">
            <p className="header-back" id="backmenu" onClick={() => handleCloseDetails()}>Назад</p>
            <p className="name-menu">Данные мероприятия</p>
          </header>
          {currentChallengeId && currentChallengeId !== null ? (
              <ChallengeInfo challengeId={currentChallengeId}></ChallengeInfo>
          ) : (<></>)}
        </div>
      </div>
  )
}