import React, {useEffect, useState} from "react";
import {IProfilePersonalForm} from "../../../../types/IProfilePersonalForm";
import {ITeam} from "../../../../types/ITeam";
import {useSearchParams} from "react-router-dom";
import {toNumber} from "lodash";
import API from "../../../../../requests/_axios";
import {BottomNav} from "./BottomNav";
import {CONFIG} from "../../../../../CONFIG";
import {ITeamAd} from "../../../../types/ITeamAd";
import InfiniteScroll from "react-infinite-scroll-component";

export const RecruitmentPersonal: React.FC<{challengeId: number | undefined,
  showAdDetails: (ad: ITeamAd) => void,
  enabled: boolean}> =
    ({challengeId, showAdDetails, enabled}) => {

      const [ads, setAds] = useState([]);
      const [currentPage, setCurrentPage] = useState(0);
      const [totalPages, setTotalPages] = useState(0);
      const [searchQuery, setSearchQuery] = useState("");
      const [searchParams, setSearchParams] = useSearchParams();
      const [filter, setFilter] = useState("all");
      const [inStat, setInStat] = useState(0);
      const [outStat, setOutStat] = useState(0);
      const [searchInProgress, setSearchInProgress] = useState(true);
      const [hasMore, setHasMore] = useState(true);
      const [searchTrigger, setSearchTrigger] = useState(true);

      useEffect(() => {
        setSearchQuery(searchParams.get("search") || searchQuery);
        setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
        fetchMoreData();
      }, [filter, searchTrigger]);

        const fetchMoreData = () => {
            setSearchInProgress(true);
            setCurrentPage(currentPage + 1);
            API.get("/challenge/api/v1/challenge/" + challengeId + "/ad/search?query=" + searchQuery + "&page=" + currentPage + "&filter=" + searchParams.get("tab") + "&isTeam=true")
                .then((response) => {
                    if(response.status === 200) {
                        setAds(ads.concat(response.data.items));
                        setTotalPages(response.data.totalPages);
                        if (response.data.totalPages <= currentPage) {
                            setHasMore(false);
                        } else {
                            setHasMore(true);
                        }
                    }
                    setSearchInProgress(false);
                });
            API.get("/challenge/api/v1/challenge/" + challengeId + "/ad/my/stat").then((response) => {
                if(response.status === 200) {
                    setInStat(response.data.in);
                    setOutStat(response.data.out);
                }
            });
        }

      const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          setAds([]);
          setCurrentPage(0);
          setSearchTrigger(!searchTrigger);
        }
      }

      if(enabled) {
        return(
            <>
              <BottomNav isTeam={false} styleName={"notifications-search"}/>
              <div className="challenge-info" id="scrollableList">
                <div className="first-message" style={{display: !searchParams.get("tab") || searchParams.get("tab") === "all" ? "" : "none"}}>
                  <p className="city">
                      Посмотрите объявления команд, которые ищут участников, и откликнитесь на подходящее. <a href="https://disk.yandex.ru/i/kAEVWhXDI2q7uA" target="_blank" className="active-link">Видеоинструкция</a>
                  </p>
                </div>
                <div className="first-message" style={{display: searchParams.get("tab") === "in" ? "" : "none"}}>
                  <p className="city">
                      Входящие - здесь отображаются полученные вами приглашения в команду. <a href="https://disk.yandex.ru/i/kAEVWhXDI2q7uA" target="_blank" className="active-link">Видеоинструкция</a>
                  </p>
                </div>
                <div className="first-message" style={{display: searchParams.get("tab") === "out" ? "" : "none"}}>
                  <p className="city">
                      Исходящие - здесь отображается информация об объявлениях, на которые вы откликнулись. <a href="https://disk.yandex.ru/i/kAEVWhXDI2q7uA" target="_blank" className="active-link">Видеоинструкция</a>
                  </p>
                </div>
                <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchQuery(e.target.value);
                }} onKeyDown={handleKeyDown} placeholder="Введите роль в команде или навык" className="input-challenges"/>

                {!searchInProgress ? (
                    <div className="first-message-error" style={{display: ads.length === 0 ? "" : "none"}}>
                      <p className="fio-or-name">
                        Нет актуальных объявлений
                      </p>
                    </div>
                ) : (<></>)}

                <ul className="challenge-list">
                  <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={ads.length} scrollableTarget="scrollableList" className="challenge-info">
                      {ads && ads.map((a: ITeamAd, i) =>
                          <li key={i} className="challenge-card">
                            <div className="challenge-card-main">
                              <img onClick={() => showAdDetails(a)} src={CONFIG.STORAGE_URL + "/" + a.team?.userId + "/team/" + a.team?.id + "/avatar/" + a.team?.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                                   alt=""
                                   className="challenge-avatar"/>
                              <div className="user-info">
                                <p className="challenge-header" onClick={() => showAdDetails(a)}>{a.team?.name}</p>
                              </div>
                            </div>
                            <p className="challenge-header" onClick={() => showAdDetails(a)}>
                              {a.name}
                            </p>
                            <p className="challenge-description">
                              {a.description}
                            </p>
                          </li>
                      )}
                  </InfiniteScroll>
                </ul>
              </div>
            </>
        )
      } else {
        return (<></>)
      }
    }