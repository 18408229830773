import React, {useEffect, useState} from "react";
import API from "../../../requests/_axios";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import {CONFIG} from "../../../CONFIG";
import {NavigationUniversity} from "./NavigationUniversity";
import {ProfileUniversityHeader} from "./ProfileUniversityHeader";
import {ProfileIndividualView} from "../common/ProfileIndividualView";
import {toNumber} from "lodash";
import {useSearchParams} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import SessionModel from "../../../stores/SessionModel";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";

export const UnloadsUniversity = () => {

  const [unloadingStudents, setUnloadingStudents] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [tab, setTab] = useState("unloads");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (SessionModel.getCurrentUserAuthData().type !== "EDU") {
      localStorage.getItem("token");
      window.location.href = "/";
    }
    setTab(searchParams.get("tab") || "unloads");
  }, []);

  const unloadStudents = () => {
    if (unloadingStudents)
      return
    setUnloadingStudents(true);
    ChallengeAPI.unloadStudents(password, 0, (status) => {
      setError("");
      setPassword("");
      setUnloadingStudents(false);
      if (status === 400) {
        setError("Неправильный пароль");
      } else if (status !== 200) {
        setError('У вас нет разрешения на просмотр перс. данных.' +
            ' Вероятней всего у вас не подтверждены или истекли полномочия. Для уточнения причин обратитесь в техподдержку по адресу <a href="mailto:info@braim.org">info@braim.org</a> или в Телеграм <a href="https://t.me/braim_org" target="_blank">@braim_org</a>');
      }
    });
  }

  return (
      <div className="platform-background">
        <video autoPlay muted loop playsInline className="image-back-video">
          <source src={require("../../../../public/images/main_video.mp4").default}/>
        </video>
        <div className="left-block">
          <ProfileUniversityHeader newPreview={null}/>
          <main className="content">
            <NavigationUniversity />
            <div className="main-list-center">
              <div className="my-challenge">
                <div className="user-info">
                  <p className="fio-or-name">Выгрузки</p>
                </div>
                <ul className="nav-block-center">
                  <li className="nav-center-item">
                    <a href="?tab=students" className={tab === "students" || tab === "" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="?tab=challenges" className={tab === "challenges" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>По мероприятиям</a>
                  </li>
                  <li className="nav-center-item">
                    <a href="?tab=unloads" className={tab === "unloads" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Выгрузки</a>
                  </li>
                </ul>
              </div>
              <ul className="challenge-info">
                <div className="first-message">
                  <p className="city">
                    Выгружая перечень участников, я гарантирую, что не буду передавать данный список третьим лицам, буду использовать данные исключительно в целях обеспечения и поддержки участия представителей нашей организации в мероприятиях на платформе Braim.
                  </p>
                </div>
                <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : "", cursor: "default"}} dangerouslySetInnerHTML={{__html: error || ""}}></p>
                <input type="password" value={password} placeholder="Введите пароль" onChange={(e) => setPassword(e.target.value)} className="input-challenges" style={{display: "inline-block"}}/>
                <li className="challenge-card" style={{display: "inline-block"}}>
                  <button onClick={() => unloadStudents()} className="challenge-activity" disabled={unloadingStudents}>{unloadingStudents ? "Выгружается..." : "Скачать перечень участников"}</button>
                </li>
              </ul>
            </div>
          </main>
          </div>
      </div>
  );
}