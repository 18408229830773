import React, {useEffect, useState} from "react";
import API from "../../../requests/_axios";
import SessionModel from "../../../stores/SessionModel";
import {CONFIG} from "../../../CONFIG";
import {IProfileUniversityForm} from "../../types/IProfileUniversityForm";

export const ProfileUniversityHeader: React.FC<{newPreview: any}> = ({newPreview}) => {

  useEffect(() => {
    if (SessionModel.getCurrentUserAuthData().type !== "EDU") {
      window.location.href = "/";
    }
  }, []);

  const [form, setForm] = useState<IProfileUniversityForm>({
    shortName: "",
    fullName:"",
    rectorName:"",
    responsiblePersonName:"",
    responsiblePersonEmail:"",
    responsiblePersonPosition:"",
    responsiblePersonPhone:"",
    phone: "",
    fias: "",
    email: "",
    type: 1,
    site:""
  });
  const[preview, setPreview] = useState<any>();

  const showMenu = () => {
    // @ts-ignore
    document.querySelector(".menu").style.display = 'flex';
  }

  useEffect(() => {
    let url = "/challenge/api/v1/university/profile";
    API.get(url, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      setForm({...form,
        shortName: response.data.shortName,
        fullName: response.data.fullName
      });
      if(response.data.avatar !== null)
        setPreview(CONFIG.STORAGE_URL + "/" + SessionModel.getCurrentUserAuthData().id + "/avatar/" + response.data.avatar);
      else
        setPreview("/images/no_ava_university.svg");
    });
    if(newPreview !== null) {
      setPreview(newPreview);
    }
  },[newPreview]);

  return (
      <header className="header">
        <div id="burger" className="burger" onClick={showMenu}>
          <span className="first-line"></span>
          <span className="two-line"></span>
          <span className="three-line"></span>
        </div>
        <img src={require("../../../../public/images/logo_plat.svg").default} alt="" className="header-logo"/>
        <a href="/profile">
          <div className="username">
            <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_university.svg"}} alt="" className="header-avatar"/>
            <p className="fio-or-name">Профиль</p>
          </div>
        </a>
      </header>
  )
}