import React, {useEffect, useState} from "react";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {useParams} from "react-router-dom";
import {toNumber} from "lodash";
import {IMentoredProfile} from "../../../../types/mentor/IMentoredProfile";
import {CONFIG} from "../../../../../CONFIG";
import {ICreateMentorRequest} from "../../../../types/mentor/ICreateMentorRequest";
import {ModalMessage} from "../../../common/ModalMessage";
import {IChallenge} from "../../../../types/IChallenge";
import SessionModel from "../../../../../stores/SessionModel";

export const MentorParticipant: React.FC<{challenge: IChallenge, enabled: boolean}> = ({challenge, enabled}) => {

    const params = useParams();
    const [mentorProfile, setMentorProfile] = useState<IMentoredProfile>();
    const [showInvitation, setShowInvitation] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [createMentorRequest, setCreateMentorRequest] = useState<ICreateMentorRequest>();

    useEffect(() => {
        ChallengeAPI.getMentorProfile(toNumber(params.id), (response) => {
            if (response.status === 200) {
                setMentorProfile(response.data);
            }
        })
    }, []);

    const addMentor = () => {
        console.log(SessionModel.getCurrentUserAuthData());
        if (createMentorRequest?.email === SessionModel.getCurrentUserAuthData().sub) {
            setErrorMessage("Вы не можете добавить самого себя в качестве наставника");
            return;
        }
        if (createMentorRequest) {
            ChallengeAPI.createMentor(createMentorRequest, (response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    if (response.status === 400) {
                        setErrorMessage("Вы не можете добавить наставника в прошедшем соревновании");
                    } else {
                        setErrorMessage("Пользователь с таким e-mail не зарегистрирован как участник или не заполнил свой профиль");
                    }
                }
            });
        }
    }

    const deleteMentor = () => {
        if (mentorProfile && confirm("Вы действительно хотите удалить наставника?")) {
            ChallengeAPI.deleteMentor(toNumber(params.id), (response) => {
                if (response.status === 200) {
                    window.location.reload()
                } else {
                    setErrorMessage("Вы не можете удалить наставника в прошедшем соревновании");
                }
            })
        }
    }

    if (enabled) {
        return (
            <>
                <div className="challenge-info">
                    <ModalMessage msg={errorMessage} header={"Ошибка"} _callback={() => setErrorMessage("")}/>
                    <div className="first-message">
                        {challenge.endDate && new Date(challenge.endDate) > new Date() ?
                            mentorProfile ?
                                <>
                                    <p className="fio-or-name">
                                        Наставник выбран
                                    </p>
                                    <a onClick={deleteMentor} className="btn-main">Удалить</a>
                                </>
                                :
                                <>
                                    <p className="fio-or-name">
                                        Выбор наставника
                                    </p>
                                    <p className="city">
                                        В этом разделе вы можете добавить своего наставника, который помогает вам подготовиться к данному мероприятию. Нажмите на кнопку «Добавить» и укажите e-mail зарегистрированного на платформе пользователя
                                    </p>
                                    <a onClick={() => setShowInvitation(true)} className="btn-main">Добавить</a>
                                </>
                            :
                            <>
                                <p className="fio-or-name">
                                    Выбор наставника невозможен. Мероприятие окончено
                                </p>
                            </>
                        }
                    </div>

                    {
                        mentorProfile ?
                            <div className="respose-item">
                                <div className="challenge-card">
                                    <div className="challenge-card-mini">
                                        <img src={`${CONFIG.STORAGE_URL}/${mentorProfile.userId}/avatar/${mentorProfile.avatar}`}
                                             onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                                             alt=""
                                             className="challenge-avatar"/>
                                            <div className="user-info">
                                                <p className="challenge-header">{`${mentorProfile.lastname} ${mentorProfile.firstname}`}</p>
                                            </div>
                                    </div>
                                </div>
                                <div className="challenge-information">
                                    {mentorProfile.telegram ?
                                        <p className="message-user">
                                            <a href={`https://t.me/${mentorProfile.telegram}`} target="_blank">Сообщение</a>
                                        </p>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                            :
                            <></>
                    }
                </div>

                {
                    showInvitation ?
                        <div className="right-block">
                            <header className="header">
                                <p className="header-back" id="backmenu" onClick={() => setShowInvitation(false)}>Назад</p>
                                <p className="name-menu">Новый наставник</p>
                            </header>
                            <div className="container-right-block">
                                <div className="menu-challenge">
                                    <h2 className="headline-right-block">Добавление наставника</h2>
                                    <ul className="responses-list">
                                        <li className="respose-item">
                                            <fieldset className="block-input">
                                                <legend className="legend">E-mail наставника</legend>
                                                <input type="email"
                                                       onChange={(e) => setCreateMentorRequest({...createMentorRequest, challengeId: toNumber(params.id), email: e.target.value})}
                                                       className="input-challenges"
                                                       placeholder="Введите e-mail"/>
                                            </fieldset>
                                            <p className="btn-description">
                                                Добавляя наставника, я разрешаю просматривать ему мои личные данные, а также результаты всех этапов, и мои достижения (дипломы, сертификаты, грамоты) данного конкретного мероприятия
                                            </p>
                                            <a onClick={addMentor} className="btn-blue-width">Добавить</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        )
    } else {
        return (<></>);
    }

}