import React, {useState} from "react";
import {useParams} from "react-router-dom";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {ICreateJuryRequest} from "../../../../types/jury/ICreateJuryRequest";
import {ModalMessage} from "../../../common/ModalMessage";
import {toNumber} from "lodash";

export const JuryInvite:React.FC<{close: () => void}> = ({close}) => {

    const params = useParams();
    const [error, setError] = useState("");
    const [juryRequest, setJuryRequest] = useState<ICreateJuryRequest>();

    const createJury = () => {
        if (params.id && juryRequest) {
            ChallengeAPI.createJury(toNumber(params.id), juryRequest, (response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else if (response.data.code === "009-012") {
                    setError("Этот эксперт уже добавлен");
                } else {
                    setError("Пользователь не найден, или является участником соревнования");
                }
            })
        }
    }

    return (
        <div className="right-block">
            <header className="header">
                <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                <p className="name-menu">Добавление члена</p>
            </header>

            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <h2 className="headline-right-block">Введите email</h2>
                    <p className="challenges-description">
                        Выберите эксперта на платофрме, поиск происходит по адресу электронной почты
                    </p>
                    <input type="email" onChange={(e) => setJuryRequest({...juryRequest, username: e.target.value})} className="input-challenges" placeholder="example@example.ru"/>
                    <input type="button" className="btn-main" value="Добавить" onClick={createJury}/>
                </div>
            </div>
        </div>
    )
}