import React from "react";
import SessionModel from "../../../stores/SessionModel";
import {UniversityList} from "../admin/UniversityList";

export const University = () => {
    let panel;

    if(SessionModel.getCurrentUserAuthData().type === "EDU") {
        panel = <></>
    } else if(SessionModel.getCurrentUserAuthData().type === "PERSONAL") {
        panel = <></>
    } else if(SessionModel.getCurrentUserAuthData().type === "ADMIN" || SessionModel.isModerator()) {
        panel = <UniversityList/>
    }
    return (
        <div>
            {panel}
        </div>
    )
}