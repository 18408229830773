import React, {useEffect, useState} from "react";
import SessionModel from "../../../stores/SessionModel";
import {toNumber} from "lodash";
import {useParams, useSearchParams} from "react-router-dom";
import {ITeamAd} from "../../types/ITeamAd";
import API from "../../../requests/_axios";
import {ProfileAdminHeader} from "./ProfileAdminHeader";
import {ProfileNavigationAdmin} from "./ProfileNavigationAdmin";
import {CONFIG} from "../../../CONFIG";
import {NewAd} from "../personal/challenge/right/NewAd";
import InfiniteScroll from "react-infinite-scroll-component";
import {OrgHeader} from "../common/OrgHeader";
import {OrgNavigation} from "../common/OrgNavigation";

export const AdList = () => {

    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchInProgress, setSearchInProgress] = useState(true);
    const [tab, setTab] = useState("all");
    const [ads, setAds] = useState([]);
    const [ad, setAd] = useState<ITeamAd>();
    const [hasMore, setHasMore] = useState(true);
    const [searchTrigger, setSearchTrigger] = useState(true);

    useEffect(() => {
        setTab(searchParams.get("tab") || "all");
        setSearchQuery(searchParams.get("search") || searchQuery);
        setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
        fetchMoreData();
    }, [searchTrigger]);

    const fetchMoreData = () => {
        setCurrentPage(currentPage + 1);
        API.get(`/challenge/api/v1/challenge/${params.id}/ad/search?query=${searchQuery}&page=${currentPage}&filter=${searchParams.get("tab")}`)
            .then((response) => {
                if(response.status === 200) {
                    setAds(ads.concat(response.data.items));
                    setTotalPages(response.data.totalPages);
                    setTotalItems(response.data.total);
                    if (response.data.totalPages <= currentPage) {
                        setHasMore(false);
                    } else {
                        setHasMore(true);
                    }
                }
            });
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            setAds([]);
            setCurrentPage(0);
            setSearchTrigger(!searchTrigger);
        }
    }

    const showAd = (ad: ITeamAd | undefined) : void => {
        setAd(ad);
        // @ts-ignore
        document.querySelector('#right-block-ad').style.right = "0";
    }

    const hideAd = () => {
        // @ts-ignore
        document.querySelector('#right-block-ad').style.right = "-100vw";
    }

    const ban = (id: number) => {
        API.post(`/challenge/api/v1/challenge/${params.id}/ad/${id}/ban`)
            .then((response) => {
                if(response.status === 200) {
                    window.location.reload();
                }
                setSearchInProgress(false);
            });
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../public/images/main_video.mp4").default}/>
            </video>
            <div className="left-block">
                <OrgHeader preview={null}/>
                <main className="content">
                    <OrgNavigation />
                    <div className="main-list-center">
                        <div className="my-challenge">
                            <div className="user-info">
                                <p className="fio-or-name">Объявления</p>
                            </div>
                            <ul className="nav-block-center">
                                <li className="nav-center-item">
                                    <a href={`/challenge/${params.id}/ads?tab=all`} className={tab === "all" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Все</a>
                                </li>
                                <li className="nav-center-item">
                                    <a href={`/challenge/${params.id}/ads?tab=banned`} className={tab === "banned" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Заблокированные</a>
                                </li>
                            </ul>
                        </div>
                        <ul className="challenge-info" id="scrollableList">
                            <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setSearchQuery(e.target.value);
                            }} onKeyDown={handleKeyDown} placeholder="Должность или навык" className="input-challenges"/>
                            <div className="first-message">Всего: {totalItems}</div>
                            {!searchInProgress && ads.length === 0 ? (
                                <div className="first-message-error" style={{display: ads.length === 0 ? "" : "none"}}>
                                    <p className="fio-or-name">
                                        Поиск не дал результатов
                                    </p>
                                </div>
                            ) : (<></>)}
                            <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={ads.length} scrollableTarget="scrollableList" className="challenge-info">
                                {ads && ads.map((a: ITeamAd, i) =>
                                    <li key={i} className="challenge-card">
                                        <div className="challenge-card-main">
                                            <img onClick={() => showAd(a)} src={CONFIG.STORAGE_URL + "/" + a.team?.userId + "/team/" + a.team?.id + "/avatar/" + a.team?.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_team.svg"}}
                                                 alt=""
                                                 className="challenge-avatar"/>
                                            <div className="user-info">
                                                <p className="challenge-header" onClick={() => showAd(a)}>{a.team?.name}</p>
                                            </div>
                                        </div>
                                        <p className="challenge-header" onClick={() => showAd(a)}>
                                            {a.name}
                                        </p>
                                        <p className="challenge-description">
                                            {a.description}
                                        </p>
                                        <div className="block-input">
                                            {a.isBanned ? <button className="btn-main" onClick={() => ban(a.id || 0)}>Разблокировать</button> : <button className="btn-no" onClick={() => ban(a.id || 0)}>Заблокировать</button>}
                                        </div>
                                    </li>
                                )}
                            </InfiniteScroll>
                        </ul>
                    </div>
                </main>
            </div>
            <div className="right-block" id="right-block-ad" style={{right: "-100vw"}}>
                <header className="header">
                    <p className="header-back" onClick={hideAd} id="backmenu">Назад</p>
                    <p className="name-menu">Объявление о наборе</p>
                </header>
                <NewAd key={ad?.id} challengeId={toNumber(params.id) || 0} ad={ad} teamId={ad?.team?.id}/>
            </div>
        </div>
    )
}