import React, {useEffect, useState} from "react";
import {IChallengePlan} from "../../../../types/IChallengePlan";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {ModalMessage} from "../../ModalMessage";

export const PlanList:React.FC<{currentPlan: IChallengePlan | undefined, visible: boolean, setVisible: (visibility: boolean) => void}> = ({currentPlan, visible, setVisible}) => {

    const [plans, setPlans] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        getPlans();
    }, []);

    const getPlans = () => {
        ChallengeAPI.getPlans((response) => {
            if (response.status === 200) {
                setPlans(response.data);
            } else {
                setError("Ошибка при получении тарифных планов");
            }
        });
    }

    const switchPlan = (planId: string) => {
        if (confirm("С вашего счета будет списана сумма согласно условиям тарифа")) {
            ChallengeAPI.switchPlan(planId, (response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    if (response.data.code === "010-003") {
                        setError("На вашем счете недостаточно средств");
                    } else {
                        setError("Ошибка подключения тарифа");
                    }
                }
            });
        }
    }


    return (
        <div className="right-block" style={{right: visible ? "0vw" : "-100vw"}}>
            <header className="header">
                <p className="header-back" id="backmenu" onClick={() => setVisible(false)}>Назад</p>
                <p className="name-menu">Тарифы</p>
            </header>
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <div className="container-right-block">

                <div className="menu-challenge">
                    <div className="row-wrapper">
                        <p className="number-question">
                            Изменить тариф
                        </p>
                    </div>

                    <p className="template-text">
                        Изменяя тариф, Вы соглашаетесь на изменение суммы списания
                        ежемесячной оплаты. Вы можете подключать и отключать
                        дополнительныен услуги в разделе тарифы.
                    </p>

                    {plans?.map((p: IChallengePlan, key) =>
                        <div className={p.id === currentPlan?.id ? "stage-button balance-green" : "stage-button"} key={key}>
                            <h2 className="headline-tarif">
                                Тариф «{p.name}»
                            </h2>
                            {/*<p className="pay-service-info">
                                Не хватает средств
                            </p>*/}
                            <div className={p.id === currentPlan?.id ? "btn-info balance-green" : "btn-info"}>
                                <h4 className={p.id === currentPlan?.id ? "btn-info-heading-green" : "btn-info-heading-money"}>
                                    {p.id === currentPlan?.id ? "Активен" : p.price / 100}
                                </h4>
                                <ul className="tarif-about">
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Количество участников одного мероприятия
                                        </p>
                                        <p className="tarif-about-num">{p.persons === -1 ? "Безлимит" : p.persons}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Количество инициатив одновременно
                                        </p>
                                        <p className="tarif-about-num">{p.challenges === -1 ? "Безлимит" : p.challenges}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Обязательная принадлежность к организации
                                        </p>
                                        <p className="tarif-about-num">{p.employeesOnly ? "Да" : "Нет"}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Период действия (дней)
                                        </p>
                                        <p className="tarif-about-num">{p.period}</p>
                                    </li>
                                </ul>
                            </div>
                            {p.id !== currentPlan?.id ?
                                <div className="flex-button-additional-service">
                                    <a className="btn-pay-invoice" onClick={() => switchPlan(p.id)}>Подключить</a>
                                </div>
                                : <></>
                            }
                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}