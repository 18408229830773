import React, {useEffect, useState} from "react";
import SessionModel from "../../../stores/SessionModel";
import InputMask from "react-input-mask";
import {ProfileSettings} from "./ProfileSettings";
import API from "../../../requests/_axios";
import {IProfileBusinessForm} from "../../types/IProfileBusinessForm";
import {ModalMessage} from "./ModalMessage";
import {AdminBusinessProfileSettings} from "../admin/AdminBusinessProfileSettings";
import {toNumber} from "lodash";

export const ProfileBusinessDetailsEditor: React.FC<{
    currentForm: IProfileBusinessForm | undefined,
    userId: number | null}>
    = ({currentForm, userId}) => {
    
    const [form, setForm] = useState<IProfileBusinessForm>();
    const [city, setCity] = useState("");
    const [error, setError] = useState("");
    const [dadata, setDadata] = useState([]);
    const [respPersonHint, setRespPersonHint] = useState(false);
    const [respPersonEmailHint, setRespPersonEmailHint] = useState(false);

    useEffect(() => {
        let url = "/challenge/api/v1/business/profile";
        if(userId !== null && userId !== undefined && userId !== 0)
            url = url + "/" + userId;
        if(currentForm == null) {
            API.get(url, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
                setForm(response.data);
                if(response.data.city !== null && response.data.region !== null)
                    setCity(response.data.region + ", " + response.data.city);
            });
        } else {
            setForm(currentForm);
            if(currentForm.city !== "" && currentForm.region !== "")
                setCity(currentForm.region + ", " + currentForm.city);
        }
    }, [userId]);

    useEffect(() => {
        if (SessionModel.isAdmin() && form?.userId)
            setForm({...form, onModeration: false, isApproved: false});
    }, [form?.userId]);

    const fetchLocation = (query: string) => {
        API.get("/challenge/api/v1/location/city?city=" + query, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
            if(response.status === 200)
                setDadata(response.data);
            else
                setDadata([]);
        });
    };

    const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setForm({...form, fias: ""});
        if(event.target.value.length > 2) {
            fetchLocation(event.target.value);
        }
        setCity(event.target.value);
    }

    const handleCityClick = (text: string, fias: string) => {
        setForm({...form, city: text, fias: fias, region: " "});
        setCity(text);
        setDadata([]);
    }

    const handleUpdate = () => {
        let url = "/challenge/api/v1/business/profile";
        if(userId !== null && userId !== undefined) {
            url = url + "/" + userId;
        }
        if(validateAll()) {
            API.put(url, form, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
                if(response.status === 200) {
                    if (userId == null) {
                        window.location.reload();
                    } else {
                        setError("Сохранено");
                    }
                } else if(response.status == 400) {
                    setError(response.data.message);
                } else {
                    alert("Ooops... Что-то не сработало :) Попробуйте еще раз, или свяжитесь с администрацией");
                }
            });
        }
    }

    const validateAll = () : boolean => {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (form) {
            let k: keyof typeof form;
            for (k in form) {
                if(k == "city" && !form.fias) {
                    setCity("");
                    setError("Выберите город из списка");
                    return false;
                }
                if((k === "phone" || k === "responsiblePersonPhone") && form[k]) {
                    form[k] = form[k]?.replace(/\D/g,'');
                }
                if((k === "email") && form[k]) {
                    let currentEmail = form[k];
                    if (currentEmail != undefined && !currentEmail.match(validRegex)) {
                        setError("Неверно заполнено поле E-mail организации");
                        return false;
                    }
                }
                if((k === "responsiblePersonEmail") && form[k]) {
                    let currentEmail = form[k];
                    if (currentEmail != undefined && !currentEmail.match(validRegex)) {
                        setError("Неверно заполнено поле E-mail ответственного");
                        return false;
                    }
                }
            }
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="right-menu">
                <ModalMessage msg={error} header={"Внимание"} _callback={() => setError("")}/>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">Краткое название организации*</legend>
                    <input type="text" value={form?.shortName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, shortName: e.target.value});
                    }} className="input-challenges" placeholder="ООО РАП"/>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">Полное название организации*</legend>
                    <input type="text" value={form?.fullName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, fullName: e.target.value});
                    }} className="input-challenges" placeholder="ООО Ромашка Агро Про"/>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && form?.onModeration}>
                    <legend className="legend">ИНН</legend>
                    <input type="text" value={form?.inn || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, inn: e.target.value});
                    }} className="input-challenges" placeholder="1234567890"/>
                </fieldset>
                <fieldset className="block-input-flex" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">Организационно-правовая форма*</legend>
                    <select value={form?.type} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setForm({...form, type: toNumber(e.target.value)})
                    }} className="input-challenges-select">
                        <option value={0}>Выберите из списка</option>
                        <option value={1}>Малый и средний бизнес</option>
                        <option value={2}>Корпорация (крупный бизнес)</option>
                        <option value={3}>НКО</option>
                        <option value={4}>Отраслевое объединение</option>
                        <option value={5}>Муниципальное учреждение</option>
                        <option value={6}>Государственные учреждение</option>
                        <option value={7}>Казённое учреждение</option>
                        <option value={8}>Другое</option>
                    </select>
                </fieldset>
                <fieldset className="block-input" disabled={!SessionModel.isAdmin() && !SessionModel.isModerator()}>
                    <legend className="legend">Регистрационный E-mail*</legend>
                    <input type="text" value={form?.username || ""} className="input-challenges" placeholder="E-mail" onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, username: e.target.value});
                    }}/>
                </fieldset>
                <fieldset className="block-input">
                    <legend className="legend">Сайт организации*</legend>
                    <input type="text" value={form?.site || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, site: e.target.value});
                    }} className="input-challenges"
                           placeholder="http://www.google.com"/>
                </fieldset>
                <fieldset className="block-input">
                    <legend className="legend">Телефон организации*</legend>
                    {/*+7 (999) 999-99-99*/}
                    <InputMask mask="+999999999999999" value={form?.phone || ""} className="input-challenges"
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                               {setForm({...form, phone: e.target.value});
                               }}/>
                    {/*<input type="tel" value={form?.phone || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {*/}
                    {/*  setForm({...form, phone: e.target.value});*/}
                    {/*}} className="input-challenges"*/}
                    {/*       placeholder="+79001234567"/>*/}
                </fieldset>
                <fieldset className="block-input">
                    <legend className="legend">E-mail организации*</legend>
                    <input type="email" value={form?.email || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, email: e.target.value});
                    }} className="input-challenges"
                           placeholder="info@parampam.com"/>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">Город*</legend>
                    <input type="text" onChange={handleCity} value={city} className="input-challenges city-search"
                           placeholder="Начните вводить и выберите значение из выпадающего списка"/>
                    <div className="container-city">
                        {dadata && dadata.map((d, i) =>
                            //@ts-ignore
                            <div onClick = {() => handleCityClick(d.label, d.id)} className="city-input" key={i}>{d.label}</div>
                        )}
                    </div>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">ФИО ответственного*</legend>
                    <input type="text" value={form?.responsiblePersonName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, responsiblePersonName: e.target.value});
                    }} className="input-challenges"
                           placeholder="Иванов Иван Иванович"/>
                    <div className="answer" onMouseOver={() => setRespPersonHint(!respPersonHint)} onMouseOut={() => setRespPersonHint(!respPersonHint)}></div>
                    <p className="answer-text" style={{display: respPersonHint ? "" : "none"}}>Ответственный - это официальный представитель учебного заведения, который работает на Платформе для курирования участия в мероприятиях своих студентов</p>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">Должность ответственного*</legend>
                    <input type="text" value={form?.responsiblePersonPosition || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, responsiblePersonPosition: e.target.value});
                    }} className="input-challenges" placeholder="Инженер"/>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">E-mail ответственного*</legend>
                    <input type="email" value={form?.responsiblePersonEmail || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                        setForm({...form, responsiblePersonEmail: e.target.value});
                    }} className="input-challenges"
                           placeholder="info@iman.ru"/>
                    <div className="answer" onMouseOver={() => setRespPersonEmailHint(!respPersonEmailHint)} onMouseOut={() => setRespPersonEmailHint(!respPersonEmailHint)}></div>
                    <p className="answer-text" style={{display: respPersonEmailHint ? "" : "none"}}>Укажите свой рабочий адрес электронной почты</p>
                </fieldset>
                <fieldset className="block-input" disabled={(!SessionModel.isAdmin() && !SessionModel.isModerator()) && (form?.onModeration || form?.isApproved)}>
                    <legend className="legend">Телефон ответственного*</legend>
                    <InputMask mask="+999999999999999" value={form?.responsiblePersonPhone || ""} className="input-challenges"
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                               {setForm({...form, responsiblePersonPhone: e.target.value});
                               }}/>
                    {/*<input type="tel" value={form?.responsiblePersonPhone || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {*/}
                    {/*  setForm({...form, responsiblePersonPhone: e.target.value});*/}
                    {/*}} className="input-challenges"*/}
                    {/*       placeholder="+79001234567"/>*/}
                </fieldset>

                <input type="button" className="btn-main" value="Сохранить" onClick={handleUpdate} />{/*disabled={SessionModel.getCurrentUserAuthData().type !== 'ADMIN' && (form?.onModeration || form?.isApproved)}*/}

                <ProfileSettings profile={form}/>
                {SessionModel.isAdmin() ? <AdminBusinessProfileSettings profile={form}/> : <></>}
            </div>
        </>
    )
}