import React from "react";
import {IAnswer} from "../../../types/IUserQuestionDto";
import {Answer} from "./Answer";

export const AnswerList: React.FC<{
  answer: IAnswer,
  answerKey: number,
  type: string,
  pick: (id: string) => void,
  fill: (value: string) => void}> = ({answer, answerKey, type, pick, fill}) => {

  if (type === "INPUT") {
    return (<>
      <input type="text" value={answer.value === null ? "" : answer.value} className="answer-button-margin" onChange={(e) => fill(e.target.value)}/>
    </>)
  } else {
    return (<Answer answerKey={answerKey} answer={answer} type={type} pick={pick}/>)
  }
}