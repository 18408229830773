import React from "react";

export const NotFound = () => {
    return (
        <div className="platform-background">
            <div className="sorry-block">
                <h1 className="sorry-heading">
                    404
                </h1>
                <p className="sorry-text">
                    Похоже, такой страницы не существует. Вы можете перейти в <a href="/profile" className="sorry-link">профиль</a> или вернуться <a href="https://braim.org"
                                                                                   className="sorry-link">на главную</a>
                </p>
            </div>
        </div>
    )
}