import React, {useState} from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {IChallengeStageVideo} from "../../../../types/IChallengeStageVideo";

export const VideoStage:React.FC<{stage: IChallengeStage}> = ({stage}) => {

    const play = (video: IChallengeStageVideo) => {
        if (stage.challengeId && stage.id && video.id) {
            ChallengeAPI.viewStageVideo(stage.challengeId, stage.id, video.id, (response) => {
                setTimeout(() => {window.open(video.url, '_blank')});
            })
        }
    }

    return (
        <div className="container-right-block">
            <div className="menu-challenge">
                <h2 className="headline-right-block">{stage.name}</h2>
                <ul className="modul-list">
                {stage.videos?.map((video, index) =>
                    <li className={video.isViewed ? "modul-item-check" : "modul-item"} key={index}>
                        {video.isViewed ?
                            <p className="stage-card-done">
                                Просмотрено
                            </p>
                            :
                            <></>
                        }
                        <div className="username" onClick={() => play(video)}>
                            <img src="/images/play_blue.svg" alt="" className="play-video-test"/>
                            <p className="fio-or-name">{video.name}</p>
                        </div>
                        <p className="career-description">
                            {video.description}
                        </p>
                        {video.addonsUrl ?
                            <a href={video.addonsUrl} target="_blank" className="link-materials">
                                Дополнительные материалы
                            </a>
                            :
                            <></>
                        }
                    </li>
                )}
                </ul>
            </div>
        </div>
    );
}