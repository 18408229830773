import React, {useEffect} from "react";
import {IDashboardItem} from "../types/ctf/IDashboardItem";
import {IDashboardResponse} from "../types/ctf/IDashboardResponse";
export const CTFInsiderRatingItem:React.FC<{
    item: IDashboardItem,
    dashboard: IDashboardResponse,
    setDashboard: (board: IDashboardResponse) => void,
    reset: () => void,
    index: number}> = ({item, dashboard, setDashboard, reset, index}) => {

    const switchVisibility = (index: number) => {
        const items = dashboard.data;
        const item = items[index];
        item.visible = !item.visible;
        items[index] = item;
        let resetItems = true;
        items.forEach(i => {
            if (i.visible === undefined) {
                i.visible = false;
                resetItems = false;
            }
            if (i.visible) { resetItems = false; }
        });
        if (resetItems) {
            reset();
        } else {
            setDashboard({...dashboard, data: items});
        }
    }

    if (index < 10) {
        if (item.visible) {
            return (
                <li className="dashboard-item" key={index}
                    style={{
                        filter: "drop-shadow(2px 2px 20px rgba(33, 94, 173, 0.2))",
                        zIndex: 900
                    }}
                    onClick={() => switchVisibility(index)}>
                    <p className="dash-rating-position" style={{color: "white"}}>
                        <span className={`color-dash top-${index + 1}`} style={{width: "100%", zIndex: -1}}></span>
                        {index + 1}
                    </p>
                    <p className="dash-comand-name">
                        {item.name}
                    </p>
                    <p className="dash-rating-num">
                        {item.totalPoints}
                    </p>
                </li>
            )
        } else {
            return (
                <li className="dashboard-item" key={index} onClick={() => switchVisibility(index)}>
                    <p className="dash-rating-position">
                        <span className={`color-dash top-${index + 1}`}></span>
                        {index + 1}
                    </p>
                    <p className="dash-comand-name">
                        {item.name}
                    </p>
                    <p className="dash-rating-num">
                        {item.totalPoints}
                    </p>
                </li>
            )
        }
    } else {
        return (<></>)
    }
}