import React from "react";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {formatDate} from "../../../../helpers/DateHelper";

export const StageButtonVideo:React.FC<{stage: IChallengeStage, showStage: (id: number | undefined) => void}> = ({stage, showStage}) => {
    // done
    if(new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() > new Date(stage.endDate + "+03:00" || "")) {
        return (
            <div className="stage-card-new">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Обучение</h5>
                        <p className="stage-card-done">
                            Завершен
                        </p>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap-work">
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                        <div className="stage-card-new-gap-work" style={{marginTop: "10px"}}>
                            <p className="stage-card-new-date">
                                Уроков: {stage.videos ? stage.videos.length : 0}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text">
                        {stage.description}
                    </p>
                </div>
            </div>
        )
    }
    // current
    else if (new Date(stage.startDate + "+03:00" || "") <= new Date() && new Date() < new Date(stage.endDate + "+03:00" || "")) {
        return (
            <div className="stage-card-new-work">
                <div className="stage-card-new-info" onClick={() => showStage(stage.id)}>
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Обучение</h5>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading" onClick={() => showStage(stage.id)}>
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap-work">
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                        <div className="stage-card-new-gap-work" style={{marginTop: "10px"}}>
                            <p className="stage-card-new-date">
                                Уроков: {stage.videos ? stage.videos.length : 0}
                            </p>
                        </div>
                        <p className="stage-card-new-text">
                            {stage.description}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
    // Недоступен
    else if (new Date(stage.startDate + "+03:00" || "") > new Date()) {
        return (
            <div className="stage-card-new-future">
                <div className="stage-card-new-info">
                    <div className="stage-card-info">
                        <h5 className="stage-card-heading">Статус</h5>
                        <p className="stage-card-date">
                            Скоро будет доступен
                        </p>
                    </div>
                    <div className="graph">
                        <p className="stage-card-new-heading">
                            {stage.name}
                        </p>
                        <div className="stage-card-new-gap-work">
                            <p className="stage-card-new-date">
                                {stage?.startDate ? formatDate(stage.startDate) : "-"}
                            </p>
                            <p className="stage-card-new-date">
                                —
                            </p>
                            <p className="stage-card-new-date">
                                {stage?.endDate ? formatDate(stage.endDate) : "-"}
                            </p>
                        </div>
                    </div>
                    <p className="stage-card-new-text"></p>
                </div>
            </div>
        )
    } else {
        return (<></>)
    }
}