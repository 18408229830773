import React, {useEffect, useState} from "react";
import API from "../../../../../requests/_axios";
import {IProfilePersonalForm} from "../../../../types/IProfilePersonalForm";
import {CONFIG} from "../../../../../CONFIG";
import {useSearchParams} from "react-router-dom";
import {toNumber} from "lodash";
import {ITeam} from "../../../../types/ITeam";
import {BottomNav} from "./BottomNav";
import InfiniteScroll from "react-infinite-scroll-component";

export const RecruitmentTeam: React.FC<{challengeId: number | undefined,
  showProfilePanel: (profile: IProfilePersonalForm, team: ITeam | undefined, enableRecruitmentPanel: boolean) => void,
  enabled: boolean}> =
    ({challengeId, showProfilePanel, enabled}) => {

  const [profiles, setProfiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInProgress, setSearchInProgress] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [searchTrigger, setSearchTrigger] = useState(true);

  useEffect(() => {
    setSearchQuery(searchParams.get("search") || searchQuery);
    setCurrentPage(currentPage || toNumber(searchParams.get("page")) || 0);
    fetchMoreData();
  }, [searchTrigger]);

    const fetchMoreData = () => {
      setCurrentPage(currentPage + 1);
      API.get("/challenge/api/v1/challenge/" + challengeId + "/ad/search?query=" + searchQuery + "&page=" + currentPage + "&filter=" + searchParams.get("tab") + "&isTeam=true")
          .then((response) => {
            if(response.status === 200) {
              setProfiles(profiles.concat(response.data.items));
              setTotalPages(response.data.totalPages);
              if (response.data.totalPages <= currentPage) {
                setHasMore(false);
              } else {
                setHasMore(true);
              }
            }
          });
    }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setProfiles([]);
      setCurrentPage(0);
      setSearchTrigger(!searchTrigger);
    }
  }

  if(enabled) {
    return(
        <>
          <BottomNav isTeam={true} styleName={"notifications-search"}/>
          <div className="challenge-info" id="scrollableList">
            <div className="first-message" style={{display: !searchParams.get("tab") || searchParams.get("tab") === "all" ? "" : "none"}}>
              <p className="city">
                Вы можете найти и пригласить в вашу команду подходящих людей среди участников мероприятия. Для этого вначале опубликуйте хотя бы одно объявление в разделе "Объявления". <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
              </p>
            </div>
            <div className="first-message" style={{display: searchParams.get("tab") === "in" ? "" : "none"}}>
              <p className="city">
                Входящие - здесь отображается информация об участниках, которые откликнулись на ваше объявление. <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
              </p>
            </div>
            <div className="first-message" style={{display: searchParams.get("tab") === "out" ? "" : "none"}}>
              <p className="city">
                Исходящие - здесь отображается информация об участниках, которых вы пригласили в свою команду. <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
              </p>
            </div>
            <input type="text" value={searchQuery} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.target.value);
            }} onKeyDown={handleKeyDown} placeholder="Введите роль в команде или навык" className="input-challenges"/>

            {!searchInProgress && profiles.length === 0 ? (
                <div className="first-message-error" style={{display: profiles.length === 0 ? "" : "none"}}>
                  <p className="fio-or-name">
                    Поиск не дал результатов
                  </p>
                </div>
            ) : (<></>)}

            <ul className="challenge-list">
              <InfiniteScroll next={fetchMoreData} hasMore={hasMore} loader={<h4>Загрузка...</h4>} dataLength={profiles.length} scrollableTarget="scrollableList" className="challenge-info">
                {profiles && profiles.map((p: IProfilePersonalForm, i) =>
                    <li key={i} className="challenge-card">
                      <div className="challenge-card-main">
                        <img onClick={() => showProfilePanel(p, undefined, true)} src={CONFIG.STORAGE_URL + "/" + p.userId + "/avatar/" + p.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}}
                             alt=""
                             className="challenge-avatar"/>
                        <div className="user-info">
                          <p className="challenge-header" onClick={() => showProfilePanel(p, undefined, true)}>{p.firstname + " " + p.lastname}</p>
                          <p className="city">{p.city}</p>
                        </div>
                      </div>
                      <div className="block-input">
                        <p className="legend-about">
                          Навыки
                        </p>
                        <ul className="interests">
                          {
                            p.skills && p.skills.split(",").map((skill, i) =>
                                <li key={i} className="interest-item-comand">
                                  {skill.trim()}
                                </li>
                            )
                          }
                        </ul>
                      </div>
                    </li>
                )}
              </InfiniteScroll>
            </ul>
          </div>
        </>
    )
  } else {
    return (<></>)
  }
}