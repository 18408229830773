import React, {useEffect, useState} from "react";
import {IAdminPlan} from "../../../../types/IAdminPlan";
import {ModalMessage} from "../../../common/ModalMessage";
import {toNumber} from "lodash";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";

export const AdminPlanSettings: React.FC<{plan: IAdminPlan | undefined, visible: boolean, setVisible: (visibility: boolean) => void}> = ({plan, visible, setVisible}) => {

    const [error, setError] = useState("");
    const [newPlan, setNewPlan] = useState<IAdminPlan>();
    const [roleHint, setRoleHint] = useState(false);

    useEffect(() => {
        setNewPlan(undefined);
    }, [plan]);

    const handleSubmit = () => {
        if (newPlan) {
            ChallengeAPI.createPlan(newPlan, (response) => {
                if (response.status === 200)
                    window.location.reload();
                else
                    setError(response.data.message);
            })
        }
    }

    const handleDelete = () => {
        if (confirm("Удалить можно, но лучше ненада") && plan?.id) {
            ChallengeAPI.deletePlan(plan.id, (response) => {
                if (response.status === 200)
                    window.location.reload();
                else
                    setError(response.data.message);
            })
        }
    }

    if (plan) {
        return (
            <div className="right-block" style={{right: visible ? "0vw" : "-100vw"}}>
                <header className="header">
                    <p className="header-back" id="backmenu" onClick={() => setVisible(false)}>Назад</p>
                    <p className="name-menu">Тариф</p>
                </header>
                <div className="container-right-block">
                    <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                    <div className="menu-challenge">
                        <div className="row-wrapper">
                            <p className="number-question">
                                {plan.name}
                            </p>
                        </div>

                        <div className="stage-button balance-green">
                            <h2 className="headline-tarif">
                                Тариф «{plan.name}»
                            </h2>
                            <div className="btn-info balance-green">
                                <h4 className="btn-info-heading-green">
                                    {plan.price ? plan.price / 100 : 0}
                                </h4>
                                <ul className="tarif-about">
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Количество участников всего*
                                        </p>
                                        <p className="tarif-about-num">{plan.persons === -1 ? "Безлимит" : plan.persons}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Количество инициатив одновременно
                                        </p>
                                        <p className="tarif-about-num">{plan.challenges === -1 ? "Безлимит" : plan.challenges}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Обязательная принадлежность к организации
                                        </p>
                                        <p className="tarif-about-num">{plan.employeesOnly ? "Да" : "Нет"}</p>
                                    </li>
                                    <li className="tarif-about-item">
                                        <p className="tarif-about-text">
                                            Период действия (дней)
                                        </p>
                                        <p className="tarif-about-num">{plan.period}</p>
                                    </li>
                                </ul>
                                <button className="btn-no" onClick={() => handleDelete()}>Удалить</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    } else {
        return (
            <div className="right-block" style={{right: visible ? "0vw" : "-100vw"}}>
                <header className="header">
                    <p className="header-back" id="backmenu" onClick={() => setVisible(false)}>Назад</p>
                    <p className="name-menu">Тариф</p>
                </header>
                <div className="container-right-block">
                    <div className="menu-challenge">
                        <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                        <h2 className="headline-right-block">Создание ТП</h2>
                        <fieldset className="block-input">
                            <legend className="legend">Название ТП*</legend>
                            <input type="text" value={newPlan?.name} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                setNewPlan({...newPlan, name: e.target.value});
                            }} className="input-challenges" placeholder="Введите название"/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Кол-во человек*</legend>
                            <input type="number" value={newPlan?.persons} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                setNewPlan({...newPlan, persons: toNumber(e.target.value)});
                            }} className="input-challenges" placeholder="-1 для неограниченного" min={-1}/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Кол-во инициатив*</legend>
                            <input type="number" value={newPlan?.challenges} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                setNewPlan({...newPlan, challenges: toNumber(e.target.value)});
                            }} className="input-challenges" placeholder="-1 для неограниченного" min={-1}/>
                        </fieldset>
                        <fieldset className="block-input-flex">
                            <legend className="legend">Только сотрудники</legend>
                            <label className="input-challenges-flex">
                                <input type="radio" name="comand_num" value="1" checked={newPlan?.employeesOnly} onChange={() => setNewPlan({...newPlan, employeesOnly: true})} className="radio-btn"/>
                                <span className="span-radio">Да</span>
                            </label>
                            <label className="input-challenges-flex">
                                <input type="radio" name="comand_num" value="2" checked={!newPlan?.employeesOnly} onChange={() => setNewPlan({...newPlan, employeesOnly: false})} className="radio-btn"/>
                                <span className="span-radio">Нет</span>
                            </label>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Период (дней)*</legend>
                            <input type="number" value={newPlan?.period} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                setNewPlan({...newPlan, period: toNumber(e.target.value)});
                            }} className="input-challenges" placeholder="кол-во дней" min={1}/>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Роли*</legend>
                            <input type="text" value={newPlan?.role} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                setNewPlan({...newPlan, role: e.target.value});
                            }} className="input-challenges" placeholder="Введите через запятую"/>
                            <div className="answer" onMouseOver={() => setRoleHint(!roleHint)} onMouseOut={() => setRoleHint(!roleHint)}></div>
                            <p className="answer-text" style={{display: roleHint ? "" : "none"}}>ROLE_ORG - все ЮЛ, ROLE_EDU - ВУЗы, ROLE_BSN - организации</p>
                        </fieldset>
                        <fieldset className="block-input">
                            <legend className="legend">Стоимость*</legend>
                            <input type="number" value={newPlan?.price} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                                setNewPlan({...newPlan, price: toNumber(e.target.value)});
                            }} className="input-challenges" placeholder="Введите стоимость тарифного плана в мин. ед. валюты" min={0}/>
                        </fieldset>
                        <button className="btn-main" onClick={() => handleSubmit()}>Сохранить</button>
                    </div>
                </div>
            </div>
        )
    }
}